import "../styles/views/blog.scss";

import React, { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Header from '../components/Header'
import Footer from '../components/Footer'
import CookiePopup from '../components/CookiePopup'
import BlogCard from "../components/cards/BlogCard";

import { ARTICLES } from "../articles";
import Inspection from "../components/articles/Inspection";
import Top10 from "../components/articles/Top10";
import Depreciation from "../components/articles/Depreciation";
import MOT2023 from "../components/articles/MOT2023";
import AutoAct from "../components/articles/AutoAct";
import Breadcrumb from "../components/Breadcrumb";

class Blog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active_article: null
        }
    }

    componentDidMount() {
        const URL = window.location.href.split('/')
        let ARTICLE = URL[URL.length - 1]
        if(window.location.href[window.location.href.length - 1] === '/'){
            ARTICLE = URL[URL.length - 2]
        }

        if (ARTICLE !== "blog") {
            this.setState({ active_article: ARTICLE })
        }
    }

    render() {

        const CURRENT = this.state.active_article

        const breadcrumbs_blog = [
            {name: "Home", item: "https://www.autoregcheck.co.uk/"},
            {name: "Blog", item: "https://www.autoregcheck.co.uk/blog/"}
        ]

        const breadcrumbs_inspection = [
            {name: "Home", item: "https://www.autoregcheck.co.uk/"},
            {name: "Blog", item: "https://www.autoregcheck.co.uk/blog/"},
            {name: "Used Car Inspection Checklist", item: "https://www.autoregcheck.co.uk/blog/used-car-inspection-checklist/"}
        ]

        const breadcrumbs_top10 = [
            {name: "Home", item: "https://www.autoregcheck.co.uk/"},
            {name: "Blog", item: "https://www.autoregcheck.co.uk/blog/"},
            {name: "Top 10 Car Check Providers", item: "https://www.autoregcheck.co.uk/blog/top-10-car-check-providers/"}
        ]

        const breadcrumbs_dep = [
            {name: "Home", item: "https://www.autoregcheck.co.uk/"},
            {name: "Blog", item: "https://www.autoregcheck.co.uk/blog/"},
            {name: "Second Hand Car Depreciation", item: "https://www.autoregcheck.co.uk/blog/why-are-evs-depreciating-so-fast/"}
        ]

        const breadcrumbs_self_driving = [
            {name: "Home", item: "https://www.autoregcheck.co.uk/"},
            {name: "Blog", item: "https://www.autoregcheck.co.uk/blog/"},
            {name: "Automated Vehicles Act", item: "https://www.autoregcheck.co.uk/blog/full-self-driving-cars-could-soon-be-coming-to-the-uk/"}
        ]

        const breadcrumbs_mot_2023 = [
            {name: "Home", item: "https://www.autoregcheck.co.uk/"},
            {name: "Blog", item: "https://www.autoregcheck.co.uk/blog/"},
            {name: "MOT 2023 Insights", item: "https://www.autoregcheck.co.uk/blog/revealed:-the-cars-with-the-best-and-worst-mot-pass-rates-in-2023"}
        ]

        return !CURRENT ? (
            <HelmetProvider>
                <Helmet>
                    <title>Blog | Auto Reg Check</title>
                    <meta name="description" content="Read Articles From Our Team Of Motoring Journalists In Our Blog | All You Need To Know About The Latest Trends And News From Across The Automotive World" />
                    <link rel="canonical" href="https://www.autoregcheck.co.uk/blog/" />
                </Helmet>
                <Breadcrumb breadcrumbs={breadcrumbs_blog} />

                <main id="main_blog">

                    <Header active_nav={'blog'} show_banner={true} />

                    <h1 className="main_h1">Blog</h1>

                    <section id="articles">
                        {ARTICLES.map((article, i) => (
                            <BlogCard key={i} article={article} />
                        ))}
                    </section>

                    <Footer show_input={true} />
                    <CookiePopup />
                </main>

            </HelmetProvider>
        ) : (
            <HelmetProvider>
                <Helmet>
                    <link rel="canonical" href={`https://www.autoregcheck.co.uk/blog/${CURRENT}/`} />

                    {CURRENT === "the-ultimate-used-car-inspection-checklist" && <title>{ARTICLES[0].html_title}</title>}
                    {CURRENT === "full-self-driving-cars-could-soon-be-coming-to-the-uk" && <title>{ARTICLES[1].html_title}</title>}
                    {CURRENT === "revealed:-the-cars-with-the-best-and-worst-mot-pass-rates-in-2023" && <title>{ARTICLES[2].html_title}</title>}
                    {CURRENT === "top-10-car-check-providers" && <title>{ARTICLES[4].html_title}</title>}
                    {CURRENT === "why-are-evs-depreciating-so-fast" && <title>{ARTICLES[3].html_title}</title>}

                    {CURRENT === "the-ultimate-used-car-inspection-checklist" && <meta name="description" content={ARTICLES[0].html_desc} />}
                    {CURRENT === "full-self-driving-cars-could-soon-be-coming-to-the-uk" && <meta name="description" content={ARTICLES[1].html_desc} />}
                    {CURRENT === "revealed:-the-cars-with-the-best-and-worst-mot-pass-rates-in-2023" && <meta name="description" content={ARTICLES[2].html_desc} />}
                    {CURRENT === "top-10-car-check-providers" && <meta name="description" content={ARTICLES[4].html_desc} />}
                    {CURRENT === "why-are-evs-depreciating-so-fast" && <meta name="description" content={ARTICLES[3].html_desc} />}

                </Helmet>

                {CURRENT === "the-ultimate-used-car-inspection-checklist" && <Breadcrumb breadcrumbs={breadcrumbs_inspection} />}
                {CURRENT === "full-self-driving-cars-could-soon-be-coming-to-the-uk" && <Breadcrumb breadcrumbs={breadcrumbs_self_driving} />}
                {CURRENT === "revealed:-the-cars-with-the-best-and-worst-mot-pass-rates-in-2023" && <Breadcrumb breadcrumbs={breadcrumbs_mot_2023} />}
                {CURRENT === "top-10-car-check-providers" && <Breadcrumb breadcrumbs={breadcrumbs_top10} />}
                {CURRENT === "why-are-evs-depreciating-so-fast" && <Breadcrumb breadcrumbs={breadcrumbs_dep} />}

                <main id="main_blog">

                    <Header active_nav={'blog'} show_banner={true} />

                    {CURRENT === "the-ultimate-used-car-inspection-checklist" && <Inspection article={ARTICLES[0]} />}
                    {CURRENT === "full-self-driving-cars-could-soon-be-coming-to-the-uk" && <AutoAct article={ARTICLES[1]} />}
                    {CURRENT === "revealed:-the-cars-with-the-best-and-worst-mot-pass-rates-in-2023" && <MOT2023 article={ARTICLES[2]} />}
                    {CURRENT === "top-10-car-check-providers" && <Top10 article={ARTICLES[4]} />}
                    {CURRENT === "why-are-evs-depreciating-so-fast" && <Depreciation article={ARTICLES[3]} />}

                    <Footer show_input={true} />
                    <CookiePopup />
                </main>

            </HelmetProvider>
        )
    }
}

export default Blog;
