import React, { Component } from "react";

import '../../styles/components/article.scss';
import BlogCTA from "../cards/BlogCTA";

const HEADINGS = ["First-things-first", "Pre-test-drive", "Start-the-engine", "On-the-test-drive", "Documents", "Professional-inspection", "History-checks", "Negotiation"]

let positions_set = false

class Inspection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scroll_perc: 0,
            active_h: "",
            positions: {
                "First-things-first": 0,
                "Pre-test-drive": 0,
                "Start-the-engine": 0,
                "On-the-test-drive": 0,
                "Documents": 0,
                "Professional-inspection": 0,
                "History-checks": 0,
                "Negotiation": 0,
            }
        }
    }

    componentDidMount() {
        if (window.innerWidth > 904) {
            this.set_positions(null)
        }
    }

    set_positions(section) {
        let pos_obj = {}

        for (let h of HEADINGS) {
            let elem = document.querySelectorAll(`a[href="#${h}"]`)[0]
            pos_obj[h] = elem.offsetTop
        }

        this.setState({ positions: pos_obj })

        if (section) {
            window.scrollTo(0, pos_obj[section.replaceAll(" ", "-")] - (window.innerHeight / 2) + 2)
        }
    }


    handle_scroll = () => {
        if (!positions_set) {
            this.set_positions(null)
        }

        positions_set = true

        const scroll_location = window.scrollY;
        const total_height = document.documentElement.scrollHeight - window.innerHeight;

        let change_point = window.innerWidth > 1239 ? 765 : 701

        if (scroll_location >= change_point && !document.getElementById('scroll_point_cont').classList.contains('float')) {
            document.getElementById('scroll_point_cont').classList.toggle('float')
        } else if (scroll_location < change_point && document.getElementById('scroll_point_cont').classList.contains('float')) {
            document.getElementById('scroll_point_cont').classList.toggle('float')
        }

        const percentage = (scroll_location / total_height) * 100;

        let visited = []
        let not_visited = []
        const POS = this.state.positions

        for (let h in POS) {
            if (POS[h] - (window.innerHeight / 2) < scroll_location) {
                visited.push(h)
            } else {
                not_visited.push(h)
            }
        }

        let active = visited.pop()
        active = active ? active : HEADINGS[0]

        this.update_scroll_points(visited, not_visited, active)

        this.setState({
            scroll_perc: percentage,
            active_h: active
        });
    };

    update_scroll_points = (visited, not_visited, active) => {
        const POS = this.state.positions
        const KEYS = Object.keys(POS)
        const NEXT = KEYS.indexOf(active) + 1

        const BASE = POS[active] - (window.innerHeight / 2)
        const TARGET = POS[KEYS[NEXT]] - (window.innerHeight / 2)
        const PROG = Math.max(((window.scrollY - BASE) * 100 / (TARGET - BASE)), 0)

        const ACTIVE_POINT = document.querySelectorAll(`[data-key="${active}"]`)[0]
        ACTIVE_POINT.classList.add('active')
        ACTIVE_POINT.querySelector('.inner_bar_progress').style.height = `${PROG}%`


        for (let h of visited) {
            let elem = document.querySelectorAll(`[data-key="${h}"]`)[0]
            elem.classList.remove('active')
            elem.classList.add('visited')
            elem.querySelector('.inner_bar_progress').style.height = '100%'
        }

        for (let h of not_visited) {
            let elem = document.querySelectorAll(`[data-key="${h}"]`)[0]
            elem.classList.remove('active')
            elem.classList.remove('visited')
            elem.querySelector('.inner_bar_progress').style.height = '0'
        }
    }

    handle_scroll_click = (e) => {
        let parent_class = e.target.parentNode.classList
        let this_class = e.target.classList
        let target_div = null

        if (this_class[0] === 'scroll_item') {
            target_div = e.target
        } else if (parent_class[0] === 'scroll_item') {
            target_div = e.target.parentNode
        } else if (this_class[0] !== "inner_bar" && this_class[0] !== "inner_bar_progress") {
            target_div = e.target.parentNode.parentNode
        }

        if (target_div) {
            this.set_positions(target_div.querySelectorAll('p')[0].innerHTML)
        }
    }

    render() {

        if (window.innerWidth > 904) {
            window.addEventListener('scroll', this.handle_scroll)
        }

        const ARTICLE = this.props.article
        return (
            <section className="article">

                <div className="article_opener">
                    <div className="opener_inner">

                        <div className="left">
                            <h1 className="article_h1">{ARTICLE.title}</h1>
                            <p className="article_subtitle">{ARTICLE.subtitle}</p>
                        </div>

                        <img alt={ARTICLE.img_alt} src={ARTICLE.img_src} />
                    </div>
                </div>

                <div className="article_body">
                    <div className="info">
                        <div className="metadata">
                            <p className="meta_author">{ARTICLE.author}</p>
                            <p>{ARTICLE.date}</p>
                            <p>{ARTICLE.read} read</p>
                        </div>
                        <div className="socials">
                            <a href="https://www.instagram.com/xegon_automotive" target="_blank" rel="noreferrer"><img alt="Instagram logo" src="./assets/icons/insta_square.svg" /></a>
                            <a href="https://www.facebook.com/profile.php?id=61550323427271" target="_blank" rel="noreferrer"><img alt="Facebook logo" src="./assets/icons/fb_square.svg" /></a>
                            <a href="https://www.linkedin.com/company/98098382/" target="_blank" rel="noreferrer"><img alt="LinkedIn Logo" src="./assets/icons/linkedin_square.svg" /></a>
                        </div>
                    </div>

                    <div className="content">

                        <p className="intro">Buying a used car is an exciting process. However, it can also be quite daunting, especially if you're unsure of what to check during your inspection. A small oversight can end up becoming a costly headache, thus making a thorough inspection critical. With our ultimate inspection checklist, we’ve listed all the necessary checks that you should conduct when screening a used car before purchase.</p>
                        <h2><a href="#First-things-first">First things first</a></h2>

                        <ul>
                            <li>Check the car’s market value before you inspect it. If the price seems too good to be true, it probably is.</li>
                            <li>If you go to inspect the car, make sure it is in clear daylight. Rain and darkness can hide a lot of issues.</li>
                            <li>Use a payment method that is traceable, such as a debit or credit card. Paying with cash is far riskier and harder to trace.</li>
                        </ul>

                        <img className="img_full" src="./assets/blog/inspection/inspector.webp" alt="A man in the back of a car taking notes" />

                        <h2><a href="#Pre-test-drive">Pre-test drive</a></h2>

                        <h3><a href="#Exterior-and-bodywork">Exterior and bodywork</a></h3>

                        <ul>
                            <li>Examine the car for signs of rust, particularly on the sills, wheel arches, door bottoms, boot lid edges, boot floor and the exhaust system.</li>
                            <li>Ensure that the doors, bonnet, and boot open and close smoothly.</li>
                            <li>Inspect the windshield and windows for chips, cracks, or damage.</li>
                            <li>Inspect the gaps between bodywork panels for any inconsistencies. Any uneven gaps may indicate that a new panel has been fitted due to prior accident damage.</li>
                            <li>Inspect under door handles, window rubbers, and panel gaps for fresh paint and overspray, which could be signs of recent body repair work.</li>
                            <li>Other signs of previous crash repair to look out for are an 'orange-peel' paint finish or new wing bolts.</li>
                            <li>Inspect alloy wheels for damage and bodywork for stone chips and parking scuffs.</li>
                            <li>Check the condition of the tyres thoroughly. Look out for any bulges or cuts in the sidewalls and ensure that the tyres for all four wheels match in brand and size.</li>
                            <li>Inspect the tread depth and ensure that it is above the legal minimum of 1.6mm - if they are below 3mm than they may need changing soon, so factor this into your cost negotiation.</li>
                            <li>Inspect the tyres for any uneven wear, which could indicate an issue with the car’s suspension or wheel alignment.</li>
                            <li>Check that drain holes (including roof drains) are clear.</li>
                        </ul>
                        <BlogCTA n='1' />

                        <h3><a href="#Interior">Interior</a></h3>

                        <ul>
                            <li>Check for water leaks into the footwells and the boot by feeling the carpets and boot floor for dampness. A musty or mouldy smell could also indicate that there is a leak. This is especially important if the car’s drain holes are found to be blocked.</li>
                            <li>Ensure that all accessories, such as the spare wheel and jack, are accounted for.</li>
                            <li>Inspect the condition of the seats for wear, tears, or stains. Don’t ignore any smells as these can be hard to get rid of.</li>
                            <li>Adjust the seats in all directions to ensure proper functionality.</li>
                            <li>Check that the rear-view and side mirrors can be properly adjusted and are in good condition.</li>
                            <li>Ensure that the steering wheel adjusts properly.</li>
                            <li>Turn the ignition on and check for any warning lights on the dashboard.</li>
                            <li>Test the headlights, brake lights, indicators, and reversing lights.</li>
                            <li>Test all controls and buttons in the cabin, including lights, wipers and radio work correctly and that they are in good condition.</li>
                            <li>Check the air conditioning by turning the dial to maximum hot and cold. Wait and see how long it takes to achieve the correct temperature.</li>
                            <li>Wind the windows up and down and check their condition.</li>
                            <li>Confirm that the odometer matches the car’s service history. If there is excessive wear, particularly on the gear lever and pedals, then this may indicate that the car has been driven a lot. In this instance it is particularly important to verify the car’s mileage.</li>
                        </ul>

                        <h3><a href="#Engine-bay">Engine bay</a></h3>

                        <ul>
                            <li>Check oil, coolant, brake fluid, and power steering fluid levels. If they’re low, it could be a sign that the car hasn’t been well maintained or it has developed a leak.</li>
                            <li>Check if the car’s oil is milky in colour or has signs of a mayonnaise-like substance. You should also check that the coolant tank is clean and the fluid is clear and the correct colour. Either could be symptoms of head gasket failure.</li>
                            <li>Look for any leaks under the car as well as in the engine bay. It is important to check this again after the test drive.</li>
                            <li>Inspect the condition of any belts for cracks or fraying.</li>
                            <li>Check brake lines for damage, and verify that the brake calipers aren't seized.</li>
                        </ul>

                        <img className="img_full" src="./assets/blog/inspection/tread.webp" alt="A coin being used to test the tread-depth of a tyre" />

                        <h2><a href="#Start-the-engine">Start the engine</a></h2>

                        <ul>
                            <li>Listen and feel for any rumbling or misfiring, which could indicate issues with the spark plugs, injectors, ignition coils or engine compression.</li>
                            <li>Make sure that you start the car from cold (ask questions if the seller has warmed it up beforehand) and check if it starts okay. If it takes a long time or doesn’t start at all, there may be issues with the battery, alternator or starter motor.</li>
                            <li>Listen for a rattling noise from the engine on cold start-up, an early sign of timing chain failure.</li>
                            <li>Check the temperature gauge stays around halfway once the engine has been running for a while. If it overheats, it could be a problem with the thermostat, or a sign of a more serious engine problem such as a failed head gasket.</li>
                            <li>Then, try turning the ignition off and on again, so that you can be sure the car starts correctly when cold and warm.</li>
                            <li>Check the exhaust for smoke and observe its colour if it is present. Different colours may indicate different issues:</li>
                            <ul>
                                <li><b>Black smoke:</b> Black smoke in a petrol car can indicate an overly rich fuel mixture, possibly due to a faulty fuel injector, a malfunctioning sensor, or a clogged air filter. In diesel cars this is a common symptom of the diesel particulate filter being clogged up - taking an extended drive at higher speeds on a motorway can help the filter to clean itself.</li>
                                <li><b>Grey smoke:</b> this may indicate that transmission fluid is being burned due to a leak. Grey smoke can also mean that excess oil is being burned.</li>
                                <li><b>Blue smoke:</b> this is often a sign of oil being burned in the combustion chamber. This could be due to worn piston rings, valve seals or a malfunctioning turbocharger.</li>
                                <li><b>White smoke:</b> this could simply be down to condensation in the exhaust pipe due to cold weather. Be wary of excessive white smoke as this could be a sign of head gasket failure, or a coolant leak.</li>
                            </ul>
                        </ul>
                        <BlogCTA n='2' />

                        <h2><a href="#On-the-test-drive">On the test drive</a></h2>

                        <ul>
                            <li>During the test drive, keep an eye out for any warning lights.</li>
                            <li>Listen for any squeaks or rattles coming from the cabin, which could be a sign of a poorly maintained or broken interior.</li>
                            <li>Listen for clonking and looseness at the front of the car, which could indicate worn suspension components. These noises will be especially prominent when driving over bumps.</li>
                            <li>Look for signs of steering wheel wobble, which could indicate a potential alignment issue. Check that the steering is light and direct, as a heavy steering wheel could be a sign that the power steering is broken.</li>
                            <li>Check that the brakes are responsive and there are no unusual noises. If the pedal vibrates or pulses then this could indicate that the brake discs are out of shape and need resurfacing or replacing.</li>
                            <li>When you are in an area with low traffic levels, test the brakes firmly for any pulling to one side, which could signal brake issues.</li>
                            <li>Test the clutch to ensure it isn’t slipping and the operation is smooth. Do this by accelerating up a hill in a low gear and at low revs.</li>
                            <li>Shift through all the gears, both manually and automatically (if applicable).</li>
                            <li>Check for smooth gear transitions without jerks or delays. Roughness, crunching noises or difficulty when changing gears could be a potential sign of worn synchros or a more serious transmission problem.</li>
                            <li>Check the acceleration for smoothness and responsiveness. Be alert for any juddering as you pull away quickly.</li>
                            <li>Keep an ear out for the sound of the engine to make sure it is running smoothly. If the car is sluggish or struggles on a steep hill, this could be a sign of an engine issue.</li>
                            <li>Test the handbrake operation, making sure it works correctly. Be sure to check the car stays stationary when parked on an incline.</li>
                            <li>Monitor the oil and coolant levels throughout the test drive and look for signs of leaks when you have finished. High oil consumption could indicate valve or piston ring issues.</li>
                        </ul>

                        <img className="img_full" src="./assets/blog/inspection/mechanic.webp" alt="" />

                        <h2><a href="#Documents">Documents</a></h2>
                        <ul>
                            <li>Thoroughly read through the <a className='outbound' target='_blank' href='https://www.carbuyer.co.uk/tips-and-advice/160024/what-is-a-v5c-all-you-need-to-know-about-the-logbook'>V5C document</a>, otherwise known as the log book. Make sure the make, model and number plate of the car you’re interested in purchasing matches the ones in the V5C. Inspect the quality of the paper and check for any watermarks. A V5C is one A3 sheet of paper folded in half to give four A4 sides.</li>
                            <li>Make a note of the <a target="_blank" className="outbound" href='https://www.gov.uk/vehicle-registration/vehicle-identification-number'>VIN (Vehicle Identification Number)</a> in the log book and check that it matches the physical VINs on the car. They are located:</li>
                            <ul>
                                <li>In the lower part of the windscreen (figure 1).</li>
                                <li>Next to the latch underneath the bonnet (figure 2).</li>
                            </ul>
                        </ul>

                        <div className="double_caption">
                            <div className="img_caption">
                                <img src="./assets/blog/inspection/vin.webp" alt="" />
                                <p>Figure 1: A VIN located in the lower windscreen</p>
                            </div>

                            <div className="img_caption">
                                <img src="./assets/blog/inspection/bonnet.webp" alt="" />
                                <p>Figure 2: A VIN could also be found next to the bonnet latch</p>
                            </div>
                        </div>

                        <h2><a href="#Professional-inspection">Professional inspection</a></h2>
                        <p>If the vehicle is particularly high value or you want further reassurance, consider getting a professional mechanic's opinion before finalising the purchase. 
                            You could find a local mechanic that can perform inspections, or spend a little more to get one from a <a className="outbound" target='_blank' href='https://www.theaa.com/vehicle-inspection/'>trusted organisation such as the AA</a></p>

                        <h2><a href="#History-checks">History checks</a></h2>
                        <p>Use an online service such as <a className="outbound" href='/pricing'>Auto Reg Check</a> to find out more about the vehicle’s history. ARC provides insights such as a 12-point history check, tax and MOT check, valuation, MOT history, specification data and much more, presented in a clear and concise manner and backed by extensive databases from the <a className='outbound' target='_blank' href='https://www.gov.uk/government/organisations/driver-and-vehicle-licensing-agency'>DVLA</a> and beyond. A check from ARC gives you that extra peace of mind when buying a used car.</p>
                        <BlogCTA n='3' />

                        <h2><a href="#Negotiation">Negotiation</a></h2>
                        <p>Use the findings from your inspection as negotiation points. If you discover something is wrong with the car then be sure to negotiate the price down and be prepared to walk away if there are significant concerns - your dream car can wait!</p>

                        <h2>Know your vehicle conditions!</h2>
                        <h3>Poor vehicle condition</h3>
                        <ul>
                            <li>More than 6 areas/panels with a chip, scuff or paintless dent</li>
                            <li>More than 3 panel requiring minor bodywork</li>
                            <li>More than 1 major bodywork repair required</li>
                            <li>Any poor previous repairs</li>
                            <li>Any major alloy wheel refurbishment required</li>
                            <li>Damage to the interior beyond reasonable repair</li>
                            <li><a className='outbound' target='_blank' href='https://www.gov.uk/vehicle-log-book'>V5C/Logbook missing</a></li>
                            <li>Service booked partially or not stamped</li>
                            <li>Mileage discrepancy</li>
                            <li>Key missing</li>
                            <li>Other items missing</li>
                        </ul>

                        <h3>Good vehicle condition</h3>
                        <ul>
                            <li>Up to 6 areas including panel, alloy or glass with a chip, scuff or minor paintless dent</li>
                            <li>Small areas of damage to the interior requiring minor cosmetic repair</li>
                            <li>V5/Logbook present</li>
                            <li>Service book partially stamped</li>
                            <li>No mileage discrepancy</li>
                            <li>2-3 panels that need a minor body shop repair</li>
                            <li>1 major body shop repair required</li>
                            <li>2-4 tyres that need replacing</li>
                            <li>Spare key present</li>
                            <li>Minor low value loose items missing</li>
                        </ul>

                        <h3>Excellent vehicle condition</h3>
                        <ul>
                            <li>Up to 5 areas including panel, alloy or glass with a chip, scuff or minor paintless dent</li>
                            <li>1 minor body shop repair to panel or bumper</li>
                            <li>Carpets not stained beyond repair</li>
                            <li>V5/Logbook present</li>
                            <li>Service book fully stamped</li>
                            <li>No mileage discrepancy</li>
                            <li>All loose items present including spare keys</li>
                            <li>Maximum of 1 tyre that needs replacing</li>
                        </ul>
                    </div>

                    <div className="nav">
                        <div id="scroll_point_cont" style={{ height: `${HEADINGS.length * 75}px` }}>
                            {HEADINGS.map((h, i) => (
                                <div onClick={this.handle_scroll_click} key={i} data-key={h} className='scroll_item'>
                                    <p>{h.replaceAll("-", " ")}</p>
                                    <div className="scroll_icon">
                                        <div className='inner_circle'></div>
                                        <div className='inner_bar'>
                                            <div className='inner_bar_progress'></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </section>

        );
    }
}
export default Inspection;