import { capitalize } from '@mui/material';
import { loadStripe } from "@stripe/stripe-js";

import 'isomorphic-fetch';

const ROOT_URL = window.location.href;

// export const stripePromise = loadStripe("pk_test_51NLiRdHSsfAsbIEMWKmbvVcAXiHS5wVmN6kHbgnXsgDAwo1qP43zwnRcY5fttZk6wny7eZrTcIYo9DwUJqMw7hIy00a9qiZdhJ");
export const stripePromise = loadStripe("pk_live_51NLiRdHSsfAsbIEMVhrESgQ36iVkteSSx1j98l2T6Btg0c0UCOsBRtj0vlxGi66WVjXVbUuqsGxHyocg8Ltu7WSD00PYKFG45x");

export const REPORT_DOWN = false
export const BUY_DOWN = false

// export const PRICING = [
//     {
    // product: '1x Full Vehicle Report',
    //         price: "£9.99",
//         discount: null,
//         total: "£9.99",
//         discount_perc: null,
//         numeric: 9.99,
//         discount_perc_short: null
//     },
//     {
    // product: '2x Full Vehicle Reports',
    //         price: "£19.99",
//         discount: "£2.00",
//         total: "£17.99",
//         discount_perc: "Multi-buy discount (10%)",
//         numeric: 17.99,
//         discount_perc_short: '-10%'
//     },
//     {
    // product: '3x Full Vehicle Reports',
    //         price: "£29.99",
//         discount: "£6.00",
//         total: "£23.99",
//         discount_perc: "Multi-buy discount (20%)",
//         numeric: 23.99,
//         discount_perc_short: '-20%'
//     }
// ]

export const PRICING = [
    {
        product: '1x Full Vehicle Report',
        price: "£4.99",
        discount: null,
        total: "£4.99",
        discount_perc: null,
        numeric: 4.99,
        discount_perc_short: null
    },
    {
        product: '2x Full Vehicle Reports',
        price: "£9.99",
        discount: "£1.00",
        total: "£8.99",
        discount_perc: "Multi-buy discount (10%)",
        numeric: 8.99,
        discount_perc_short: '-10%'
    },
    {
        product: '3x Full Vehicle Reports',
        price: "£14.99",
        discount: "£3.00",
        total: "£11.99",
        discount_perc: "Multi-buy discount (20%)",
        numeric: 11.99,
        discount_perc_short: '-20%'
    }
]

export const DEFAULT_FUEL_PRICES = {
    "PETROL": 145,
    "DIESEL": 150,
    "ELECTRIC": 24.5
}

export const NAV_ITEMS = [
    {
        'display_name': 'Overview',
        'name': 'over',
        "report_items": ['Vehicle Identity', 'MOT & Tax Check', 'Full History Check', 'Timeline', 'Safety', 'VIN'],
        "free_check_items": ['Vehicle Identity', 'MOT & Tax Check', 'Free History Check',],
        'premium_items': ['Full Check', 'Timeline', 'Safety', 'VIN'],
    },
    {
        'display_name': 'History',
        'name': 'hist',
        'report_items': ['Written-off', 'Stolen', 'Scrapped', 'Mileage Issue', 'Outstanding Finance', 'Plate Changes', 'High Risk Records', 'Imported', 'Exported', 'Colour Changes', 'Recalls', 'VIC'],
        'free_check_items': ['Exported', 'Recalls'],
        'premium_items': ['Written-off', 'Stolen', 'Scrapped', 'Mileage Issue', 'Outstanding Finance', 'Plate Changes', 'High Risk Records', 'Imported', 'Colour Changes', 'VIC']
    },
    {
        'display_name': 'MOT',
        'name': 'cert',
        'report_items': ['MOT & Tax Check', 'Full MOT History', 'MOT Stats', 'Mileage Records', 'Buyer Beware', 'Did You Know?'],
        'free_check_items': ['MOT & Tax Check', 'Most Recent MOT Test'],
        'premium_items': ['Full MOT History', 'MOT Stats', 'Mileage Records', 'Buyer Beware', 'Did You Know?']
    },
    {
        'display_name': 'Valuation',
        'name': 'valu',
        'report_items': ['Current Valuation', 'Real-world Valuations', 'Depreciation', 'Cost of Ownership'],
        'free_check_items': [],
        'premium_items': ['Current Valuation', 'Real-world Valuations', 'Depreciation', 'Cost of Ownership']
    },
    {
        'display_name': 'Eco',
        'name': 'ecoi',
        'report_items': ['Fuel Economy', 'Emissions', 'Eco Score', 'LEZ Check', 'Green NCAP', 'Noise Pollution'],
        'free_check_items': ['CO2 Emissions'],
        'premium_items': ['Fuel Economy', 'Other Emissions', 'Eco Score', 'LEZ Check', 'Green NCAP', 'Noise Pollution']
    },
    {
        'display_name': 'Specs',
        'name': 'spec',
        'report_items': ['Vehicle Identity', 'Dimensions', 'Engine', 'Performance', 'Factory Equipment List', 'Optional Extras', 'Tyres', 'RIMS', 'SMMT'],
        'free_check_items': [],
        'premium_items': ['Vehicle Identity', 'Dimensions', 'Engine', 'Performance', 'Factory Equipment List', 'Optional Extras', 'Tyres', 'RIMS', 'SMMT']
    },
]

export const REVIEWS = {
    score: 4.4,
    count: 10,
    testimonials: [
        [
            "This is a very informative dashboard taking you through so much varied and helpful information (specific to your vehicle and the model in general). The MOT history in particular were interesting, as was the expected annual cost. What an amazing find! ",
            "Tracey C"
        ],
        [
            "This reg check gave me loads of information that made buying my next car so much easier! I now have piece of mind that my new vehicle is in perfect condition and it even helped with valuation and running costs, so I knew it was well within my budget!",
            "Phoebe C"
        ],
        [
            "This vehicle check contained lots of detailed information. Navigation through the report is seamless, and it offered a great insight into my car.",
            "John B"
        ],
        [
            "This is an outstanding car check service. The website is so easy to use, and the report contains extensive information. I highly recommend this service to anyone who needs to know more information about a vehicle.",
            "Timmy R"
        ],
        [
            "The purchasing of the report is super simple, and the layout is outstanding, I'm really happy with the quality, and I found out lot of new information about my car.",
            "Henry J"
        ],
        [
            "I don't know a lot about cars, but I now feel confident in my new purchase and have learnt things about cars I didn't know before. I highly recommend a report to anyone buying a used car.",
            "Rose B"
        ],
        [
            "You get loads of information, but it's so easy to navigate the report - it's not overwhelming as it's broken down into neat sections. The report had much more information than previous reg checks that I've used in the past.",
            "Chris B"
        ],
        [
            "My Auto Reg Check report was really helpful for finding specs for my car to make sure I was purchasing the right kind of tyres - thanks!",
            "Fliss C",
        ],
        [
            "Very helpful when trying to buy a new car when you’re not an expert! Really clear and thorough information.",
            "Emma B"
        ],
        [
            "It was clear what information I was going to get in my Auto Reg Check report before paying. It was extremely useful in helping me understand it's whole history and any problems I may need to watch out for.",
            "Bruce A"
        ]
    ],
}


export const SPEC_METADATA = [
    {
        short_name: "reg",
        name: "Identity",
        desc: "The official information tied to the vehicle, including its make and model, year of manufacture, fuel type, colour, and more.",
        max_items: 18,
        singles: [],
        lists: []
    },
    {
        short_name: "dim",
        name: "Dimensions",
        desc: "These specifications provide information about the vehicle's physical size and weight, offering insight into aspects like garage fit, driving comfort, and payload capacity.",
        max_items: 15,
        singles: [],
        lists: []
    },
    {
        short_name: "engine",
        name: "Engine",
        desc: "This details the vehicle's engine type, capacity, power output, and other important features, which can influence the vehicle's performance, fuel efficiency, and maintenance needs.",
        max_items: 16,
        singles: [],
        lists: []
    },
    {
        short_name: "perf",
        name: "Performance",
        desc: "This is a collection of statistics that paint a picture of the vehicle's power, speed, and overall performance capabilities, including acceleration times and top speed.",
        max_items: 9,
        singles: [],
        lists: []
    },
    {
        short_name: "elec",
        name: "EV Data",
        desc: "For electric vehicles, this information covers critical aspects like battery life, charging times, and electric-only range, which can impact the practicality and cost-effectiveness of an EV.",
        max_items: 23,
        singles: [],
        lists: []
    },
    {
        short_name: "fact",
        name: "Factory Equipment",
        desc: "This list contains all the equipment and features that come standard with the vehicle directly from the manufacturer, which can vary from basic safety features to advanced technological integrations.",
        max_items: 30,
        singles: [],
        lists: []
    },
    {
        short_name: "opt",
        name: "Optional Extras",
        desc: "These are additional features or equipment that can be added to the vehicle at an extra cost. These can range from aesthetic upgrades to enhanced safety or comfort features.",
        max_items: 30,
        singles: [],
        lists: []
    },
    {
        short_name: "tyres",
        name: "Tyres",
        desc: "Details like tyre size, tread pattern, and recommended pressure are included in this section. Proper understanding of these specifications is crucial for maintaining optimal vehicle safety and performance. There may be multiple different tyre specs below, relating to different configurations",
        max_items: 18,
        singles: [],
        lists: []
    },
    {
        short_name: "rims",
        name: "Rims",
        desc: "Specifications of the car's wheel rims, including their size, material, and design. These can influence the vehicle's handling, performance, and overall aesthetics. There may be multiple different rim specs below, relating to different configurations",
        max_items: 10,
        singles: [],
        lists: []
    },
    {
        short_name: "smmt",
        name: "SMMT",
        desc: "The Society of Motor Manufacturers and Traders (SMMT) provides authoritative industry information. This might include data on sales trends, manufacturing output, and other relevant insights about the make and model of the vehicle.",
        max_items: 20,
        singles: [],
        lists: []
    },
]

export const HISTORY_METADATA = {
    "Written-off": {
        item_name: "written_off",
        frame_title: "Write-off History",
        home_title: "Written-off",
        data_items: "write_off_list",
        desc_titles: {
            pass: "There are no records of this vehicle having been written-off",
            warn: "We couldn't find any data on write-offs for this vehicle",
            fail: "This vehicle has been written-off",
            free_check: "This is a premium check. Buy a full check to ensure this car has no records of being written-off."
        },
        no: 2.1
    },

    "Stolen": {
        item_name: "stolen",
        frame_title: "Theft History",
        home_title: "Stolen",
        data_items: "stolen_list",
        desc_titles: {
            pass: "There are no recorded thefts for this vehicle",
            warn: "We couldn't find any data relating to previous theft of this vehicle",
            fail: "This vehicle has been reported stolen",
            free_check: "This is a premium check. Buy a full check to ensure this car has no records of being stolen."
        },
        no: 2.2
    },

    "Scrapped": {
        item_name: "scrapped",
        frame_title: "Scrap History",
        home_title: "Scrapped",
        data_items: [["Date Scrapped", "date_scrapped"], ["Destruction Certificate Issued", "destruction_cert_issued"]],
        desc_titles: {
            pass: "There are no records of this vehicle being scrapped",
            warn: "We couldn't find any data relating to previous scrapping of this vehicle",
            fail: "This vehicle has been scrapped",
            free_check: "This is a premium check. Buy a full check to ensure this car has no records of being scrapped."
        },
        no: 2.3
    },

    "Mileage Issue": {
        item_name: "mileage",
        frame_title: "Mileage Discrepancies",
        home_title: "Mileage Issue",
        data_items: "mileage_list",
        desc_titles: {
            pass: "This vehicle has no record of any mileage discrepancies",
            warn: "We couldn't find any data on mileage discrepancies for this vehicle",
            fail: "This vehicle has mileage discrepancies",
            free_check: "This is a premium check. Buy a full check to ensure this car has no mileage discrepancies."
        },
        no: 2.4
    },

    "Finance": {
        item_name: "finance",
        frame_title: "Outstanding Finance",
        home_title: "Outstanding Finance",
        data_items: "finance_list",
        desc_titles: {
            pass: "No records for outstanding finance for this vehicle",
            warn: "We couldn't find any data on outstanding finance",
            fail: "This vehicle has outstanding finance",
            free_check: "This is a premium check. Buy a full check to ensure this car has no outstanding finance."
        },
        no: 2.5
    },

    "Plate Change": {
        item_name: "plate",
        frame_title: "License Plate Changes",
        home_title: "Plate Changes",
        data_items: "plate_change_list",
        desc_titles: {
            pass: "There are no recorded plate changes for this vehicle",
            warn: "We couldn't find any data on plate changes for this vehicle",
            fail: "This vehicle has had its plate changed",
            free_check: "This is a premium check. Buy a full check to ensure this car has no plate change records."
        },
        no: 2.6
    },

    "High Risk": {
        item_name: "high_risk",
        frame_title: "High Risk Records",
        home_title: "High Risk Records",
        data_items: "high_risk_list",
        desc_titles: {
            pass: "There are no high-risk records for this vehicle",
            warn: "We couldn't find any data on high-risk records for this vehicle",
            fail: "This vehicle has high-risk records",
            free_check: "This is a premium check. Buy a full check to ensure this car has no high-risk records."
        },
        no: 2.7
    },

    "Imported": {
        item_name: "imported",
        frame_title: "Import Status",
        home_title: "Imported",
        data_items: [["Date Imported", "import_date"], ["Imported from outside the EU", "import_non_eu"], ["Imported prior to UK Registration", "import_pre_uk_reg"]],
        desc_titles: {
            pass: "This vehicle has not been recorded as imported",
            warn: "We couldn't find any import data for this vehicle",
            fail: "This vehicle has been imported to the UK",
            free_check: "This is a premium check. Buy a full check to ensure this car has no suspicious import records."
        },
        no: 2.8
    },
    "Exported": {
        item_name: "exported",
        frame_title: "Export Status",
        home_title: "Exported",
        data_items: [["Date Exported", "export_date"]],
        desc_titles: {
            pass: "This vehicle has not been recorded as exported",
            warn: "We couldn't find any export data for this vehicle",
            fail: "This vehicle has been exported from the UK",
            free_check: "This is a premium check. Buy a full check to ensure this car has no suspicious export records."
        },
        no: 2.9
    },
    "Colour Change": {
        item_name: "colour",
        frame_title: "Registered Colour Changes",
        home_title: "Colour Changes",
        data_items: [["Current Colour", "colour"], ["Latest Colour Change", "colour_change_latest"], ["Previous Colour", "prev_colour"]],
        desc_titles: {
            pass: "There are no recorded colour changes for this vehicle",
            warn: "We couldn't find any data on colour changes for this vehicle",
            fail: "This vehicle has had a colour change",
            free_check: "This is a premium check. Buy a full check to check the colour change records for this car."
        },
        no: 2.10
    },
    "Recalls": {
        item_name: "recalls",
        frame_title: "Recalls",
        home_title: "Recalls",
        data_items: "recalls_list",
        desc_titles: {
            pass: "We didn't find any recalls relating to this vehicle",
            fail: "We found some recalls that may relate to this vehicle",
        },
        no: 2.11
    },
    "VIC": {
        item_name: "vic",
        frame_title: "Vehicle Identity Check",
        home_title: "VIC",
        data_items: "vic_list",
        desc_titles: {
            pass: "This vehicle has no VIC records",
            warn: "We couldn't find any data relating to VIC records",
            fail: "This vehicle has a VIC record",
            free_check: "This is a premium check. Buy a full check to ensure your car has no VIC records."
        },
        no: 2.12
    },

}

export const ALL_TITLES = Object.keys(HISTORY_METADATA)

export const get_relevant_data = (data, page_letter) => {
    return data.filter(item => {
        const id = item.id;
        return id.startsWith(page_letter) || id.startsWith('M');
    });
}

export const remove_listeners = (id) => {
    let old_element = document.getElementById(id);
    let new_element = old_element.cloneNode(true);
    old_element.parentNode.replaceChild(new_element, old_element);
}

export const clean_plate = (id) => {
    let plate = document.getElementById(id).value
    plate = plate.replace(/\s+/g, '')
    plate = plate.replace(/[^a-zA-Z0-9]/g, '')
    plate = plate.toUpperCase()

    if (plate.length > 0 && plate.length < 10) {
        return plate
    }
}

async function send_request(path, options = {}) {
    const headers = { 'Content-type': 'application/json; charset=UTF-8', };

    const response = await fetch(
        `${ROOT_URL}${path}`,
        Object.assign(
            {
                method: 'POST',
                credentials: 'include'
            },
            { headers },
            options
        )
    );

    const data = await response.json();

    if (data.error) {
        throw new Error(data.error);
    }

    return data;
}

export const add_to_mailing_list = ({ email }) => send_request(
    'add_mailing',
    { body: JSON.stringify({ email }) }
);

export const get_rating_colour = (value) => {
    let colour = "#6ddba7"
    if (value < 3.3) {
        colour = "#dd5b52"
    } else if (value < 6.6) {
        colour = "#f0b261"
    }
    return colour
}

export const get_value = (data_list, search_value, search_column = "item_id", return_column = "value") => {
    for (let i = 0; i < data_list.length; i++) {
        const dict = data_list[i];
        if (dict[search_column] === search_value) {
            return dict[return_column];
        }
    }
    return null;
}

export const format_money = (number) => {
    try {
        if (number) {
            return `£${number.toLocaleString('en-GB')}`
        } else {
            return 'Unknown'
        }
    } catch {
        return "Unknown"
    }
};

export const format_money_short = (number) => {
    try {
        if (number) {
            if (number > 1000) {
                return `£${(number / 1000).toFixed(1)}k`
            } else {
                return `£${number.toLocaleString('en-GB')}`
            }
        } else {
            return 'Unknown'
        }
    } catch (err) {
        return "Unknown"
    }
};

export const normalise_date = (dateString) => {
    const PARTS = dateString.split('/')
    if (PARTS.length >= 3) {
        let year = PARTS[2].length === 4 ? PARTS[2].slice(-2) : PARTS[2]
        return `${PARTS[0]}/${PARTS[1]}/${year}`
    }

}

export const set_history_status = (data) => {
    let status_list = {
        finance: get_state_from_value(data['finance_list'].value.length),
        stolen: get_state_from_value(data['stolen_list'].value.length),
        scrapped: get_state_from_value(data['date_scrapped'].value),
        vic: get_state_from_value(data['vic_list'].value.length),
        imported: get_state_from_value(data['import_date'].value),
        exported: get_state_from_value(data['export_date'].value),
        plate: get_state_from_value(data['plate_change_list'].value.length),
        mileage: get_state_from_value(data['mileage_anomaly'].value),
        written_off: get_state_from_value(data['write_off_list'].value.length),
        high_risk: get_state_from_value(data['high_risk_list'].value.length),
        colour: get_state_from_value(data['colour_change_latest'].value),
        recalls: get_state_from_value(data['n_recalls'])
    }
    return status_list

}

export const get_state_from_value = (value) => {
    let status_info = { status: "warn", marker: "No Data" }
    try {
        if (typeof (value) === 'number') {
            if (value !== null) {
                let cast_val = parseInt(value)
                status_info = cast_val === 0 ? { status: "pass", marker: "Pass" } : { status: "fail", marker: 'Fail' }
            }
        } else {
            status_info = value ? { status: "fail", marker: 'Fail' } : { status: "pass", marker: "Pass" }
        }
    } catch (err) {
        console.error(err.stack)
    }

    return status_info
}

export const get_history_data = (data, title, shorten_recalls) => {
    let formatted_data = []

    try {
        if (Object.values(data).length > 0) {

            let data_item_keys = HISTORY_METADATA[title]["data_items"]
            if (typeof (data_item_keys) === "string") {

                // Change keys to add spaces
                for (let entry of data[data_item_keys].value) {
                    let items = []

                    if (title === "Recalls") {
                        let build_start = entry['build_start_yr'] === "0" ? "?" : entry['build_start']
                        let build_end = entry['build_end_yr'] === "9999" ? "?" : entry['build_end']
                        let build_str = build_start === "?" && build_end === "?" ? "Unknown" : `${build_start} - ${build_end}`
                        if (!shorten_recalls) {
                            items.push({ name: "Recalls No.", value: entry['recalls_number'] ? entry['recalls_number'] : "Unknown Ref" })
                            items.push({ name: "Manufacture Ref", value: entry['manufacturer_ref'] ? entry['manufacturer_ref'] : "Unknown" })
                            items.push({ name: "Model Variant", value: entry['model'] })
                            items.push({ name: "Build Dates", value: build_str })
                            items.push({ name: "Recall Announced", value: entry['date_released'] })
                            items.push({ name: "Affected Vehicles", value: entry['n_affected'] })
                            items.push({ name: "Concern", value: capitalize(entry['defect'].toLowerCase()) })
                            items.push({ name: "Remedy", value: entry['remedy'] })
                            // items.push({ name: "VIN range", value: `${entry['vin_start']} - ${entry['vin_end']}` })

                        } else {
                            items.push({ name: "Manufacture Ref", value: entry['manufacturer_ref'] ? entry['manufacturer_ref'] : "Unknown" })
                            items.push({ name: "Recalls No.", value: entry['recalls_number'] })
                            items.push({ name: "Concern", value: capitalize(entry['defect'].toLowerCase()) })
                        }


                    } else {
                        for (const [key, value] of Object.entries(entry)) {

                            let new_key = key.split(/(?=[A-Z])/).join(' ')
                            let new_value = new_key === "Agreement Date" ? value.substring(0, 10) : value
                            new_value = new_key === "Agreement Term" ? value + ' months' : new_value

                            new_key = new_key.replace("Miaftr", "MIAFTR")

                            items.push({ name: new_key, value: new_value })

                        }
                    }
                    formatted_data.push(items)
                }

            } else {
                for (let key of data_item_keys) {
                    formatted_data.push({ name: key[0], value: data[key[1]].value })
                }
                formatted_data = [formatted_data]
            }

            if (title === 'Mileage Issue') {
                if (formatted_data.length > 0) {

                    let mileage_indicies = []
                    let current_max = 0

                    for (let i = formatted_data.length - 1; i >= 0; i--) {

                        if (formatted_data[i][0].value < current_max) {
                            mileage_indicies.push(i + 1)
                            mileage_indicies.push(i)
                        }
                        current_max = formatted_data[i][0].value
                    }

                    if (mileage_indicies.length > 0) {
                        formatted_data = formatted_data.filter((_, index) => mileage_indicies.includes(index))
                        formatted_data.reverse()
                    }
                }


            }
        }
    } catch (err) {
        console.error(err.stack)
    }

    return formatted_data
}

export const move_items = (col_no, items) => {

    const COL_1 = document.getElementById('col_1')
    const COL_2 = document.getElementById('col_2')
    const COL_3 = document.getElementById('col_3')

    if(!COL_1 || !COL_2 || !COL_3){
        return
    }

    switch (col_no) {
        case 2:
            COL_2.classList.add('active')
            COL_3.classList.remove('active')
            break
        case 3:
            COL_2.classList.add('active')
            COL_3.classList.add('active')
            break
        default:
            COL_2.classList.remove('active')
            COL_3.classList.remove('active')
    }

    const COLS = [COL_1, COL_2, COL_3]

    for (let item of items) {
        // Only use the items from the right subset (1, 2 or 3 column views)
        // All items are in all subsets, but we'd get dups if we didn't do this
        if (item[0] === col_no.toString()) {
            let elem = document.getElementsByClassName(item)
            if (elem.length > 0) {
                COLS[parseInt(item[2]) - 1].append(elem[0])
            }
        }
    }
}

export const get_avg_weight = (euro) => {
    let weight = 1400
    if (euro) {
        switch (euro.substring(0, 1)) {
            case "6":
                weight = 1600
                break
            case "5":
                weight = 1500
                break
            case "4":
                weight = 1400
                break
            case "3":
                weight = 1300
                break
            case "2":
                weight = 1200
                break
            default:
                weight = 1400
        }
    }

    return weight
}

const _calc_size_score = (size) => {

    const L_SCORE = _calc_basic_score(size.length, 3600, 5000, true)
    const W_SCORE = _calc_basic_score(size.width, 1600, 2100, true)
    const H_SCORE = _calc_basic_score(size.height, 1400, 1800, true)

    return (L_SCORE + W_SCORE + H_SCORE) / 3
}

const _calc_euro_score = (euro) => {
    let euro_score = 50
    if (euro) {
        switch (euro.substring(0, 1)) {
            case "6":
                euro_score = 100
                break
            case "5":
                euro_score = 90
                break
            case "4":
                euro_score = 70
                break
            case "3":
                euro_score = 45
                break
            case "2":
                euro_score = 20
                break
            case "1":
                euro_score = 0
                break
            default:
                euro_score = 50
        }
    }

    return euro_score
}

const _is_pol_valid = (pollution) => {
    let pol_valid_count = 0

    for (let i = 0; i < 5; i++) {
        if (pollution[i] && i !== 3) {
            pol_valid_count++
        }
    }

    return pol_valid_count > 1 || pollution[0]
}

const _calc_pollution_score = (pollution, fuel) => {
    let pol_score = 0
    let pol_valid_count = 0

    // Co2, CO, THC, part, NOx
    const POL_MAX = [300, 600, 100, 0, 100]
    const POL_MIN = [0, 0, 0, 0, 0]

    for (let i = 0; i < 5; i++) {
        if (pollution[i] && i !== 3) {
            pol_score += _calc_basic_score(pollution[i], POL_MIN[i], POL_MAX[i], true)
            pol_valid_count++
        }
    }

    // If we have 2+ or we have Co2, then proceed as normal. Otherwise estimate.
    if (pol_valid_count > 1 || pollution[0]) {
        pol_score = pol_score / pol_valid_count
    } else {
        switch (fuel.type) {
            case "ELECTRIC":
            case "ELECTRICITY":
            case "HYDROGEN":
            case "HYDROGEN FUEL CELL":
                pol_score = 100
                break
            case "PLUG-IN":
            case "PLUG-IN HYBRID":
            case "HYBRID":
            case "HYBRID ELECTRIC":
                pol_score = 50
                break
            default:
                pol_score = 0
        }
    }

    return pol_score
}

const _calc_fuel_score = (fuel) => {
    let fuel_score = 50
    switch (fuel.type.toUpperCase()) {
        case "ELECTRICITY":
        case "ELECTRIC":
        case "EV":
            fuel_score = 90
            break
        case "HYDROGEN":
        case "HYDROGEN FUEL CELL":
            fuel_score = 100
            break
        case "PLUG-IN":
        case "PLUG-IN HYBRID":
            fuel_score = 80
            break
        case "HYBRID":
        case "HYBRID ELECTRIC":
            fuel_score = 65
            break
        case "PETROL BI-FUEL":
        case "GAS BI-FUEL":
        case "BIO":
        case "BIOFUEL":
        case "BIO-FUEL":
        case "BIFUEL":
        case "BI-FUEL":
            fuel_score = 20
            break
        case "LPG":
            fuel_score = 20
            break
        case "PETROL":
            fuel_score = 0
            break
        case "DIESEL":
            fuel_score = 0
            break
        default:
            fuel_score = 50
    }


    return fuel_score
}

const _calc_mpge = (fuel) => {
    let type = fuel.elec > 0 ? 'ev' : 'fossil'
    type = fuel.mpg > 0 && fuel.elec > 0 ? 'hybrid' : type

    let mpge = 0
    switch (type) {
        case 'ev':
            mpge = 33700 / fuel.elec
            break
        case 'hybrid':
            mpge = ((33700 / fuel.elec) + fuel.mpg) / 2
            break
        default:
            mpge = fuel.mpg
    }

    return mpge
}

const _calc_basic_score = (val, min, max, high_is_bad) => {
    const val_real = val ? val : ((max - min) / 2) + min
    let score = high_is_bad ? 100 - (val_real - min) * 100 / (max - min) : (val_real - min) * 100 / (max - min)
    score = Math.min(100, Math.max(0, score))
    return score
}

export const calc_eco_score = (size, weight, euro, pollution, noise, fuel) => {

    // Get unweighted scores
    const POLLUTION = _calc_pollution_score(pollution, fuel)
    const FUEL = _calc_fuel_score(fuel)
    let mpge = _calc_mpge(fuel)
    const EFFICIENCY = _calc_basic_score(mpge, 0, 170, false)
    const EURO = _calc_euro_score(euro)
    const SIZE = _calc_size_score(size)
    const WEIGHT = _calc_basic_score(weight, 1100, 2000, true)
    const NOISE = _calc_basic_score(noise, 65, 75, true)

    // Format size string
    let size_str = ""
    size_str += size.length > 0 ? `${(size.length / 1000).toFixed(1)}` : '?'
    size_str += size.width > 0 ? `x${(size.width / 1000).toFixed(1)}` : 'x?'
    size_str += size.height > 0 ? `x${(size.height / 1000).toFixed(1)}m` : 'x?'
    size_str = size_str === "? x ? x ?" ? "(Unknown)" : `(${size_str})`

    // Format raw values
    const RAW_VALS = [
        _is_pol_valid(pollution) || fuel.type !== 'PETROL' || fuel.type !== 'DIESEL' ? '' : '(Unknown)',
        fuel.type ? `(${capitalize(fuel.type.toLowerCase())})` : "(Unknown)",
        mpge > 0 ? `(${parseInt(mpge)} MPGe)` : "(Unknown)",
        euro ? `(${euro})` : "(Unknown)",
        size_str,
        weight ? `(${weight} kg)` : '(Unknown)',
        noise ? `(${noise} dB)` : "(Unknown)"
    ]

    const WEIGHTED = [POLLUTION * 0.3, FUEL * 0.2, EFFICIENCY * 0.2, EURO * 0.15, SIZE * 0.05, WEIGHT * 0.05, NOISE * 0.05]
    const OVERALL = WEIGHTED.reduce((partialSum, a) => partialSum + a, 0)

    return {
        weighted: WEIGHTED,
        unweighted: [POLLUTION, FUEL, EFFICIENCY, EURO, SIZE, WEIGHT, NOISE],
        overall: OVERALL.toFixed(0),
        raw: RAW_VALS
    }
}