import '../../styles/components/report_panels/valuation.scss'
import { format_money, get_relevant_data, DEFAULT_FUEL_PRICES, move_items } from "../../utils"
import { TT } from '../../tooltips'
import FreeCheckCTA from '../cards/FreeCheckCTA.js'
import React, { Component } from "react"

import ReportFrame from '../ReportFrame.js'
import LineChartVal from "../graphs/LineChartVal"
import DonutChart from "../graphs/DonutChart"
import Tooltip from '../Tooltip'
import Review from '../cards/Review'

let loaded = false

const REPAIR_SCALING = {
    threshold: 2000,
    max: 5000,
    min: 100,
}

class Valuation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            valuation: {
                year: 0,
                current: "No Data",
                otr: "No Data",
                dealership: "No Data",
                private: "No Data",
                trade: "No Data",
                auction: "No Data"
            },
            depreciation: {
                years: [],
                values: []
            },
            mpg: 0,
            whm: 0,
            fuel_type: "",
            fuel_price: 0,
            avg_mileage: 7400, // UK avg annual mileage
            car_img: "",
            is_electric: false
        }
    }
    componentDidUpdate() {
        if (!loaded & Object.values(this.props.data).length > 0) {
            this.set_state(this.props.data)
        }
    }

    componentDidMount() {
        if (Object.values(this.props.data).length > 0) {
            this.set_state(this.props.data)
        }
        this.organise_columns()
        window.addEventListener('resize', this.organise_columns)
        loaded = false
    }

    handle_mileage_change = (event) => {
        if (!isNaN(event.target.value)) {
            this.setState({ avg_mileage: event.target.value })
        }
    }

    handle_fuel_change = (event) => {
        if (!isNaN(event.target.value)) {
            this.setState({ fuel_price: event.target.value })
        }
    }

    handle_expand = (e) => {
        let img = e.currentTarget.getElementsByTagName('IMG')[0]

        if (img.style.transform === 'rotate(45deg)') {
            img.style.transform = 'none'
        } else {
            img.style.transform = 'rotate(45deg)'
        }

        document.getElementById('expenses_table').classList.toggle('hide')
        document.getElementById('expenses_inputs').classList.toggle('hide')


    }

    handle_focus = (e) => {
        e.currentTarget.getElementsByTagName('input')[0].focus()
    }

    set_state = (data) => {
        try {
            // Get the relevant metadata
            let metadata = get_relevant_data(data.api_metadata, 'V')

            let filtered_data = {}
            for (let m of metadata) {
                m['value'] = data.api_response[m['item_name']]
                filtered_data[m['item_name']] = m
            }

            // VALUATION
            let valuation = {
                year: 0,
                current: "No Data",
                otr: "No Data",
                dealership: "No Data",
                private: "No Data",
                trade: "No Data",
                auction: "No Data"
            }

            const otr = filtered_data['val_otr'].value

            const private_a = filtered_data['val_private_avg'].value
            const private_c = filtered_data['val_private_clean'].value
            let private_str = private_a === private_c && private_a && private_c ? format_money(private_a) : `${format_money(private_a)} - ${format_money(private_c)}`
            private_str = private_str === 'Unknown - Unknown' ? "Unknown" : private_str
            if (private_str !== 'Unknown - Unknown') {
                private_str = private_str.replace(' - Unknown', '')
                private_str = private_str.replace('Unknown - ', '')
            }

            const trade_p = filtered_data['val_trade_poor'].value
            const trade_r = filtered_data['val_trade_retail'].value
            let trade_str = trade_p === trade_r && trade_p && trade_r ? format_money(trade_p) : `${format_money(trade_p)} - ${format_money(trade_r)}`
            trade_str = trade_str === 'Unknown - Unknown' ? "Unknown" : trade_str
            if (trade_str !== 'Unknown - Unknown') {
                trade_str = trade_str.replace(' - Unknown', '')
                trade_str = trade_str.replace('Unknown - ', '')
            }

            valuation.otr = format_money(otr)
            valuation.dealership = format_money(filtered_data['val_dealership'].value)
            valuation.private = private_str
            valuation.trade = trade_str
            valuation.auction = format_money(filtered_data['val_auction'].value)

            let years = ['']
            let values = [null]

            if (filtered_data['year'].value && otr) {
                const first_year = parseInt(filtered_data['year'].value)
                const current_year = new Date().getFullYear()
                const age = current_year - first_year

                let current_rate = 20

                valuation.year = first_year
                valuation.current = "Unknown"

                let current_price = otr

                for (let i = 0; i <= age + 3; i++) {
                    years.push(first_year + i)
                    values.push(parseInt(current_price))

                    current_price = current_price * (1 - current_rate / 100)

                    current_rate = Math.max(current_rate * 0.5, 3)

                    if (i === age - 1) {
                        valuation.current = parseFloat(current_price.toFixed(0)).toLocaleString('en-GB')
                    }

                }
                years.push('')
                values.push(null)


            } else {
                valuation.year = "Unknown"
                valuation.current = "Unknown"
            }

            // COST OF OWNERSHIP
            const fuel_type = filtered_data['fuel_type'].value
            const elec = data.api_response['elec-Energy_Consumption_WH_per_mile']

            let fuel_price = DEFAULT_FUEL_PRICES["PETROL"]
            let is_electric = false

            if (fuel_type.includes("DIESEL")) {
                fuel_price = DEFAULT_FUEL_PRICES["DIESEL"]
            } else if (!filtered_data['mpg_combined'].value && elec) {
                is_electric = true
                fuel_price = DEFAULT_FUEL_PRICES["ELECTRIC"]
            }

            let image = this.props.free_check && this.props.details.car_img !== "" ? this.props.details.car_img : filtered_data['image'].value

            this.setState({
                mpg: filtered_data['mpg_combined'].value,
                whm: elec ? parseInt(elec) : null,
                fuel_type: fuel_type,
                depreciation: {
                    years: years,
                    values: values
                },
                valuation: valuation,
                fuel_price: fuel_price,
                is_electric: is_electric,
                car_img: image

            })

            loaded = true
        } catch (error) {
            console.error(error)
        }

    }

    handleImageError = (event) => {
        event.target.onError = null; // Prevent infinite loop if fallback image also fails
        event.target.src = "./assets/car_no_image.webp"
        if (this.props.car_img) {
            event.target.src = this.props.car_img
        }
    }

    handleLogoError = (event) => {
        event.target.onError = null; // Prevent infinite loop if fallback image also fails
        event.target.src = "./assets/logos/NO_LOGO.png"
    }
    
    get_fuel_cost = (miles, rate, fuel_price, is_electric) => {
        if (is_electric) {
            return parseInt((miles * fuel_price * ((rate / 1000) / 0.621371)) / 100)

        } else {
            return parseInt(((miles / rate) * 4.54609) * (fuel_price / 100))
        }
    }

    organise_columns = () => {
        const CURR = this.state.current_col

        const ITEMS = ["1-1-1", "1-1-2", "1-1-3", "1-1-4", "1-1-5",
            "2-1-1", "2-1-2", "2-1-3", "2-2-1", "2-2-2",
            "3-1-1", "3-1-2", "3-1-3", "3-2-1", "3-3-1"]

        if (window.innerWidth < 600 && CURR !== 1) {
            move_items(1, ITEMS)
            this.setState({ current_col: 1 })

        } else if (window.innerWidth >= 600 && window.innerWidth < 1440 && CURR !== 2) {
            move_items(2, ITEMS)
            this.setState({ current_col: 2 })

        } else if (window.innerWidth >= 1440 && CURR !== 3) {
            move_items(3, ITEMS)
            this.setState({ current_col: 3 })
        }
    }

    render() {
        const CD = this.props.details

        let costs = []
        let expense_total = 0

        if (this.props.free_check) {
            costs = [
                ["Fuel", 800, '£000'],
                ["Insurance", 200, '£000'],
                ["Repairs & <br>Servicing", 100, '£000'],
                ["Tax", 100, "£000"],
                ["MOT", 70, '£000'],
                ["Other", 20, '£000']
            ]

            expense_total = 1000
        } else {
            const FUEL_COST = this.get_fuel_cost(
                this.state.avg_mileage,
                this.state.is_electric ? this.state.whm : this.state.mpg,
                this.state.fuel_price,
                this.state.is_electric
            )

            let repair_cost = 273
            let mileage_dif = this.state.avg_mileage - 7400

            if (Math.abs(mileage_dif) > REPAIR_SCALING.threshold) {
                let mult = this.state.avg_mileage / 7400
                repair_cost = parseInt(mult * repair_cost)

                repair_cost = Math.min(repair_cost, REPAIR_SCALING.max)
                repair_cost = Math.max(repair_cost, REPAIR_SCALING.min)

            }

            costs = [
                ["Other", 44, '£44'],
                ["Repairs & <br>Servicing", repair_cost, format_money(repair_cost)],
                ["Insurance", 484, '£484'],
                ["MOT", 55, '£55'],
                ["Tax", 165, '£165'],
                ["Fuel", FUEL_COST, FUEL_COST ? format_money(FUEL_COST) : "Unknown MPG"]
            ]

            costs.sort((a, b) => b[1] - a[1])

            for (let i = 0; i < costs.length; i++) {
                if (costs[i][1]) {
                    expense_total += costs[i][1]
                }
            }
        }

        let i = 0
        for (const [key, value] of Object.entries(this.state.valuation)) {
            if (value === "Unknown") {
                i++
            }
        }
        const hide_val_table = i >= 5

        return (
            <div id="valuation_panel" className="report_panel">
                {this.props.free_check && <FreeCheckCTA plate={this.props.plate} title={"Unveil its true value"} text="See how much you could get for this car! Only with our full check." img={'./assets/CTA/valuation.webp'} />}

                <div id='columns'>
                    <div id='col_1' className='col active'></div>
                    <div id='col_2' className='col'></div>
                    <div id='col_3' className='col'></div>
                </div>

                <ReportFrame
                    title="Valuation"
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    col_classes={"1-1-1 2-1-1 3-1-1"}
                    nodata={this.state.valuation.current === 'Unknown' && !this.props.free_check}
                >
                    <div id='valuation'>
                        <div id='val_header'>
                            <img alt="The report car manufacturer logo" src={CD.logo} onError={this.handleLogoError} />
                            <p>{`${CD.car}`}</p>
                        </div>
                        <img className="car_img" alt="The report car" src={this.state.car_img} onError={this.handleImageError} />

                        <div id='val_content'>
                            <p>Estimated value: </p>
                            <Tooltip text={"Current valuation is calculated by applying a decreasing annual depreciation rate on the on-the-road price (total first-year cost at launch), since the car was initially released."} />
                            <div className='val_container'>
                                <img src='./assets/icons/pound.svg' alt='GBP icon' />
                                <p className={this.props.free_check ? 'blur' : ''}>{this.props.free_check ? "00,000" : this.state.valuation.current}</p>
                            </div>
                        </div>
                    </div>

                </ReportFrame>

                <ReportFrame
                    title="Real-world"
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    nodata={hide_val_table}
                    col_classes={"1-1-2 2-2-1 3-1-2"}
                >
                    <div id="real_world">
                        <div className="val_part_row">
                            <div className='row_title'>
                                <p className='default_key'>On the road</p>
                                <Tooltip text={TT.val.new} />
                            </div>
                            <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{this.state.valuation.otr}</p>
                        </div>
                        <div className="val_sep"></div>

                        <div className="val_part_row">
                            <div className='row_title'>
                                <p className='default_key'>Dealership</p>
                                <Tooltip text={TT.val.dealership} />
                            </div>
                            <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{this.state.valuation.dealership}</p>
                        </div>
                        <div className="val_sep"></div>

                        <div className="val_part_row">
                            <div className='row_title'>
                                <p className='default_key'>Private</p>
                                <Tooltip text={TT.val.private} />
                            </div>
                            <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{this.state.valuation.private}</p>
                        </div>
                        <div className="val_sep"></div>

                        <div className="val_part_row">
                            <div className='row_title'>
                                <p className='default_key'>Trade</p>
                                <Tooltip text={TT.val.trade} />
                            </div>
                            <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{this.state.valuation.trade}</p>
                        </div>
                        <div className="val_sep"></div>
                        <div className="val_part_row">
                            <div className='row_title'>
                                <p className='default_key'>Auction</p>
                                <Tooltip text={TT.val.auction} />
                            </div>
                            <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{this.state.valuation.auction}</p>
                        </div>

                    </div>
                </ReportFrame>

                <Review free_check={this.props.free_check} class_name='1-1-3 2-1-3 3-1-3' avatar='4' text='You get so much information - this made buying my new car a lot easier!' />

                <ReportFrame
                    title="Depreciation"
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    nodata={this.state.valuation.otr === "Unknown"}
                    col_classes={"1-1-4 2-1-2 3-2-1"}
                    block_children={this.props.free_check}
                >
                    <div className="highcharts_cont">
                        <LineChartVal years={this.state.depreciation.years} values={this.state.depreciation.values} free_check={this.props.free_check} />
                    </div>
                </ReportFrame>

                <ReportFrame
                    title="Expenses"
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    col_classes={"1-1-5 2-2-2 3-3-1"}
                >
                    <div id="expenses">
                        <div id='expenses_header'>
                            <p className='default_key'>Annual cost: </p>
                            <div className='val_container'>
                                <img src='./assets/icons/pound.svg' alt='GBP icon' />
                                <p className={this.props.free_check ? 'blur' : ''}>{this.props.free_check ? "0,000" : expense_total.toLocaleString('en-GB')}</p>
                            </div>
                        </div>

                        <div className="highcharts_cont">
                            <DonutChart
                                free_check={this.props.free_check}
                                data={costs}
                                tooltip="Est. Annual Cost: £{point.y}"
                                colours={['rgb(51, 93, 162)', 'rgb(69, 143, 188)', 'rgb(99, 180, 193)', 'rgb(145, 203, 188)', 'rgb(205, 232, 185)', 'rgb(239, 248, 185)']}
                            />
                        </div>

                        <div className='sep'></div>

                        <div id='breakdown_list'>
                            <div id='breakdown_dropdown' onClick={this.handle_expand}>
                                <p className='default_key'>View breakdown</p>
                                <img className='dropdown' alt='Dropdown icon' src='./assets/icons/expand.svg' />
                            </div>

                            <div id="expenses_table" className='hide'>
                                {costs.map((e, i) => (
                                    <div className="expenses_row" key={i}>
                                        <div className='expenses_key'>
                                            <p className='default_key'>{e[0].replace("<br>", "")}</p>
                                            <Tooltip text={TT.val.expenses[e[0]]} />
                                        </div>
                                        <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{e[2]}</p>
                                    </div>
                                ))}
                            </div>

                            <div id="expenses_inputs" className='hide'>
                                <div className="input_item" onClick={this.handle_focus}>
                                    <p>Annual Mileage</p>
                                    <div className="input_box">
                                        <input
                                            id='mileage_input'
                                            type="text"
                                            value={this.state.avg_mileage}
                                            onChange={this.handle_mileage_change}
                                            pattern="[0-9]"
                                            inputMode="numeric"
                                            maxLength={6}
                                        />
                                        <p>mi</p>
                                    </div>
                                </div>

                                <div className="input_item" onClick={this.handle_focus}>
                                    <p>Fuel Price</p>
                                    <div className="input_box">
                                        <input
                                            type="text"
                                            value={this.state.fuel_price}
                                            onChange={this.handle_fuel_change}
                                            pattern="[0-9]{0,3}(\.[0-9]{0,1})?"
                                            inputMode="decimal"
                                            maxLength={5}
                                            style={this.state.is_electric ? { maxWidth: '40px' } : { maxWidth: '30px' }}
                                        />
                                        {!this.state.is_electric && <p>p/L</p>}
                                        {this.state.is_electric && <p>p/kWh</p>}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </ReportFrame>


            </div>
        )
    }
}
Valuation.displayName = 'Valuation'

export default Valuation