import React, { Component } from "react";
import '../styles/components/faq.scss';

class FAQ extends Component {

    handle_faq_click = (elem) => {

        switch (elem.target.tagName) {
            case 'IMG':
                elem.target.classList.toggle('rotate')
                elem.target.parentNode.parentNode.children[1].classList.toggle('show')
                break;
            case 'DIV':
                elem.target.children[1].classList.toggle('rotate')
                elem.target.children[1].classList.toggle('show')
                elem.target.parentNode.children[1].classList.toggle('show')
                break;
            default:
                elem.target.parentNode.children[1].classList.toggle('rotate')
                elem.target.parentNode.parentNode.children[1].classList.toggle('show')
        }
    }

    render() {
        return (
            <div className='main_faq'>
                <div className="question" onClick={this.props.dropdown ? this.handle_faq_click : null}>
                    <p>{this.props.question}</p>
                    {this.props.dropdown && <img src='./assets/icons/faq_down.svg' draggable='false' alt='Arrow indicating the element can be expanded and retracted.' />}
                </div>

                <div className={this.props.dropdown ? "answer" : "answer show"}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
export default FAQ;