import "../styles/views/buy.scss";

import React, { Component } from "react";
import axios from 'axios';
import { BUY_DOWN } from "../utils";
import Header from '../components/Header'
import Footer from '../components/Footer'
import CookiePopup from '../components/CookiePopup'
import ConfirmAndPay from "../components/user_journey/ConfirmAndPay";
import Loading from "../components/user_journey/Loading";
import { Helmet, HelmetProvider } from 'react-helmet-async';

class Buy extends Component {

    constructor(props) {
        super(props)
        this.state = {
            current_stage: "confirm_and_pay",
            dvla_results: [],
            input_plates: [],
            reports: [],
            current_loading: [],
            total_to_load: 0,
            total_loaded: 0
        }
    }

    componentDidMount() {
        if (BUY_DOWN) {
            window.location.href = '/maintenance'
        }

        if (localStorage.getItem('cookies_accepted') === "accepted") {
            (function (h, o, t, j, a, r) {
                h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                h._hjSettings = { hjid: 3716479, hjsv: 6 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script'); r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        }

        let n = new URLSearchParams(window.location.search).get('count')
        let all_plates = []

        for (let i = 0; i < n; i++) {
            all_plates.push(new URLSearchParams(window.location.search).get(`plate${i + 1}`))
        }

        if (all_plates.length > 0) {
            this.check_plates(all_plates)
        } else {
            window.location.href = '/'
        }
    }

    get_basic_dvla = async (plate) => {
        let result = []
        try {
            const response = await axios.get(`/api/get-basic-dvla-info/${plate}`);
            result = response.data
        } catch (error) {
            window.location.href = "/server-error"
        }
        return result
    }

    check_plates = async (plates) => {
        const dvla_results = await Promise.all(plates.map(async (p) => {
            const resp = await this.get_basic_dvla(p)
            const RESP = JSON.parse(resp)

            const CAP = Math.round((RESP.engineCapacity / 1000) * 10) / 10

            let dvla_resp = { plate: p }

            if (Object.keys(RESP).length !== 1) {
                let make = RESP.make.toLowerCase()
                make = make === "bmw" ? "BMW" : make
                make = make === "ac" ? "AC" : make
                make = make === "dc" ? "DC" : make
                make = make === "mg" ? "MG" : make

                dvla_resp = {
                    plate: p,
                    make: make,
                    year: RESP.yearOfManufacture,
                    colour: RESP.colour,
                    fuel_type: RESP.fuelType,
                    capacity: `${CAP}L`,
                };
            }

            return dvla_resp
        }))

        // Remove duplicates
        const unique_results = dvla_results.filter((value, index) => {
            const _value = JSON.stringify(value);
            return index === dvla_results.findIndex(obj => {
                return JSON.stringify(obj) === _value;
            });
        });

        this.setState({
            dvla_results: unique_results,
            input_plates: plates,
        });

    }

    handle_confirmation = async (report_inputs, email) => {
        let input_list = []
        for (let i = 1; i <= 3; i++) {
            let plate = report_inputs[`plate_${i}`]
            let id = report_inputs[`arc_id_${i}`]

            if (plate !== 'NA') {
                input_list.push([plate, id])
            }
        }

        this.setState({
            total_to_load: input_list.length,
            current_stage: "loading",
        })

        let total_loaded = 0

        let report_list = []

        for (let i = 0; i < input_list.length; i++) {
            let plate_id = input_list[i]

            this.setState({
                current_loading: plate_id[0].toUpperCase(),
                total_loaded: total_loaded
            })

            try {
                let report = await axios.get(`/api/generate-report/${plate_id[0]}/${plate_id[1]}`);
                report_list.push(report.data)
                total_loaded += 1
            } catch (err) {
                window.location.href = '/report-error'
            }
        }

        window.email_for_enhanced_conversion = email
        window.transaction_id = input_list[0][1]


        let bought_vehicles = this.state.dvla_results.filter(x => 'make' in x);
        for (let i = 0; i < bought_vehicles.length; i++) {
            for (let j = 0; j < report_list.length; j++) {
                if (report_list[j]['plate'] === bought_vehicles[i]['plate']) {
                    bought_vehicles[i]['id'] = report_list[j]['id']
                }
            }
        }

        let url = `/confirmation?count=${bought_vehicles.length}`
        for (let i = 0; i < bought_vehicles.length; i++) {
            url += `&make${i + 1}=${bought_vehicles[i]['make']}`
            url += `&year${i + 1}=${bought_vehicles[i]['year']}`
            url += `&id${i + 1}=${bought_vehicles[i]['id']}`
            url += `&plate${i + 1}=${bought_vehicles[i]['plate']}`
        }

        window.location.href = url
    }

    render() {



        return (
            <HelmetProvider>

                <Helmet>
                    <title>Purchase a Full Check | Auto Reg Check</title>
                </Helmet>

                <main id="main_buy">
                    <Header active_nav={''} show_banner={true} />

                    <div id={this.state.current_stage} className="stage">

                        {this.state.current_stage === "confirm_and_pay" &&
                            <ConfirmAndPay
                                input_plates={this.state.input_plates}
                                handle_confirm={this.handle_confirmation}
                                dvla_results={this.state.dvla_results}
                            />}


                        {this.state.current_stage === "loading" &&
                            <Loading
                                current_loading={this.state.current_loading}
                                total_to_load={this.state.total_to_load}
                                total_loaded={this.state.total_loaded}

                            />}



                    </div>

                    <Footer />
                    <CookiePopup />
                </main>
            </HelmetProvider>

        );
    }
}

export default Buy;
