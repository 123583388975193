import React, { Component } from "react";
import PlateInput from '../PlateInput'

import '../../styles/components/cards/blog_cta.scss';

class BlogCTA extends Component {
    render() {
        return (
            <div className="blog_cta">
                <p className="headline">Don't leave it to chance</p>
                <p className="sub">Buy a full car check for £4.99, avoid £1000's in costly repairs</p>
                <PlateInput input_id={`blog_cta_${this.props.n}`}/>
            </div>
        );
    }
}
export default BlogCTA;