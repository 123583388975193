import React, { Component } from "react";
import '../../styles/components/graphs/comparison_bar.scss';

import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import HC_access from "highcharts/modules/accessibility";

HC_access(Highcharts);
HC_more(Highcharts);

class ComparisonBar extends Component {
    chartRef = React.createRef();
    render() {

        const FC = this.props.free_check

        const COMB_VALS = [...this.props.avg, ...this.props.this_car, ...this.props.worst];
        const MAX = Math.max(...COMB_VALS);

        let W = window.innerWidth
        let H = ''
        if (W >= 1240) {
            H = '95%'
        } else if (W > 740) {
            H = '70%'
        } else if (W >= 600) {
            H = '130%'
        } else if (W > 370) {
            H = '75%'
        } else {
            H = '130%'
        }

        let series = []

        const HIDE_COMPARISON = this.props.avg[0] === 0

        if (HIDE_COMPARISON) {
            series = [{
                name: FC ? "SAMPLE" : "This Vehicle",
                color: 'rgb(145, 203, 188)',
                data: this.props.this_car
            }]
        } else {
            series = [{
                name: FC ? "SAMPLE" : "This Vehicle",
                color: 'rgb(145, 203, 188)',
                data: this.props.this_car
            }, {
                name: FC ? "SAMPLE" : `Avg.`,
                color: 'rgb(69, 143, 188)',
                data: this.props.avg
            }, {
                name: FC ? "SAMPLE" : `Worst`,
                color: 'rgb(51, 93, 162)',
                data: this.props.worst
            }]
        }
        const options = {
            chart: {
                type: 'column',
                style: {
                    fontFamily: 'Nunito Sans, sans-serif'
                },
                height: H,
                marginLeft: 65,
                marginTop: 20,
            },
            title: { text: null },
            credits: { enabled: false, },

            legend: {
                enabled: !HIDE_COMPARISON,
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal'
            },

            xAxis: {
                categories: FC ? ['SAMPLE', 'SAMPLE', 'SAMPLE'] : ['Urban', 'Extra Urban', 'Combined'],
            },

            yAxis: {
                allowDecimals: false,
                title: { text: 'MPG' },
                max: MAX
            },

            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            if (this.y) {
                                return this.y;
                            }
                        },
                    }
                }
            },

            series: series,

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 100
                    },
                    chartOptions: {
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        },
                        yAxis: {
                            labels: {
                                align: 'left',
                                x: 0,
                                y: -5
                            },
                            title: {
                                text: null
                            }
                        },
                        subtitle: {
                            text: null
                        },
                        credits: {
                            enabled: false
                        }
                    }
                }]
            }
        };

        return (
            <div id="comparison_bar">
                <div className={this.props.free_check ? "block" : ""}></div>

                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={this.chartRef}
                />
            </div>
        );
    }
}

export default ComparisonBar;
