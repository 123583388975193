import React, { Component } from "react";

import '../../styles/components/article.scss';
import BlogCTA from "../cards/BlogCTA";

const HEADINGS = ["Rapid-Technological-Advancements", "Battery-Degradation-Concerns", "Limited-Resale-Market", "Impact-of-Government-Incentives", "Perception-and-Awareness", "Conclusion"]

let positions_set = false

class Depreciation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scroll_perc: 0,
            active_h: "",
            positions: {
                "What-to-Look-For": 0,
                "The-Top-10": 0,
                "Conclusion": 0,
            }
        }
    }

    componentDidMount() {
        if (window.innerWidth > 904) {
            this.set_positions(null)
        }
    }

    set_positions(section) {
        let pos_obj = {}

        for (let h of HEADINGS) {
            let elem = document.querySelectorAll(`a[href="#${h}"]`)[0]
            pos_obj[h] = elem.offsetTop
        }

        this.setState({ positions: pos_obj })

        if (section) {
            window.scrollTo(0, pos_obj[section.replaceAll(" ", "-")] - (window.innerHeight / 2) + 2)
        }
    }


    handle_scroll = () => {
        if (!positions_set) {
            this.set_positions(null)
        }

        positions_set = true

        const scroll_location = window.scrollY;
        const total_height = document.documentElement.scrollHeight - window.innerHeight;

        let change_point = window.innerWidth > 1239 ? 765 : 701

        if (scroll_location >= change_point && !document.getElementById('scroll_point_cont').classList.contains('float')) {
            document.getElementById('scroll_point_cont').classList.toggle('float')
        } else if (scroll_location < change_point && document.getElementById('scroll_point_cont').classList.contains('float')) {
            document.getElementById('scroll_point_cont').classList.toggle('float')
        }

        const percentage = (scroll_location / total_height) * 100;

        let visited = []
        let not_visited = []
        const POS = this.state.positions

        for (let h in POS) {
            if (POS[h] - (window.innerHeight / 2) < scroll_location) {
                visited.push(h)
            } else {
                not_visited.push(h)
            }
        }

        let active = visited.pop()
        active = active ? active : HEADINGS[0]

        this.update_scroll_points(visited, not_visited, active)

        this.setState({
            scroll_perc: percentage,
            active_h: active
        });
    };

    update_scroll_points = (visited, not_visited, active) => {
        const POS = this.state.positions
        const KEYS = Object.keys(POS)
        const NEXT = KEYS.indexOf(active) + 1

        const BASE = POS[active] - (window.innerHeight / 2)
        const TARGET = POS[KEYS[NEXT]] - (window.innerHeight / 2)
        const PROG = Math.max(((window.scrollY - BASE) * 100 / (TARGET - BASE)), 0)

        const ACTIVE_POINT = document.querySelectorAll(`[data-key="${active}"]`)[0]
        ACTIVE_POINT.classList.add('active')
        ACTIVE_POINT.querySelector('.inner_bar_progress').style.height = `${PROG}%`


        for (let h of visited) {
            let elem = document.querySelectorAll(`[data-key="${h}"]`)[0]
            elem.classList.remove('active')
            elem.classList.add('visited')
            elem.querySelector('.inner_bar_progress').style.height = '100%'
        }

        for (let h of not_visited) {
            let elem = document.querySelectorAll(`[data-key="${h}"]`)[0]
            elem.classList.remove('active')
            elem.classList.remove('visited')
            elem.querySelector('.inner_bar_progress').style.height = '0'
        }
    }

    handle_scroll_click = (e) => {
        let parent_class = e.target.parentNode.classList
        let this_class = e.target.classList
        let target_div = null

        if (this_class[0] === 'scroll_item') {
            target_div = e.target
        } else if (parent_class[0] === 'scroll_item') {
            target_div = e.target.parentNode
        } else if (this_class[0] !== "inner_bar" && this_class[0] !== "inner_bar_progress") {
            target_div = e.target.parentNode.parentNode
        }

        if (target_div) {
            this.set_positions(target_div.querySelectorAll('p')[0].innerHTML)
        }
    }

    render() {

        if (window.innerWidth > 904) {
            window.addEventListener('scroll', this.handle_scroll)
        }

        const ARTICLE = this.props.article
        return (
            <section className="article">

                <div className="article_opener">
                    <div className="opener_inner">

                        <div className="left">
                            <h1 className="article_h1">What's Behind the Big Depreciation of Second Hand EVs?</h1>
                            <p className="article_subtitle">{ARTICLE.subtitle}</p>
                        </div>

                        <img alt={ARTICLE.img_alt} src={ARTICLE.img_src} />
                    </div>
                </div>

                <div className="article_body">
                    <div className="info">
                        <div className="metadata">
                            <p className="meta_author">{ARTICLE.author}</p>
                            <p>{ARTICLE.date}</p>
                            <p>{ARTICLE.read} read</p>
                        </div>
                        <div className="socials">
                            <a href="https://www.instagram.com/xegon_automotive" target="_blank" rel="noreferrer"><img alt="Instagram logo" src="./assets/icons/insta_square.svg" /></a>
                            <a href="https://www.facebook.com/profile.php?id=61550323427271" target="_blank" rel="noreferrer"><img alt="Facebook logo" src="./assets/icons/fb_square.svg" /></a>
                            <a href="https://www.linkedin.com/company/98098382/" target="_blank" rel="noreferrer"><img alt="LinkedIn Logo" src="./assets/icons/linkedin_square.svg" /></a>
                        </div>
                    </div>

                    <div className="content">

                        <p className="intro">In recent years, electric vehicles (EVs) have surged in popularity, offering numerous advantages over traditional petrol and diesel cars. Reduced emissions, lower operating costs, and a commitment to sustainability make EVs an appealing choice for environmentally conscious consumers. However, despite their growing adoption across the automotive industry, a noticeable trend has emerged in recent times: the rapid depreciation of second-hand electric cars. This sudden decline in value has puzzled many potential buyers. Let’s explore the factors driving this phenomenon.</p>

                        <h2><a href="#Rapid-Technological-Advancements">Rapid Technological Advancements</a></h2>
                        <p>
                            One of the primary reasons for the depreciation of second-hand electric cars is the swift pace of technological advancements in the sector. Newer EV models often feature improved battery technology, longer ranges, and enhanced performance over previous generations, meaning what was once cutting-edge quickly becomes outdated. Buyers prefer the latest models with superior technology, leaving older versions less desirable and thus significantly depreciating their value.
                            <br /><br />
                            Innovations in charging infrastructure and software updates also contribute to this trend. Consumers are willing to pay more for vehicles that offer the latest features and conveniences, further affecting the resale value of older EV models on the market.
                        </p>
                        <BlogCTA n='1' />

                        <h2><a href="#Battery-Degradation-Concerns">Battery Degradation Concerns</a></h2>
                        <p>
                            The battery is the heart of an electric vehicle, crucial to its performance and range. Over time, lithium-ion batteries degrade, leading to reduced capacity and diminished range. This process, known as battery degradation, is a familiar issue for anyone with a smartphone. However, unlike upgrading a phone, replacing a car battery is costly and inconvenient.
                            <br /><br />
                            Potential buyers of second-hand EVs are wary of purchasing vehicles with older batteries nearing the end of their lifespan. The perceived risk of poor battery performance deters buyers, causing the value of these cars to plummet. Many opt for newer models to ensure better battery life and reliability.
                        </p>
                        <img className="img_full" src="./assets/blog/depreciation/charge.webp" alt="An electric vehicle being charged on the street." />

                        <h2><a href="#Limited-Resale-Market">Limited Resale Market</a></h2>
                        <p>
                            The resale market for used electric vehicles is considerably smaller than that for traditional gasoline-powered cars. Simply put, limited demand means lower resale values.
                            <br /><br />
                            Despite the environmental benefits of EVs, many consumers remain cautious, preferring the tried-and-trusted reliability of petrol or diesel cars. This reluctance keeps the resale market for used EVs constrained, driving down prices.
                        </p>

                        <h2><a href="#Impact-of-Government-Incentives">Impact of Government Incentives</a></h2>
                        <p>
                            Government initiatives and incentives, <a className="outbound" target="_blank" href='https://www.gov.uk/electric-vehicle-chargepoint-grant-household'>such as the EV chargepoint grant</a>, have played a crucial role in promoting electric vehicle adoption. These programs often include significant financial incentives for purchasing new EVs, making them more accessible and attractive to buyers. However, these benefits typically do not extend to second-hand electric cars.
                            <br /><br />
                            The focus on new EVs means that second-hand models are often overlooked. Buyers are more likely to take advantage of government incentives for new vehicles, further diminishing the demand for used electric cars and accelerating their depreciation.
                        </p>
                        <BlogCTA n='2' />

                        <h2><a href="#Perception-and-Awareness">Perception and Awareness</a></h2>
                        <p>
                            Perception and awareness also significantly influence the depreciation of second-hand electric cars. Despite the environmental and cost-saving benefits of EVs, some consumers remain sceptical. Concerns about the reliability of new technology, the availability of charging stations, and the overall safety of electric vehicles contribute to this caution.
                            <br /><br />
                            Many potential buyers prefer to wait until EVs become more mainstream and the technology has been developed thoroughly. This cautious approach delays the wider acceptance of second-hand electric cars, contributing to their rapid depreciation.

                        </p>
                        <img className="img_full" src="./assets/blog/depreciation/wind_farm.webp" alt="An on-shore wind farm." />


                        <h2><a href="#Conclusion">Conclusion</a></h2>
                        <p>The depreciation of second-hand electric cars is influenced by a combination of technological advancements, battery degradation, limited resale markets, government incentives, and consumer perception. While new EVs continue to gain popularity, the value of older models declines rapidly. Understanding these factors can help potential buyers make informed decisions and navigate the evolving landscape of electric vehicles. As technology continues to advance and public awareness grows, the dynamics of the second-hand EV market may change, potentially stabilising their resale value in the future.
                        </p>

                    </div>

                    <div className="nav">
                        <div id="scroll_point_cont" style={{ height: `${HEADINGS.length * 75}px` }}>
                            {HEADINGS.map((h, i) => (
                                <div onClick={this.handle_scroll_click} key={i} data-key={h} className='scroll_item'>
                                    <p>{h.replaceAll("-", " ")}</p>
                                    <div className="scroll_icon">
                                        <div className='inner_circle'></div>
                                        <div className='inner_bar'>
                                            <div className='inner_bar_progress'></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </section>

        );
    }
}
export default Depreciation;