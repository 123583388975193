import React, { Component } from "react";
import '../styles/components/cookie_popup.scss';

class CookiePopup extends Component {

    constructor(props) {
        super(props)
        this.state = {
            show_banner: false,
        }
    }

    componentDidMount() {

        document.getElementById('accept_btn').addEventListener('click', () => { this.accept_cookies() })
        document.getElementById('decline_btn').addEventListener('click', () => { this.decline_cookies() })

        window.gtag('set', 'url_passthrough', true);

        if (!localStorage.getItem('cookies_accepted')) {
            this.setState({ show_banner: true })
        }
    }

    accept_cookies = () => {

        this.setState({ show_banner: false })

        const CONSENT = {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted',
            'personalization_storage': 'granted',
            'functionality_storage': 'granted',
            'security_storage': 'granted',
        }

        window.gtag('consent', 'update', CONSENT)
        localStorage.setItem('cookies_accepted', 'accepted')
        localStorage.setItem('cookieConsent', JSON.stringify(CONSENT))

    }

    decline_cookies = () => {

        this.setState({ show_banner: false })

        const CONSENT = {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied',
            'personalization_storage': 'denied',
            'functionality_storage': 'denied',
            'security_storage': 'denied',
        }

        window.gtag('consent', 'update', CONSENT)
        localStorage.setItem('cookies_accepted', 'declined')
        localStorage.setItem('cookieConsent', JSON.stringify(CONSENT))
    }

    render() {

        return (
            <div id="cookie_banner" className={this.state.show_banner ? '' : 'hide'}>
                <div id='cookie_inner'>
                    <p>
                        This website uses cookies to improve your experience. By clicking
                        "Accept", you consent to the use of cookies (<a href='/cookies-policy' target='_blank'>Cookies Policy</a>).
                    </p>
                    <div id="btns">
                        <md-filled-button id='accept_btn'>Accept</md-filled-button>
                        <md-outlined-button id='decline_btn'>Decline</md-outlined-button>
                    </div>
                </div>
            </div>

        );
    }
}

export default CookiePopup;