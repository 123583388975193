import '../../styles/components/report_panels/specification.scss'
import { SPEC_METADATA, get_relevant_data } from '../../utils'
import React, { Component } from "react"

import ReportFrame from '../ReportFrame'
import Tooltip from '../Tooltip'
import { capitalize } from '@mui/material'
import FreeCheckCTA from '../cards/FreeCheckCTA'
let loaded = false

class Specification extends Component {

    constructor(props) {
        super(props)
        this.state = {
            counts: {
                overall: 0,
                reg: 0,
                dim: 0,
                engine: 0,
                perf: 0,
                elec: 0,
                fact: 0,
                opt: 0,
                tyres: 0,
                rims: 0,
                smmt: 0,
            },
            all_item_names: [],
            equipment_count: 0,
            all_data: []
        }
    }

    componentDidUpdate() {
        if (!loaded & Object.values(this.props.data).length > 0) {
            this.set_retrieval(this.props.data)
        }
    }

    componentDidMount() {
        if (Object.values(this.props.data).length > 0) {
            this.set_retrieval(this.props.data)
        }
        loaded = false
    }

    set_retrieval = (data) => {
        try {
            let all_names = []

            let counts = {}
            let all_count_sum = 0

            let all_item_names = []
            let equipment_count = 0

            for (let item of SPEC_METADATA) {
                let section = item.name

                let section_count = 0
                let section_data = this.set_state_or_get_data(data, section)

                // Non-listed
                for (let item of section_data[0]) {
                    section_count += 1
                    all_names.push([item[0], section])
                }

                if (section_data[1].length > 0 && !['Tyres', 'Rims'].includes(section)) {
                    section_count += 1
                    equipment_count += section_data[1].length
                }

                if (section === 'Tyres' || section === 'Rims') {
                    for (let item of section_data[1]) {
                        for (let row of item) {
                            if (!all_item_names.includes(row[0])) {
                                all_names.push([row[0], section])
                                all_item_names.push(row[0])
                            }

                        }
                    }
                }

                if (['Tyres', 'Rims'].includes(section) && section_data[1][0]) {
                    section_count += section_data[1][0].length
                }

                if ((section === 'Factory Equipment' || section === 'Optional Extras') && section_data[1].length > 0) {
                    section_count = section_data[1].length
                }

                counts[section] = section_count
                all_count_sum += section_count
            }
            counts["overall"] = all_count_sum

            let all_data = []
            let custom_spec = [...SPEC_METADATA]

            for (let item of custom_spec) {
                let data = this.set_state_or_get_data(this.props.data, item.name)
                if (data[0].length > 0 || data[1].length > 0) {

                    all_data.push(
                        {
                            name: item.name,
                            short_name: item.short_name,
                            max_items: item.short_name === "fact" ? data[1].length : item.max_items,
                            desc: item.desc,
                            icon: item.icon,
                            singles: data[0],
                            lists: data[1]
                        }
                    )

                }
            }

            this.setState({
                counts: counts,
                all_item_names: all_names,
                equipment_count: equipment_count,
                all_data: all_data
            })

            loaded = true

        } catch (error) {
            console.error(error)
        }
    }

    flatten_dict = (input, parentKey = "") => {
        let output = [];

        for (let key in input) {
            let key_formatted = key.split(/(?=[A-Z])/).join(' ')
            key_formatted = key === 'Laden' ? '(laden)' : key_formatted
            key_formatted = key === 'Psi' ? '(Psi)' : key_formatted
            key_formatted = key_formatted.replace("P C D", "PCD")

            const currentKey = parentKey ? `${parentKey} ${key_formatted}` : key_formatted;

            if (typeof input[key] === "object") {
                // Recursively traverse nested dictionaries
                output = output.concat(this.flatten_dict(input[key], currentKey, key));
            } else {
                // Add the composite key and value to the output list
                if (input[key] && !currentKey.includes('Bar')) {
                    output.push([currentKey, input[key]]);
                }
            }
        }

        return output;
    }

    set_state_or_get_data = (data, section) => {
        try {
            let section_code = SPEC_METADATA.find(item => item.name === section)['short_name']

            // Get the relevant metadata
            let metadata = get_relevant_data(data.api_metadata, 'S')

            let filtered_data = {}
            for (let m of metadata) {
                if (m['item_name'].split('-')[0] === section_code) {
                    m['value'] = data.api_response[m['item_name']]
                    filtered_data[m['item_name']] = m
                }
            }

            let non_listed_items = []
            let listed_items = []
            const to_bracket = [' cc', ' kg', ' mm', ' rpm', ' Nm', ' FtLb', ' Kw', ' bhp', ' mph', ' ml']
            const dont_lowercase = ['Cam Type', 'Engine Number', 'Driving Axle', 'Marque Code', 'Market Sector Code', 'Fuel System', 'Make', 'Model']
            for (let item in filtered_data) {
                if (filtered_data[item].value && filtered_data[item].value !== 'UNKNOWN' && filtered_data[item].value !== 'Unknown' && filtered_data[item].value !== 'NOT KNOWN') {
                    if (!filtered_data[item]['is_list']) {

                        // formatting
                        let item_name = filtered_data[item]['item_name'].split('-')[1]
                        item_name = item_name.split('_').join(' ')
                        for (let b of to_bracket) {
                            item_name = item_name.replace(b, ` (${b.trim()})`)
                        }
                        if (item_name.substring(item_name.length - 1) === 'L') {
                            item_name = item_name.replace(' L', ' (L)')
                        }

                        let item_value = String(filtered_data[item]['value'])
                        if (!dont_lowercase.includes(item_name)) {
                            item_value = capitalize(item_value.toLowerCase())
                        }
                        item_value = item_value === 'False' ? 'No' : item_value
                        item_value = item_name === "Tesla Supercharger Equipped" ? "Unknown" : item_value

                        if (item_value !== 'Na') {
                            non_listed_items.push([item_name, item_value, filtered_data[item]['tooltip']])
                        }


                    } else {
                        // Get the list of items
                        let list = filtered_data[item].value

                        // For each section, iterate through the keys/values
                        for (let list_sub_item of list) {

                            // Represents each section, to be separated in the front-end
                            let list_item = []

                            for (let [key, value] of Object.entries(list_sub_item)) {

                                // If the value is present, add it
                                if (value) {

                                    // If it's a dict, unnest it into a single item
                                    if (typeof (value) !== "string") {
                                        if (section !== "Factory Equipment List") {
                                            list_item.push(...this.flatten_dict(value, key))
                                        }
                                    } else {
                                        list_item.push([key, value])
                                    }
                                }
                            }

                            listed_items.push(list_item)
                        }
                    }
                }
            }

            return [non_listed_items, listed_items]

        } catch (error) {
            console.error(error)
        }


    }

    handle_search_input = (event) => {
        const INPUT_CLEAN = event.target.value.toLowerCase();

        const SEARCH_RESULTS = this.state.all_item_names.filter(
            item => item[0].toLowerCase().includes(INPUT_CLEAN)
        );

        const RESULTS_DIV = document.getElementById("search_results");

        while (RESULTS_DIV.firstChild) {
            RESULTS_DIV.removeChild(RESULTS_DIV.firstChild);
        }

        SEARCH_RESULTS.forEach(result => {
            const LIST_ITEM = document.createElement("div");
            LIST_ITEM.classList.add('result_item')
            LIST_ITEM.onclick = () => {

                const SHORT_NAME = SPEC_METADATA.find((item) => item.name === result[1]).short_name;

                document.getElementById("search_results").style.display = "none";
                document.getElementById(SHORT_NAME).classList.remove('hide')
                document.getElementById(SHORT_NAME).parentNode.querySelectorAll('.img_expand')[0].style.transform = 'rotate(45deg)'
                document.getElementById('spec_input').value = ''
                function delay(time) {
                    return new Promise(resolve => setTimeout(resolve, time));
                }

                delay(200).then(function () {
                    let element = document.getElementById(result[0])
                    window.scrollTo(0, element.getBoundingClientRect().y - (window.innerHeight / 2))
                    delay(100).then(function () {
                        element.parentNode.parentNode.classList.add('result')
                        delay(500).then(function () {
                            element.parentNode.parentNode.classList.remove('result')
                            delay(500).then(function () {
                                element.parentNode.parentNode.classList.add('result')
                                delay(500).then(function () {
                                    element.parentNode.parentNode.classList.remove('result')
                                });
                            });
                        });
                    });
                });
            }

            const ITEM_NAME = document.createElement("p")
            ITEM_NAME.classList.add('default_key')
            ITEM_NAME.textContent = result[0]
            LIST_ITEM.appendChild(ITEM_NAME)

            const SECTION_NAME = document.createElement("p")
            SECTION_NAME.classList.add('default_val')
            SECTION_NAME.textContent = result[1]
            LIST_ITEM.appendChild(SECTION_NAME)

            RESULTS_DIV.appendChild(LIST_ITEM)
        })

        if (INPUT_CLEAN !== "" && SEARCH_RESULTS.length === 0) {
            const NO_RESULT_DIV = document.createElement("div")
            NO_RESULT_DIV.classList.add('default_key')

            const NO_RESULT_TEXT = document.createElement("p");
            NO_RESULT_TEXT.textContent = "No results found"
            NO_RESULT_DIV.appendChild(NO_RESULT_TEXT)

            RESULTS_DIV.appendChild(NO_RESULT_DIV)
        }

        RESULTS_DIV.style.display = INPUT_CLEAN !== "" ? "flex" : "none"
    }

    handle_expand = (e) => {

        let elem = e.currentTarget.querySelectorAll('.img_expand')[0]
        let tgt = e.target

        if (tgt.classList.contains('header_left') || tgt.classList.contains('tt_elem') || tgt.classList.contains('default_key')) {
            return
        }

        if (elem.style.transform === 'rotate(45deg)') {
            elem.style.transform = 'none'
        } else {
            elem.style.transform = 'rotate(45deg)'
        }

        let parent = e.currentTarget.parentNode
        parent.querySelectorAll('.data_content')[0].classList.toggle('hide')
    }

    render() {
        const CNT = this.state.counts

        let to_include = []
        for (const [key, value] of Object.entries(CNT)) {
            if (value > 0) {
                to_include.push(key)
            }
        }

        let all_data = this.props.free_check ? SPEC_METADATA : this.state.all_data

        return (
            <div id="spec_panel" className="report_panel">
                
                {this.props.free_check && <FreeCheckCTA plate={this.props.plate} img='./assets/CTA/specs.webp' title={"View the full spec list"} text="Get the lowdown on the specs, features, and extras for this car" />}

                <ReportFrame
                    title={"Specs"}
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    nodata={all_data.length === 0}
                >

                    <div id="spec_content">

                        {!this.props.free_check && <div id='search_bar'>
                            <img src='./assets/icons/search.svg' alt='Magnifying glass' />
                            <input
                                id="spec_input"
                                type="text"
                                onChange={this.handle_search_input}
                                placeholder='Search for a spec'
                                maxLength={30}
                            />
                        </div>}
                        <div id="search_results_cont">
                            <div id="search_results"></div>
                        </div>


                        {all_data.map((item, i) => (
                            <div key={i} className="spec_item">
                                <div className={this.props.free_check ? "data_header free_check" : "data_header"} onClick={!this.props.free_check ? this.handle_expand : null}>

                                    <div className="header_left">
                                        <p className='default_key'>{item.name}</p>
                                        <Tooltip text={item.desc} />
                                    </div>

                                    <div className="header_right">
                                        <div className='marker'>
                                            <p className='count_text'><span className={this.props.free_check ? 'blur' : ''}>{this.props.free_check ? item.max_items : CNT[item.name]}</span> / {item.max_items}</p>
                                        </div>
                                        {!this.props.free_check && <img className='img_expand' src='./assets/icons/expand.svg' alt='Expand icon' />}
                                    </div>

                                </div>

                                <div className={`data_content hide`} id={item.short_name}>

                                    {item.singles.map((item, j) => (
                                        <div key={j} className='content_row'>
                                            <div className='key_cont'>
                                                <p id={item[0]} className='default_key'>{item[0]}</p>
                                                {item[2] && <Tooltip text={item[2]} />}
                                            </div>
                                            <p className='default_val'>{item[1]}</p>
                                        </div>
                                    ))}

                                    {item.lists.map((list, k) => (
                                        <div key={k}>
                                            {k < item.lists.length && (k !== 0 || item.singles.length > 0) && <div className='list_sep'></div>}

                                            {list.map((item, l) => (
                                                <div key={l} className='content_row'>
                                                    <p id={item[0]} className='default_key'>{item[0].replace("PackageContentList 0 Description", "Description").replace("PackageContentList 1 Description", "Description")}</p>
                                                    <p className='default_val'>{item[1]}</p>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>

                                {i < all_data.length - 1 && <div className='sep'></div>}

                            </div>
                        ))}
                    </div>


                </ReportFrame>
            </div>
        );
    }
}

Specification.displayName = 'Specs'

export default Specification