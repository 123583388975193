import React, { useState } from 'react';
import '../styles/components/hamburger.scss';

const HamburgerMenu = ({ children, colour }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`hamburger-menu ${isOpen ? 'open' : ''}`} onClick={handleClick}>
            <span style={{backgroundColor: colour}}></span>
            <span style={{backgroundColor: colour}}></span>
            <span style={{backgroundColor: colour}}></span>

            {isOpen && (
                <div className="menu-items">
                    {children}
                </div>
            )}
        </div>
    );
};

export default HamburgerMenu;
