import '../../styles/components/graphs/emission_bar.scss';

import React, { Component } from "react";

class EmissionsBar extends Component {


    render() {
        const { value, name, show_val } = this.props

        let series = [
            { width: 40, name: 'A', label: '0', color: '#40ae49', min: 0, max: 0 },
            { width: 50, name: 'B-C', label: '1-75', color: '#8bb93f', min: 1, max: 75 },
            { width: 60, name: 'D-E', label: '76-100', color: '#ccd22a', min: 76, max: 100 },
            { width: 70, name: 'F-G', label: '101-130', color: '#f9ec10', min: 101, max: 130 },
            { width: 80, name: 'H-I', label: '131-170', color: '#f8b31b', min: 131, max: 170 },
            { width: 90, name: 'J-K', label: '171-225', color: '#ed7324', min: 171, max: 225 },
            { width: 100, name: 'L-M', label: '226-256+', color: '#ee2e2a', min: 226, max: 99999 }
        ];


        const TARGET = series.find(entry => {
            return value >= entry.min && value <= entry.max;
        });

        if (TARGET && show_val) {
            TARGET.label = `${name} (${value} g/km)`;
            TARGET.width = 100;
        }

        const idx = series.indexOf(TARGET)

        const CD = this.props.car

        return (
            <div id="emissions_bar_plot">
                {series.map((row, i) => (
                    <div key={i} className={i === idx ? 'bar active' : 'bar'} style={{ backgroundColor: row.color, width: `${row.width}%` }}>
                        {i === idx && <div id='target_row'>
                            <img src={CD.logo} alt='Car manufacturer logo'/>
                            <p className='car'>{CD.car.replace(' UNKNOWN', '')}</p>

                        </div>}
                        {i === idx && <p className='rating'>{row.name}</p>}
                    </div>
                ))}
            </div>
        );
    }
}
export default EmissionsBar;