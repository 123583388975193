import React, { Component } from "react";

import '../../styles/components/article.scss';
import BlogCTA from '../cards/BlogCTA'

class AutoAct extends Component {

    render() {

        const ARTICLE = this.props.article
        return (
            <section className="article">

                <div className="article_opener">
                    <div className="opener_inner">

                        <div className="left">
                            <h1 className="article_h1">{ARTICLE.title}</h1>
                            <p className="article_subtitle">{ARTICLE.subtitle}</p>
                        </div>

                        <img alt={ARTICLE.img_alt} src={ARTICLE.img_src} />
                    </div>
                </div>

                <div className="article_body">
                    <div className="info">
                        <div className="metadata">
                            <p className="meta_author">{ARTICLE.author}</p>
                            <p>{ARTICLE.date}</p>
                            <p>{ARTICLE.read} read</p>
                        </div>
                        <div className="socials">
                            <a href="https://www.instagram.com/xegon_automotive" target="_blank" rel="noreferrer"><img alt="Instagram logo" src="./assets/icons/insta_square.svg" /></a>
                            <a href="https://www.facebook.com/profile.php?id=61550323427271" target="_blank" rel="noreferrer"><img alt="Facebook logo" src="./assets/icons/fb_square.svg" /></a>
                            <a href="https://www.linkedin.com/company/98098382/" target="_blank" rel="noreferrer"><img alt="LinkedIn Logo" src="./assets/icons/linkedin_square.svg" /></a>
                        </div>
                    </div>

                    <div className="content">
                        <p>Recent legislation has cleared the path for self-driving cars to grace British roads within the next couple of years. The law, dubbed the <a className="outbound" target="_blank" href="https://www.gov.uk/government/news/self-driving-vehicles-set-to-be-on-roads-by-2026-as-automated-vehicles-act-becomes-law">Automated Vehicles Act</a>, is aimed at regulating vehicles before paving the way for the deployment of fully autonomous vehicles classified as "level four". Unlike their predecessors, these vehicles won't necessitate a "safety driver", enabling motorists to relinquish control and assume the role of mere passengers during transit.
                            <br /><br />

                            The legislation aligns with the scale of automation developed by SAE International, recognizing six levels of automation ranging from manual to fully autonomous. Previously, British law only permitted vehicles up to level two, which offer limited automation.
                            <br /><br />
                            Under the provisions of the Automated Vehicles Act, passengers relinquish responsibility for the vehicle's performance while in self-driving mode. Instead, liability shifts to entities such as vehicle manufacturers, insurance firms, or software developers. However, owners will still bear responsibility for ensuring roadworthiness and insurance coverage.
                            <br /><br />
                        </p>

                        <BlogCTA n='1' />
                        <p>
                            Proponents argue that automated vehicles hold the potential to enhance overall road safety by mitigating risks associated with human errors, such as drink-driving, speeding, and driver fatigue—factors contributing to approximately 88% of road collisions.
                            <br /><br />


                            Trials for self-driving vehicles are already underway across the nation, with cities like London, Oxford, and Milton Keynes serving as testing grounds. Wayve, a British autonomous vehicle company, recently secured over $1 billion in funding to advance its commercialisation efforts.
                            <br /><br />

                            Companies in the delivery sector, including <a className="outbound" target="_blank" href="https://www.ocadogroup.com/investors/ocado-invests-in-oxbotica-autonomous-vehicle-software/">Ocado</a> and <a className="outbound" target="_blank" href="https://corporate.asda.com/newsroom/2023/04/17/asda-and-wayve-launch-uks-largest-self-driving-grocery-home-delivery-trial">Asda</a>, are poised to embrace autonomous vehicles for grocery and parcel delivery services. The prospect of autonomous buses also looms on the horizon, particularly for controlled environments like airport shuttles or local transit routes. Agriculture is also poised for transformation, with companies like John Deere exploring AI and robotics integration.

                        </p>
                        <img className="img_full" src="./assets/blog/self_driving/robots.webp" alt="Self driving robotic transport units." />
                        <p>
                            Transport Secretary Mark Harper has lauded the enactment of the bill as a significant milestone for the self-driving industry. He envisions a transformative future for British transportation, emphasising the economic and safety benefits afforded by self-driving technology. While acknowledging individuals' freedom to drive manually, Harper anticipates the widespread adoption of self-driving vehicles on British roads as early as 2026.
                            <br /><br />

                            However, recent polls indicate a lingering scepticism among Britons regarding autonomous vehicles. Despite public apprehension, government officials stress that road safety remains paramount, asserting that self-driving vehicles must meet or exceed the safety standards upheld by careful and competent human drivers.
                            <br /><br />

                            The British government aims to preempt challenges encountered by the self-driving industry in the United States, where incidents such as the collision of two driverless Waymo taxis in Arizona prompted technology recalls. Similarly, Cruise, a subsidiary of General Motors, recalled nearly a thousand autonomous cars following an incident in San Francisco.
                            <br /><br />

                            While experts foresee a delay in widespread private ownership of autonomous vehicles whilst the technology develops and costs are brought down, it seems the dawn of <a className="outbound" target="_blank" href="https://www.gov.uk/transport/self-driving-vehicles">self-driving vehicles</a> is almost upon us.

                        </p>
                    </div>

                    <div className="nav">

                    </div>
                </div>

            </section>

        );
    }
}
export default AutoAct;