import React, { Component } from "react";
import '../../styles/components/graphs/comparison_bar.scss';

import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import HC_access from "highcharts/modules/accessibility";

HC_access(Highcharts);
HC_more(Highcharts);

class ComparisonBarElec extends Component {
    chartRef = React.createRef();
    render() {

        const AVG = 330
        const WORST = 221
        const FC = this.props.free_check

        let W = window.innerWidth
        let H = ''
        if (W >= 1240) {
            H = '95%'
        } else if (W > 740) {
            H = '70%'
        } else if (W >= 600) {
            H = '130%'
        } else if (W > 370) {
            H = '75%'
        } else {
            H = '130%'
        }

        const options = {
            chart: {
                type: 'column',
                style: {
                    fontFamily: 'Nunito Sans, sans-serif'
                },
                height: H,
                marginLeft: 65,
                marginTop: 20,
            },
            title: { text: null },
            credits: { enabled: false, },
            legend: { enabled: false, },
            tooltip: { enabled: false },
            xAxis: { categories: FC ? ['SAMPLE', 'SAMPLE', 'SAMPLE'] : ['This Car', 'Avg.', 'Worst'], },
            colors: ['rgb(145, 203, 188)', 'rgb(69, 143, 188)', 'rgb(51, 93, 162)'],
            series: [{ data: [this.props.elec, AVG, WORST] }],

            yAxis: {
                allowDecimals: false,
                title: { text: 'Watt-hours per mile' },
            },

            plotOptions: {
                column: {
                    colorByPoint: true
                },
                series: {
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            if (this.y) {
                                return this.y;
                            }
                        },
                    }
                }
            },

            responsive: {
                rules: [{
                    condition: { maxWidth: 100 },
                    subtitle: { text: null },
                    credits: { enabled: false },

                    chartOptions: {
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        },

                        yAxis: {
                            labels: { align: 'left', x: 0, y: -5 },
                            title: { text: null }
                        },

                    }
                }]
            }
        };

        return (
            <div id="comparison_bar">
                <div className={this.props.free_check ? "block" : ""}></div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={this.chartRef}
                />
            </div>
        );
    }
}

export default ComparisonBarElec;
