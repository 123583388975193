import React, { Component } from "react";

import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import HC_access from "highcharts/modules/accessibility";
import NoData from '../NoData';

HC_access(Highcharts);
HC_more(Highcharts);

require("highcharts/modules/series-label")(Highcharts);

class LineChartCert extends Component {
    chartRef = React.createRef();
    render() {

        const YEAR_LIST = [''].concat(this.props.years, [''])
        const DATA_LIST = this.props.data

        let data_list_formatted = [{ y: null, name: null }]

        for (let i = 0; i < DATA_LIST.length; i++) {
            data_list_formatted.push({ name: (i + 1).toString(), y: DATA_LIST[i] })
        }
        data_list_formatted.push({ y: null, name: null })
        const SRC = this.props.sources

        let W = window.innerWidth
        let H = ''
        if(W >= 1240){
            H = '100%'
        }else if (W >= 905){
            H = '60%'
        }else if(W >= 600){
            H = '90%'
        }else if(W >= 400){
            H = '90%'
        }else{
            H = "110%"
        }

        const options = {
            chart: {
                events: {
                    load: function () {
                        this.renderer.rect(65, this.plotTop + this.plotHeight + 10, this.plotWidth, 20, 5)
                            .attr({
                                'stroke-width': 0,
                                fill: '#E3EAE7',
                                zIndex: 0,
                                height: 50
                            })
                            .add();
                    }
                },
                style: {
                    fontFamily: 'Nunito Sans, sans-serif'
                },
                height: H,
                marginLeft: 65,
                marginTop: 20,
            },

            title: { text: null },
            credits: { enabled: false },
            yAxis: {
                title: { text: 'Miles', style: { fontSize: 14 } }, min: 0
            },
            xAxis: {
                categories: YEAR_LIST,
                labels: {
                    style: {
                        color: '#171D1B',
                        zIndex: 5,
                        fontWeight: 600,
                        fontSize: 14,
                    },
                    y: 28,
                },
            },

            plotOptions: {
                series: {
                    color: '#0E9384',
                    pointPlacement: "on",
                    dataLabels: {
                        enabled: true,
                        color: '#171D1B',
                        align: 'center',
                        format: '{point.name}',
                        style: {
                            textOutline: 'none',
                            fontSize: 16,
                            fontWeight: 600
                        }
                    }
                }
            },

            tooltip: {
                formatter: function () {
                    return `<b>${this.x}</b><br>Source: ${SRC[this.key - 1]} <br>${this.y.toLocaleString('en-GB')} mi`;
                }
            },

            series: [{
                name: '',
                type: 'area',
                showInLegend: false, // to hide the series from the legend
                data: data_list_formatted,
                fillColor: {
                    linearGradient: [0, 0, 0, 300], // apply gradient vertically
                    stops: [
                        [0, '#D0D5DD'],
                        [1, 'rgba(255,255,255,0)'] // gradient from series color to transparent
                    ]
                }
            }],

        };

        return (
            YEAR_LIST.length > 2 ?
                <div id="mileage_graph_cont">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={this.chartRef}
                    />
                </div> : <NoData />
        );
    }
}

export default LineChartCert;
