import "../styles/components/report_frame.scss";

import React, { Component } from "react";
import Blocker from "./Blocker";
import NoData from "./NoData";

class ReportFrame extends Component {
    render() {
        const { alert, nodata, grow, col_classes, id, title, children, free_check, plate, block_children } = this.props;

        let border = alert ? '1px solid #BA1A1A' : '1px solid #BEC9C5'
        let background = alert ? '#FFEDEA' : '#F4FBF8'
        let text_colour = alert ? '#410002' : '#00201C'
        let icon_bg = alert ? ' #FFDAD6' : '#CCE8E2'
        let icon = alert ? './assets/icons/marker_fail_v2.svg' : './assets/icons/marker_pass_v2.svg'

        background = nodata ? '#FFF2E7' : background
        text_colour = nodata ? '#523916' : text_colour
        icon_bg = nodata ? '#FFE3D1' : icon_bg
        icon = nodata ? './assets/icons/marker_warn_v2.svg' : icon

        background = free_check ? '#E9EFEC' : background
        text_colour = free_check ? '#171D1B' : text_colour
        icon_bg = free_check ? '#DAE5E1' : icon_bg
        icon = free_check ? './assets/icons/lock.svg' : icon

        return (
            <div className={`report_frame ${col_classes}`} style={{ flex: grow, border: border }} id={id}>
                <div style={{ backgroundColor: background }} className={free_check ? "frame_header free_check" : "frame_header"}>

                    <div className="header_cont">
                        <div className="marker" style={{ backgroundColor: icon_bg }}>
                            <img alt='' src={icon} />
                        </div>
                        <div className="title_parts">
                            <h2 style={{ color: text_colour }}>{title}</h2>
                        </div>
                    </div>

                    {free_check &&
                        <md-filled-button href={`/buy?count=1&plate1=${plate}`} id='prem_unlock'>Full Check</md-filled-button>
                    }


                </div>

                {(!free_check || !block_children) && <div className="frame_content">
                    {!nodata && children}
                    {nodata && <NoData />}
                </div>}


                {free_check && block_children &&
                    <div className="frame_content free_check">
                        {children}
                        <Blocker />
                    </div>}

            </div>
        )
    }
}

export default ReportFrame;
