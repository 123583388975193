import "../../styles/views/errors/404.scss";

import React, { Component } from 'react';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import CookiePopup from '../../components/CookiePopup'
import PlateInput from "../../components/PlateInput";
import { Helmet, HelmetProvider } from 'react-helmet-async';

class InvalidPlate extends Component {

    componentDidMount() {
        document.getElementById("root").style.height = `${window.screen.height}px`
        document.getElementById("main_404").style.height = `${window.screen.height}px`
    }

    render() {
        return (
            <HelmetProvider>

                <Helmet>
                    <title>Invalid Plate | Auto Reg Check</title>
                    <link rel="canonical" href="https://www.autoregcheck.co.uk/invalid-plate/" />
                </Helmet>
                
                <main id="main_404">
                    <Header />
                    <section>
                        <div id="content">
                            <h1 className="red">Error</h1>
                            <h2>No data</h2>
                            <p className="p_content">Sorry, there is no data available for this vehicle.<br /> Please enter a new plate and try again.</p>

                            <PlateInput input_id={"invalid_plate_id"} />

                        </div>

                        <img id="main_img" alt="A car with a red cross over it" src="./assets/errors/no_plate.png" />
                    </section>
                    <Footer />
                    <CookiePopup />
                </main>
            </HelmetProvider>

        )
    }
}

export default InvalidPlate;
