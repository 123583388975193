export const ARTICLES = [
    {
        title: "The Ultimate Used Car Inspection Checklist",
        html_title: "Used Car Inspection Checklist | Auto Reg Check",
        html_desc: "Everything You Need To Know About Inspecting A Used Vehicle | Use Our Exhaustive Checklist To Thoroughly Check A Used Car And Ensure You Get A Good Deal",
        marker: "checklist",
        subtitle: "Buying a used car? Ensure your inspection is thorough with our ultimate inspection checklist!",
        img_src: "./assets/blog/inspection/card.webp",
        img_alt: "A person using their phone, with a broken-down car in the background.",
        read: "10 min",
        author: "Jack Baker",
        author_profile: "./assets/blog/JB_author.svg",
        date: "24/10/2023",
        live: true,
    },

    {
        title: "Full Self-Driving Cars Could Soon Be Coming to the UK",
        html_title: "Full self-driving cars could soon be coming to the UK | Auto Reg Check",
        html_desc: "Are You Ready For The Automated Vehicles Act To Revolutionise British Roads by 2026, Paving The Way For Full Self-Driving Cars?",
        marker: "news",
        subtitle: "Automated Vehicles Act to revolutionise British roads by 2026. Are you ready to share the road with fully self-driving cars?",
        img_src: "./assets/blog/self_driving/card.webp",
        img_alt: "The view from the drivier's seat of a Tesla.",
        read: "5 min",
        author: "Jack Baker",
        author_profile: "./assets/blog/JB_author.svg",
        date: "23/05/24",
        live: true,
    },

    {
        title: "REVEALED: The Cars With The Best And Worst MOT Pass Rates in 2023",
        html_title: "The Cars With The Best And Worst MOT Pass Rates in 2023",
        html_desc: "Learn about the emerging trends in MOT tests across the UK in our 2023 MOT data report! Take a deep-dive into the pass rates, common failures, and best performing models.",
        marker: "data",
        subtitle: "From pass rates to failure items, we take a look at the best and worst performing cars in our 2023 MOT report.",
        img_src: "./assets/blog/mot_2023/card.webp",
        img_alt: "A man fixing something under the hood fo a car.",
        read: "10 min",
        author: "Charlie Bignell",
        author_profile: "./assets/blog/CB_author.svg",
        date: "28/06/24",
        live: true,
    },

    {
        title: "Why are EVs Depreciating so Fast?",
        html_title: "What's Behind the Big Depreciation of Second Electric Hand Cars? | Auto Reg Check",
        html_desc: "Second Hand Electric Cars Are Plummeting In Value. Dive into the surprising trends and influences behind this market shift.",
        marker: "news",
        subtitle: "Second hand EVs are plummeting in value. Dive into the surprising trends and influences behind this market shift.",
        img_src: "./assets/blog/depreciation/card.webp",
        img_alt: "An electric vehicle charging port being used",
        read: "7 min",
        author: "Jack Baker",
        author_profile: "./assets/blog/JB_author.svg",
        date: "23/07/24",
        live: true,
    },

    {
        title: "Top 10 Car Check Providers",
        html_title: "Top 10 Car Check Providers | Auto Reg Check",
        html_desc: "Automated Car Checks Are A Great Tool To Find Out More About A Car Before You Buy - Use Our Detailed Guide To Find Out Which Reg Check Is Best",
        marker: "top10",
        subtitle: "Online car checks are an essential tool to learn everything about a car before you buy, but which is best?",
        img_src: "./assets/blog/top10/card.webp",
        img_alt: "A car dashboard display",
        read: "5 min",
        author: "Jack Baker",
        author_profile: "./assets/blog/JB_author.svg",
        date: "02/09/24",
        live: true,
    },

    {
        title: "How to Spot a Clocked Car",
        html_title: "",
        html_desc: "",
        marker: "scams",
        subtitle: "'Clocking' hides the true mileage of a used car. Safeguard your investment by learning how to spot this scam.",
        img_src: "",
        img_alt: "",
        read: "5 min",
        author: "Jack Baker",
        author_profile: "./assets/blog/JB_author.svg",
        date: "Coming Soon",
        live: false,
    },





]