import React, { Component } from "react";

import '../../styles/components/user_journey/loading.scss';

class Loading extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div id="loading_panel_buy">
                <div id='loading_text'>
                    <h1>Generating ARC Report </h1> 
                    <h2>{this.props.total_loaded + 1} of {this.props.total_to_load}</h2>
                    <div id="plate">
                        <p>{this.props.current_loading}</p>
                    </div>
                </div>

                <div id="loading_animation">
                    <div className="bar bar1"></div>
                    <div className="bar bar2"></div>
                    <div className="bar bar3"></div>
                    <div className="bar bar4"></div>
                    <div className="bar bar5"></div>
                    <div className="bar bar6"></div>
                    <div className="bar bar7"></div>
                    <div className="bar bar8"></div>
                </div>
            </div>
        );
    }
}
export default Loading;