import React, { Component } from "react";
import '../styles/components/blocker.scss';

class Blocker extends Component {
    render() {
        return (
            <div className={this.props.non_frame ? "blocker_cont non_frame" : "blocker_cont"}>
            </div>
        );
    }
}
export default Blocker;