import React, { Component } from "react";
import '../styles/components/report_mob_nav.scss';
import { NAV_ITEMS } from "../utils";

class ReportMobNav extends Component {

    componentDidUpdate() {

        let ac = this.props.action

        if (ac[1] === 'max') {
            document.getElementById('report_mob_nav').scrollBy({ top: 999, left: 999, behavior: 'smooth' })
        } else if (ac[1] === 'min') {
            document.getElementById('report_mob_nav').scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        } else {
            let spacer = (ac[0] * 74) + 16 + 34
            let left = spacer - (window.innerWidth / 2)
            document.getElementById('report_mob_nav').scrollTo({ top: 0, left: left, behavior: 'smooth' })
        }
    }


    render() {

        return (
            <div id='report_mob_nav_main'>

                <div id='report_mob_nav'>
                    {NAV_ITEMS.map((item, i) => (
                        <div key={i} className={this.props.active === item.name ? "mob_item active" : "mob_item"} onClick={() => { this.props.handle_nav(item.name) }}>
                            <div className="icon">
                                <img alt='List' draggable='' src={`./assets/icons/report_nav_${item.name}.svg`} />
                                {this.props.alerts[item.name].length > 0 && <div className="alert"><p>{this.props.alerts[item.name].length}</p></div>}
                            </div>
                            <p>{item.display_name}</p>
                        </div>
                    ))}
                </div>
            </div>

        );
    }
}
export default ReportMobNav;