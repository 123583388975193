import React, { Component } from "react";
import '../styles/components/report_nav.scss';
import { NAV_ITEMS } from "../utils";

class ReportNav extends Component {

    render() {

        let active_item = {}
        for (let i of NAV_ITEMS) {
            if (i.name === this.props.active) {
                active_item = i
                break
            }
        }

        let list = this.props.free_check ? active_item['free_check_items'] : active_item['report_items']
        let locked_list = this.props.free_check ? active_item['premium_items'] : []

        return (
            <div id='report_nav_main'>

                <div id='report_nav'>
                    {NAV_ITEMS.map((item, i) => (
                        <div key={i} className={this.props.active === item.name ? "nav_item active" : "nav_item"} onClick={() => { this.props.handle_nav(item.name) }}>
                            <div className="icon">
                                <img alt='List' draggable='' src={`./assets/icons/report_nav_${item.name}.svg`} />
                                {this.props.alerts[item.name].length > 0 && <div className="alert"><p>{this.props.alerts[item.name].length}</p></div>}
                            </div>
                            <p>{item.display_name}</p>
                        </div>
                    ))}
                </div>

                <div id='components'>
                    {list.map((item, i) => (
                        <div key={i} className="list_item">
                            {item !== 'CO2 Emissions' && <p>{item}</p>}
                             {item === 'CO2 Emissions' && <p>CO<sub>2</sub> Emissions</p>}
                            <div className={this.props.alerts[this.props.active].includes(item) ? ' alert' : 'alert hide'}>
                                <p>{this.props.alerts[this.props.active].filter((v) => (v === item)).length}</p>
                            </div>
                        </div>
                    ))}
                    {locked_list.map((item, i) => (
                        <div key={i} className="list_item premium" onClick={() => {window.location.href = `/buy?count=1&plate1=${this.props.plate}`}}>
                            <img src='./assets/icons/lock.svg' alt='Lock icon' />
                            <p>{item}</p>
                            <div className={this.props.alerts[this.props.active].includes(item) ? ' alert' : 'alert hide'}>
                                <p>{this.props.alerts[this.props.active].filter((v) => (v === item)).length}</p>
                            </div>
                            
                        </div>
                    ))}
                </div>
            </div>

        );
    }
}
export default ReportNav;