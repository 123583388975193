import { format_money, format_money_short } from "../../utils";

import React, { Component } from "react";

import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import HC_access from "highcharts/modules/accessibility";
import NoData from "../NoData";
HC_access(Highcharts);
HC_more(Highcharts);

class LineChartVal extends Component {
    chartRef = React.createRef();
    render() {

        let W = window.innerWidth
        let H = ''
        if (W >= 1240) {
            H = '100%'
        } else if (W >= 905) {
            H = '60%'
        } else if (W >= 600) {
            H = '90%'
        } else if (W >= 400) {
            H = '90%'
        } else {
            H = "110%"
        }

        const options = {
            chart: {
                events: {
                    load: function () {
                        this.renderer.rect(55, this.plotTop + this.plotHeight + 10, this.plotWidth + 20, 20, 5)
                            .attr({
                                'stroke-width': 0,
                                fill: '#E3EAE7',
                                zIndex: 0,
                                height: 50
                            })
                            .add();
                    }
                },
                style: {
                    fontFamily: 'Nunito Sans, sans-serif'
                },
                height: H,
                marginLeft: 65,
                marginTop: 20,
            },

            title: { text: null },
            credits: { enabled: false },

            yAxis: {
                title: { text: null },
                labels: {
                    formatter: function () {
                        return format_money_short(this.value) === "Unknown" ? "£0" : format_money_short(this.value)
                    }
                }
            },

            xAxis: {
                categories: this.props.years,
                labels: {
                    style: {
                        color: '#171D1B',
                        zIndex: 5,
                        fontSize: 14,
                        fontWeight: 600,
                    },
                    y: 28,
                },
            },

            plotOptions: {
                series: {
                    color: '#0E9384',
                    pointPlacement: "on"
                }
            },

            tooltip: {
                formatter: function () {
                    return `<b>${this.x}</b><br>${format_money(this.y)}`;
                }
            },

            series: [{
                name: '',
                type: 'area',
                showInLegend: false,
                data: this.props.values,
                fillColor: {
                    linearGradient: [0, 0, 0, 350], // apply gradient vertically
                    stops: [
                        [0, '#D0D5DD'],
                        [1, 'rgba(255,255,255,0)'] // gradient from series color to transparent
                    ]
                },
                zoneAxis: 'x',
                zones: [{
                    value: this.props.years.length - 5
                }, {
                    dashStyle: 'dot',
                }]
            }],
        }

        return (

            this.props.years.length > 2 ?
                <div id="valuation_graph_cont">
                    <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={this.chartRef}
                />
                </div> : <NoData />
        );
    }
}
export default LineChartVal;