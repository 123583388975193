import "../../styles/views/errors/500.scss";

import React, { Component } from 'react';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import CookiePopup from '../../components/CookiePopup'
import { Helmet, HelmetProvider } from 'react-helmet-async';

class ServerError extends Component {
    
    componentDidMount(){
        document.getElementById("root").style.height = `${window.screen.height + 45}px`
        document.getElementById("main_500").style.height = `${window.screen.height + 45}px`
    }

    render() {
        return (
            <HelmetProvider>

            <Helmet>
                <title>Error | Auto Reg Check</title>
                <link rel="canonical" href="https://www.autoregcheck.co.uk/server-error/" />
            </Helmet>
            <main id="main_500">
                <Header />
                <section>
                    <div>
                        <h1>500 error</h1>
                        <h2>Something went wrong</h2>
                        <p>Sorry, we weren't able to process your last action. Please try again later and get in touch if you're having difficulties</p>
                        <button onClick={() => { window.location.href = "/" }}>Return home</button>
                    </div>

                    <img alt="A graphic showing the number 500" src="./assets/errors/500.svg" />
                </section>
                <Footer />
                <CookiePopup />
            </main>
            </HelmetProvider>

        )
    }
}

export default ServerError;
