import '../../styles/components/graphs/donut_guage.scss';

import React, { Component } from "react";

import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
// import HC_access from "highcharts/modules/accessibility";

// HC_access(Highcharts);
HC_more(Highcharts);

require("highcharts/modules/solid-gauge")(Highcharts);

class MiniDonutGuage extends Component {
    chartRef = React.createRef();
    render() {

        const options = {
            chart: {
                type: 'solidgauge',
                spacing: [0, 0, 0, 0],
                margin: [0, 0, 0, 0],
                height: 36,
                style: {
                    fontFamily: 'Nunito Sans, sans-serif'
                }
            },
            title: { text: null },
            credits: { enabled: false, },
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [{
                    outerRadius: '55%',
                    innerRadius: '43%',
                    backgroundColor: Highcharts.color('#D0D5DD').setOpacity(0.3).get(),
                    borderWidth: 0
                }]
            },
            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: [],
            },

            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: false,               
                    },
                    rounded: false
                }
            },
            tooltip: { enabled: false },

            series: [{
                name: 'Pass Rate',
                data: [{
                    color: '#0E9384',
                    radius: '55%',
                    innerRadius: '43%',
                    y: this.props.percentage
                }]
            }]
        };

        return (
            <div id="pass_rate_donut_mini">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={this.chartRef}
                />
            </div>
        );
    }
}

export default MiniDonutGuage;
