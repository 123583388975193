import "../styles/views/help.scss";

import React, { Component } from 'react';
import Breadcrumb from "../components/Breadcrumb";
import Header from '../components/Header'
import Footer from '../components/Footer'

import CookiePopup from '../components/CookiePopup'
import { Helmet, HelmetProvider } from 'react-helmet-async';

import FAQ from "../components/FAQ";

const FAQ_FEEDBACK = [
    [
        "Who can I contact about a specific question I have?",
        "Email customersupport@autoregcheck.co.uk or enter a message into our dedicated chatbot, and we'll get back to you as soon as we can."
    ],
    [
        "I have a cool idea for a new feature. Who should I talk to?",
        "We’ve always got our ear out for new features and ideas from our customers, so don’t hesitate to get in touch!\n\nHead to the feedback page to submit a form with your feedback or suggestion, or send us an email at customersupport@autoregcheck.co.uk."
    ]
]

const FAQ_REPORTS = [
    [
        "How can I obtain an ARC report?",
        "Head to our pricing page to get started! Once you’ve entered up to three license plates, we will perform a quick check to confirm we have the right details and you'll be able to view your free vehicle check. To purchase a full check, submit your payment details and we’ll get to work generating your unique report.\n\nOnce generated, we will provide the links to you in the website, so don’t close your browser window until we have confirmed the reports are complete. But don’t worry if you do, as we will still generate the reports in the background, and your links will always be sent you your email in your receipt."
    ],
    [
        "Which vehicles are supported by ARC?",
        "We use a variety of data sources in order to give you the most comprehensive data package. These data sources are mostly targeted at consumer cars, which excludes other vehicles such as trucks and motorcycles. Our initial checks are processed using only data directly from the DVLA, therefore you can buy an ARC report for any vehicle registered with the DVLA in the UK, but we can only guarantee complete ARC reports for consumer cars. Additionally, it's highly likely that cars older than 30 years will be missing data.\n\nIf you are not happy with the data in your report, please get in touch at customersupport@autoregcheck.co.uk."
    ],
    [
        "How long will it take to receive my report?",
        "We will generate your report as soon as your payment details are confirmed. This should only take a few seconds in total, after which you will be provided a link to the reports you’ve purchased. During busy periods, it could take a couple of minutes to generate the report - let us know if you’re having difficulties."
    ],
    [
        "How long will I be able to access my report for?",
        "You will be able to access your report any time you wish for up to a full year after purchase. This is because the reports will not update as new history items become available. If you would like an updated report, you will need to purchase a new one with the same registration."
    ],
    [
        "Does the ARC report cover all road incidents and significant events?",
        "We use data from official sources. If an accident has occurred and the official database has a record of it, then it will appear in your report. This goes for all of the official databases that we use.\n\nUnfortunately, there are cases of improper conduct with regards to vehicle repair and resale which mean official databases are not notified of an event. For example, if a vehicle is written off and repaired without the insurance company being notified, then there will be no record in the official database and it will therefore not show up in an ARC report."
    ],
]

const FAQ_ISSUES = [
    [
        "I entered my registration number and found no results. What's next?",
        "If we don’t find any records, check you are entering the number correctly. Common issues include mixing up an ‘O’ with a ‘0’ (zero), or an ‘S’ with a ‘5’. Our initial checks use official DVLA services, so if no vehicle results were found when you entered the registration number, it means the DVLA doesn’t have records for it. \n\nIt could also be the case that the vehicle you have entered has a private registration plate that has not been registered with the DVLA, instead of the one it was originally registered with."
    ],
    [
        "What steps can I take if the details in my report are incorrect?",
        "Reach out to us using the chatbot in the bottom-right corner of the screen, or send us an email at customersupport@autoregcheck.co.uk and we will check the report on our end to try and resolve your issue.",
    ],
    [
        "Why does a different vehicle appear when I input my registration number?",
        "It may be the case that the vehicle has stolen plates, in which case it is advised to avoid the vehicle. If you do not think this is the case, get in touch and we can look into it for you."
    ],
    [
        "I’ve lost the links to my vehicle reports.",
        "Once you’ve paid for your reports, you will see the links to your reports in a confirmation page. You can’t return to this page, but we have sent the links to your email in your receipt, so check there. If you still cannot find the links then get in touch and let us know the email you used during purchase and the license plate, and we’ll share the link with you."
    ],
    [
        "My report is incomplete. How should I proceed?",
        "As explained in the terms and conditions, older vehicles will have less data will available for us to populate your report. In particular, if your vehicle was made before the year 2000, it’s likely your report will be missing key data.  If you  are unhappy with your report, get in touch with our customer service team at customersupport@autoregcheck.co.uk or send us a message in the chatbot on the page and we will look into the issue as soon as we can."
    ],
    [
        "I haven't received the report link in my email. What should I do?",
        "Double-check your main inbox and check the spam folder to see if the report has landed there. If the payment process completed, check your bank account to ensure the payment was successful.\n\nIf you are still encountering issues, send an email to customersupport@autoregcheck.co.uk."
    ],
    [
        "Why doesn't the mileage for the vehicle in my ARC report match the vehicle's odometer reading?",
        "The mileage readings in our reports are taken from the last recorded figure we have in the official data source, which is often an MOT test. They are not updated in real time, therefore there may be a discrepancy."
    ],
    [
        "The report didn't load after I paid. How can I get help?",
        "If your report didn’t load upon completing the payment process, there may have been an interruption to your network connection. In this case, check your bank account to see if the payment was completed, and try reloading the page. If an error occurs after we have taken payment and you cannot access the confirmation page, don’t worry, it’s very likely we have still generated your reports and have sent the links to your email.\n\nIf you are still encountering an issue, get in touch via our chatbot or at customersupport@autoregcheck.co.uk."
    ],
    [
        "I purchased a report with an incorrect email. How can I correct it?",
        "Let us know via our chatbot or send us an email at customersupport@autoregcheck.co.uk and we will send your report links to the correct email."
    ]
]

const FAQ_BILLING = [
    [
        "What payment methods can I use?",
        "We accept card payments from Visa, Mastercard and American Express."
    ],
    [
        "Do you provide receipts?",
        "Yes, we provide receipts via email as soon as the payment is confirmed."
    ],
    [
        "None of the report packages suit my business.",
        "Please get in touch via our chatbot or at customersupport@autoregcheck.co.uk and we’d be happy to discuss."
    ],
    [
        "How do I request a refund?",
        "Send us an email at customersupport@autoregcheck.co.uk stating that you’d like a refund. We will need to see important details such as the VRN, the email used during purchase, and the reason for the refund."
    ]
]


class Help extends Component {

    componentDidMount() {
        let f = new URLSearchParams(window.location.search).get('section')
        let e = document.getElementById(f)

        if (f && e) {
            if (window.innerWidth > 600) {
                e.scrollIntoView({ behavior: 'smooth' })
            } else {
                e.scrollIntoView()
            }
        }
    }


    render() {
        const breadcrumbs = [
            {name: "Home", item: "https://www.autoregcheck.co.uk/"},
            {name: "Help", item: "https://www.autoregcheck.co.uk/help/"}
        ]
        return (
            <HelmetProvider>

                <Helmet>
                    <title>Help | Auto Reg Check</title>
                    <meta name="description" content="Discover The Benefits Of A Used Vehicle Check From Auto Reg Check With Our FAQs And Help Section | Purchase A Car Check And Safeguard Your Investment Today" />
                    <link rel="canonical" href="https://www.autoregcheck.co.uk/help/" />
                </Helmet>

                <Breadcrumb breadcrumbs={breadcrumbs} />
                
                <main id="main_help">
                    <Header active_nav={'about'} show_banner={true} />

                    <h1>Help</h1>

                    <h2 id='top_header'>Feedback</h2>
                    <section className="faq_list">
                        {FAQ_FEEDBACK.map((item, i) => (
                            <div key={i}>
                                <FAQ question={item[0]} dropdown={true}>
                                    <p>{item[1]}</p>
                                </FAQ>
                            </div>
                        ))}
                    </section>

                    <h2>About the reports</h2>
                    <section className="faq_list">
                        {FAQ_REPORTS.map((item, i) => (
                            <div key={i}>
                                <FAQ question={item[0]} dropdown={true}>
                                    <p>{item[1]}</p>
                                </FAQ>
                            </div>
                        ))}
                    </section>

                    <h2 id='troubleshooting'>Troubleshooting</h2>
                    <section className="faq_list">
                        {FAQ_ISSUES.map((item, i) => (
                            <div key={i}>
                                <FAQ question={item[0]} dropdown={true}>
                                    <p>{item[1]}</p>
                                </FAQ>
                            </div>
                        ))}
                    </section>

                    <h2>Billing</h2>
                    <section className="faq_list">
                        {FAQ_BILLING.map((item, i) => (
                            <div key={i}>
                                <FAQ question={item[0]} dropdown={true}>
                                    <p>{item[1]}</p>
                                </FAQ>
                            </div>
                        ))}
                    </section>
                    <div id='sep'></div>

                    <Footer show_input={true} />
                    <CookiePopup />
                </main>
            </HelmetProvider>

        )
    }
}

export default Help;
