import React, { Component } from "react";
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import HC_access from "highcharts/modules/accessibility";
import '../../styles/components/graphs/stacked_column.scss';

HC_access(Highcharts);
HC_more(Highcharts);

class StackedColumn extends Component {
    chartRef = React.createRef();

    render() {
        const { data, free_check } = this.props;

        let years = [];
        let pass = [];
        let prs = [];
        let fail = [];
        let max_count = 0;

        for (let i of data) {
            years.push(i.year);
            pass.push(i.first_time);
            prs.push(i.with_rect);
            fail.push(i.fail);
            max_count = Math.max(max_count, i.first_time + i.with_rect + i.fail);
        }

        let W = window.innerWidth
        let H = ''
        if(W >= 1240){
            H = '100%'
        }else if (W >= 905){
            H = '60%'
        }else if(W >= 600){
            H = '90%'
        }else{
            H = '100%'
        }

        const options = {
            chart: {
                type: 'column',
                height: H,
                marginLeft: 70,
                marginTop: 20,
                style: {
                    fontFamily: 'Nunito Sans, sans-serif'
                }
            },
            title: { text: null },
            credits: { enabled: false },
            legend: { align: 'center' },
            xAxis: {
                labels: {
                    style: { color: "#475467", }
                },
                categories: years
            },
            yAxis: {
                min: -0,
                max: max_count,
                allowDecimals: false,
                title: {
                    enabled: true,
                    text: 'Number of MOT Tests',
                    style: {
                        color: "#475467",
                        fontSize: 14,
                        fontWeight: 500
                    }
                },
                labels: {
                    style: { color: "#475467" },
                    formatter: function () {
                        // Format the y-axis label
                        let value = this.value;

                        // Define suffixes for formatting
                        const suffixes = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

                        // Loop through suffixes to find appropriate format
                        let suffixIndex = 0;
                        while (value >= 1000 && suffixIndex < suffixes.length - 1) {
                            value /= 1000;
                            suffixIndex++;
                        }

                        // Format the value without decimal if it's '.0'
                        const formattedValue = value % 1 === 0 ? value.toFixed(0) : value.toFixed(1);

                        // Format the value and add suffix
                        return formattedValue + suffixes[suffixIndex];
                    }
                },
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
                formatter: function () {
                    const seriesName = this.series.name;
                    const pointY = Highcharts.numberFormat(this.point.y, 0, '.', ',');
                    const stackTotal = Highcharts.numberFormat(this.point.stackTotal, 0, '.', ',');
            
                    return `<b>${this.x}</b><br/>${seriesName}: ${pointY}<br/>Total: ${stackTotal}`;
                }
            },
            plotOptions: {
                series: { groupPadding: 0.15 },
                column: {
                    stacking: 'normal',
                    dataLabels: { enabled: false },
                    borderRadius: 6,
                    borderWidth: 2,
                }
            },
            series: [
                { name: 'Passed first time', data: pass, color: '#7fcdbb' },
                { name: 'Passed after repairs', data: prs, color: '#1d91c0' },
                { name: 'Failed', data: fail, color: '#253494' }
            ]
        };

        return (
            <div className='stacked_column'>
                <div className={free_check ? "block" : ""}></div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={this.chartRef}
                />
            </div>
        );
    }
}

export default StackedColumn;
