import React, { Component } from "react";
import '../styles/components/status_dropdown.scss';
import Tooltip from "./Tooltip";
import { TT } from "../tooltips"

class StatusDropdown extends Component {

    componentDidMount() {
        if (this.props.default_expand) {
            this.handle_expand({currentTarget: null})
        }
    }

    handle_expand = (e) => {
        let elem
        if(e.currentTarget){
            elem = e.currentTarget.querySelectorAll('.img_expand')[0]
            let tgt = e.target

            if (tgt.classList.contains('title_cont') || tgt.classList.contains('tt_elem') || tgt.classList.contains('title_text')) {
                return
            }
        }else{
            elem = document.getElementById(`icon_${this.props.title}`)
        }
       

        if (elem.style.transform === 'rotate(45deg)') {
            elem.style.transform = 'none'
        } else {
            elem.style.transform = 'rotate(45deg)'
        }

        let parent = elem.parentNode.parentNode.parentNode
        parent.querySelectorAll('.item_details')[0].classList.toggle('hide')
    }

    render() {

        const { title, status_class, stataus_text, item_key, item_value } = this.props

        return (
            <div className={`check_item_status ${status_class}`}>
                <div className='item_header' onClick={this.handle_expand}>
                    <div className="title_cont">
                        <p className="title_text">{title}</p>
                        <Tooltip text={TT.his[title]} />
                    </div>

                    <div className='item_right' >
                        <div className='marker'>
                            <p>{stataus_text}</p>
                            <img className="marker_img" src={`./assets/icons/marker_${status_class}_v2.svg`} alt=''/>
                        </div>
                        <img id={`icon_${title}`} alt='Dropdown icon' className='img_expand' src='./assets/icons/expand.svg' />
                    </div>

                </div>

                <div className={'item_details hide'}>
                    <p className="default_key">{item_key}</p>
                    <p className='default_val'>{item_value}</p>
                </div>
            </div>
        );
    }
}
export default StatusDropdown;