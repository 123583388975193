import React, { Component } from "react";
import '../styles/components/plate_input.scss';
import axios from 'axios';
import '@material/web/button/filled-button.js';

class PlateInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        document.querySelectorAll(".start_btn").forEach((button) => {
            button.addEventListener("click", () => {
                this.handle_plate_input()
            });
        });
    }

    handle_plate_input = () => {
        let plate = document.getElementById(this.props.input_id).value
        plate = plate.replace(/\s+/g, '')
        plate = plate.replace(/[^a-zA-Z0-9]/g, '')
        plate = plate.toUpperCase()

        if (plate.length > 0 && plate.length < 10) {
            this.setState({ loading: true })

            setTimeout(async function () {

                try {
                    const response = await axios.get(`/api/get-free-check/${plate}`);
                    if (response.data.length === 0) {
                        window.location.href = '/invalid-plate'
                    } else {
                        window.location.href = `/free-check/${plate}`
                    }
                } catch (error) {
                    window.location.href = '/invalid-plate'
                }

            }, 1500);
        } else {
            this.setState({ loading: false })
        }
    }

    render() {
        return (
            <div className="plate_input_cont">
                <input className="plate_input_elem" id={this.props.input_id} placeholder={window.innerWidth >= 330 ? "Enter your reg number..." : "Enter your reg..."} maxLength="10" />

                {!this.state.loading &&
                    <md-filled-button class='start_btn'>Start Check</md-filled-button>
                }

                {this.state.loading &&
                    <div className="loading_btn_cont">
                        <p>Checking</p>
                        <div className="dot_cont">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
export default PlateInput;