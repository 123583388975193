import "../../styles/views/docs.scss";

import React, { Component } from 'react';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import CookiePopup from '../../components/CookiePopup'
import { Helmet, HelmetProvider } from 'react-helmet-async';

class Privacy extends Component {


    render() {
        return (
            <HelmetProvider>

                <Helmet>
                    <title>Privacy Policy | Auto Reg Check</title>
                    <link rel="canonical" href="https://www.autoregcheck.co.uk/privacy-policy/" />
                </Helmet>
                <main id="main_doc">
                    <Header />
                    <section>
                        <iframe title="Privacy Policy" className="privacy" src="https://docs.google.com/document/d/e/2PACX-1vSj2uxxy2wR_dsYheZsmSjVm64iLR1qPSnFqUnaX56r8j2xV2o4aIh6Z_-ovouV57SN3Sct690oJaYX/pub?embedded=true"></iframe>
                    </section>
                    <Footer />
                    <CookiePopup />
                </main>
            </HelmetProvider>

        )
    }
}

export default Privacy;
