import React, { Component } from "react";

import '../../styles/components/user_journey/confirmation.scss';
import '@material/web/button/filled-tonal-button';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import CookiePopup from '../../components/CookiePopup'
import { Helmet, HelmetProvider } from 'react-helmet-async';

class Confirmation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            reports: [],
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        let n = new URLSearchParams(window.location.search).get('count')
        let reports = []

        for (let i = 0; i < n; i++) {
            let make = new URLSearchParams(window.location.search).get(`make${i + 1}`)
            let year = new URLSearchParams(window.location.search).get(`year${i + 1}`)
            let plate = new URLSearchParams(window.location.search).get(`plate${i + 1}`)
            let id = new URLSearchParams(window.location.search).get(`id${i + 1}`)

            reports.push({ make: make, plate: plate, year: year, id: id })
        }

        this.setState({ reports: reports })
    }

    handleImageError = (event) => {
        event.target.onerror = null; // Prevent infinite loop if fallback image also fails
        event.target.src = './assets/logos/NO_LOGO.png';
    }

    format_data = (val) => {
        if (val && val !== 'NaNL') {
            return `${val}`;
        }
    }

    get_logo = (details) => {
        if (details.make) {
            return details.make.replace(" ", "").toLowerCase()
        } else {
            return 'ERROR'
        }
    }

    render() {

        return (
            <HelmetProvider>

                <Helmet>
                    <title>Car Check Reports | Auto Reg Check</title>
                </Helmet>

                <Header active_nav={''} show_banner={true} />
                <main id="main_confirmation">

                    <div id="confirmation_panel">

                        <section id="text">
                            <h1>Payment Complete</h1>
                            {window.innerWidth > 599 && <p>Thank you for your payment, the links to your ARC reports are now ready. Click on the ‘view report’ button to open each report. We’ve also sent the links to each report to your email.</p>}
                            {window.innerWidth <= 599 && <p>Thank you for your payment, the links to your ARC reports are now ready. Click on the ‘view' button to open each report. We’ve also sent the links to each report to your email.</p>}

                        </section>

                        <section id='report_cards'>
                            {this.state.reports.map((v, i) => (
                                <div key={i} className={"info_box"}>

                                    <div className='info_left'>
                                        <div className="info_image">
                                            <img src={`./assets/logos/${this.get_logo(v)}.png`} onError={this.handleImageError} alt="Manufacturer logo" />
                                        </div>

                                        <div className="info_vehicle">
                                            <p className="make">{v.make}</p>
                                            <p className="year">{v.year}</p>
                                        </div>

                                    </div>

                                    <div className='info_right'>
                                        <div className="info_plate">
                                            <p>{v.plate}</p>
                                        </div>

                                        <md-filled-tonal-button class='btn_view' target='_blank' href={`/report/${v.id}`}>{window.innerWidth > 599 ? 'View report' : 'View'}</md-filled-tonal-button>

                                    </div>
                                </div>
                            ))}
                        </section>
                    </div>
                    <Footer />
                    <CookiePopup />
                </main>
            </HelmetProvider>

        );
    }
}
export default Confirmation;