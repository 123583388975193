import React, { Component } from "react";
import '../../styles/components/cards/free_check_cta.scss';

class FreeCheckCTA extends Component {
    render() {
        return (
            <div className="free_check_cta">
                {this.props.img && <img className="cta_header" src={this.props.img} alt='' draggable='false' />}

                <h3>{this.props.title}</h3>
                <p className="text">{this.props.text}</p>

                <div className="cta_footer">
                    <p className="price">Unlock the <b>FULL</b> report for <u>only £4.99</u></p>
                    <md-filled-button href={`/buy?count=1&plate1=${this.props.plate}`} id='cta_btn'>Full Check</md-filled-button>
                </div>

            </div>
        );
    }
}
export default FreeCheckCTA;