import "../../styles/views/errors/404.scss";

import React, { Component } from 'react';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import CookiePopup from '../../components/CookiePopup'
import { Helmet, HelmetProvider } from 'react-helmet-async';

class NoReport extends Component {
    
    componentDidMount(){
        document.getElementById("root").style.height = `${window.screen.height}px`
        document.getElementById("main_404").style.height = `${window.screen.height}px`
    }

    render() {
        return (
            <HelmetProvider>

            <Helmet>
                <title>Report Not Found | Auto Reg Check</title>
                <link rel="canonical" href="https://www.autoregcheck.co.uk/invalid-report/" />
            </Helmet>
            <main id="main_404">
                <Header />
                <section>
                    <div id="content">
                        <h1>404 error</h1>
                        <h2>Report not found</h2>
                        <p className="p_content">Sorry, we couldn't find data for this report</p>
                        <p className="p_content">Check the link is correct - you should have recieved the links in an email after purchase.<br /><br /> If you're still having difficulties, please get in touch via the chatbot or email us at customersupport@autoregcheck.co.uk</p>

                        <button onClick={() => { window.location.href = "/" }}>Return home</button>
                    </div>

                    <img id="main_img" alt="A graphic showing the number 404" src="./assets/errors/404.svg" />
                </section>
                <Footer />
                <CookiePopup />
            </main>
            </HelmetProvider>

        )
    }
}

export default NoReport;
