import "../styles/views/about.scss";
import React from 'react';

import Header from '../components/Header'
import Footer from '../components/Footer'

import CookiePopup from '../components/CookiePopup'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Breadcrumb from "../components/Breadcrumb";

function About() {

    const breadcrumbs = [
        {name: "Home", item: "https://www.autoregcheck.co.uk/"},
        {name: "About", item: "https://www.autoregcheck.co.uk/about/"}
    ]

    return (
        <HelmetProvider>

            <Helmet>
                <title>About Us | Auto Reg Check</title>
                <meta name="description" content="Meet The Team Behind The UK's Best Value Car Check | Make The Right Investment With Auto Reg Check | Registration & History Checks Now 20% Off* Across The Site" />
                <link rel="canonical" href="https://www.autoregcheck.co.uk/about/" />
            </Helmet>
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <main id="main_about">
                <Header active_nav={'about'} show_banner={true}  />

                <section id='about_arc'>
                    <h1>About Us</h1>
                    <p>Founded in 2023 in Hertfordshire, Auto Reg Check is a dynamic new product with a vision to revolutionise the automotive services industry. Built on a foundation of passion, innovation, and commitment to excellence, our team is dedicated to providing accurate and reliable information to empower our customers. As we embark on this exciting journey, we have big plans for the future, with a focus on expanding our services, bringing new innovations to the market and enhancing customer experiences.</p>
                </section>

                <section id='bios'>
                    <div className="bio">
                        <img alt="Jack Baker's Avatar" src={window.innerWidth >= 600 ? './assets/about/JB_round.svg' : './assets/about/JB.svg'} />
                        <div className="bio_box">
                            <h2>Jack Baker</h2>
                            <p className="details">With years of experience in web and graphic design, Jack leads the operations of the business and all design related activities.</p>
                            <p className="title">CEO</p>
                        </div>
                    </div>

                    <div className="bio">
                    <img alt="Charlie Bignell's Avatar" src={window.innerWidth >= 600 ? './assets/about/CB_round.svg' : './assets/about/CB.svg'} />
                        <div className="bio_box">
                            <h2>Charlie Bignell</h2>
                            <p className="details">As an experienced data scientist and software developer, Charlie leads the technical development of the business.</p>
                            <p className="title">CTO</p>
                        </div>
                    </div>
                </section>
               
                <Footer show_input={true} />
                <CookiePopup />
            </main>
        </HelmetProvider>

    );
}

export default About;
