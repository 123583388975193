import "../../styles/views/errors/500.scss";

import React, { Component } from 'react';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import CookiePopup from '../../components/CookiePopup'
import { Helmet, HelmetProvider } from 'react-helmet-async';

class ReportError extends Component {
    
    componentDidMount(){
        document.getElementById("root").style.height = `${window.screen.height + 45}px`
        document.getElementById("main_500").style.height = `${window.screen.height + 45}px`
    }

    render() {
        return (
            <HelmetProvider>

            <Helmet>
                <title>Report Error | Auto Reg Check</title>
                <link rel="canonical" href="https://www.autoregcheck.co.uk/report-error/" />
            </Helmet>
            <main id="main_500">
                <Header />
                <section>
                    <div>
                        <h1>500 error</h1>
                        <h2>Something went wrong</h2>
                        <p>Sorry, we ran into an issue while generating your report. <br /><br />Please check your email inbox for your receipt. If access to your report is not available or the links aren't working, please get in touch via the chatbot or email us at customersupport@autoregcheck.co.uk</p>
                        <button onClick={() => { window.location.href = "/" }}>Return home</button>
                    </div>

                    <img alt="A graphic showing the number 500" src="./assets/errors/500.svg" />
                </section>
                <Footer />
                <CookiePopup />
            </main>
            </HelmetProvider>

        )
    }
}

export default ReportError;
