import React, { Component } from "react";

import '../../styles/components/article.scss';

const HEADINGS = ["What-to-Look-For", "The-Top-10", "Conclusion"]

let positions_set = false

class Top10 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scroll_perc: 0,
            active_h: "",
            positions: {
                "What-to-Look-For": 0,
                "The-Top-10": 0,
                "Conclusion": 0,
            }
        }
    }

    componentDidMount() {
        if (window.innerWidth > 904) {
            this.set_positions(null)
        }
    }

    set_positions(section) {
        let pos_obj = {}

        for (let h of HEADINGS) {
            let elem = document.querySelectorAll(`a[href="#${h}"]`)[0]
            pos_obj[h] = elem.offsetTop
        }

        this.setState({ positions: pos_obj })

        if (section) {
            window.scrollTo(0, pos_obj[section.replaceAll(" ", "-")] - (window.innerHeight / 2) + 2)
        }
    }


    handle_scroll = () => {
        if (!positions_set) {
            this.set_positions(null)
        }

        positions_set = true

        const scroll_location = window.scrollY;
        const total_height = document.documentElement.scrollHeight - window.innerHeight;

        let change_point = window.innerWidth > 1239 ? 765 : 701

        if (scroll_location >= change_point && !document.getElementById('scroll_point_cont').classList.contains('float')) {
            document.getElementById('scroll_point_cont').classList.toggle('float')
        } else if (scroll_location < change_point && document.getElementById('scroll_point_cont').classList.contains('float')) {
            document.getElementById('scroll_point_cont').classList.toggle('float')
        }

        const percentage = (scroll_location / total_height) * 100;

        let visited = []
        let not_visited = []
        const POS = this.state.positions

        for (let h in POS) {
            if (POS[h] - (window.innerHeight / 2) < scroll_location) {
                visited.push(h)
            } else {
                not_visited.push(h)
            }
        }

        let active = visited.pop()
        active = active ? active : HEADINGS[0]

        this.update_scroll_points(visited, not_visited, active)

        this.setState({
            scroll_perc: percentage,
            active_h: active
        });
    };

    update_scroll_points = (visited, not_visited, active) => {
        const POS = this.state.positions
        const KEYS = Object.keys(POS)
        const NEXT = KEYS.indexOf(active) + 1

        const BASE = POS[active] - (window.innerHeight / 2)
        const TARGET = POS[KEYS[NEXT]] - (window.innerHeight / 2)
        const PROG = Math.max(((window.scrollY - BASE) * 100 / (TARGET - BASE)), 0)

        const ACTIVE_POINT = document.querySelectorAll(`[data-key="${active}"]`)[0]
        ACTIVE_POINT.classList.add('active')
        ACTIVE_POINT.querySelector('.inner_bar_progress').style.height = `${PROG}%`


        for (let h of visited) {
            let elem = document.querySelectorAll(`[data-key="${h}"]`)[0]
            elem.classList.remove('active')
            elem.classList.add('visited')
            elem.querySelector('.inner_bar_progress').style.height = '100%'
        }

        for (let h of not_visited) {
            let elem = document.querySelectorAll(`[data-key="${h}"]`)[0]
            elem.classList.remove('active')
            elem.classList.remove('visited')
            elem.querySelector('.inner_bar_progress').style.height = '0'
        }
    }

    handle_scroll_click = (e) => {
        let parent_class = e.target.parentNode.classList
        let this_class = e.target.classList
        let target_div = null

        if (this_class[0] === 'scroll_item') {
            target_div = e.target
        } else if (parent_class[0] === 'scroll_item') {
            target_div = e.target.parentNode
        } else if (this_class[0] !== "inner_bar" && this_class[0] !== "inner_bar_progress") {
            target_div = e.target.parentNode.parentNode
        }

        if (target_div) {
            this.set_positions(target_div.querySelectorAll('p')[0].innerHTML)
        }
    }

    render() {

        if (window.innerWidth > 904) {
            window.addEventListener('scroll', this.handle_scroll)
        }

        const ARTICLE = this.props.article
        return (
            <section className="article">

                <div className="article_opener">
                    <div className="opener_inner">

                        <div className="left">
                            <h1 className="article_h1">{ARTICLE.title}</h1>
                            <p className="article_subtitle">{ARTICLE.subtitle}</p>
                        </div>

                        <img alt={ARTICLE.img_alt} src={ARTICLE.img_src} />
                    </div>
                </div>

                <div className="article_body">
                    <div className="info">
                        <div className="metadata">
                            <p className="meta_author">{ARTICLE.author}</p>
                            <p>{ARTICLE.date}</p>
                            <p>{ARTICLE.read} read</p>
                        </div>
                        <div className="socials">
                            <a href="https://www.instagram.com/xegon_automotive" target="_blank" rel="noreferrer"><img alt="Instagram logo" src="./assets/icons/insta_square.svg" /></a>
                            <a href="https://www.facebook.com/profile.php?id=61550323427271" target="_blank" rel="noreferrer"><img alt="Facebook logo" src="./assets/icons/fb_square.svg" /></a>
                            <a href="https://www.linkedin.com/company/98098382/" target="_blank" rel="noreferrer"><img alt="LinkedIn Logo" src="./assets/icons/linkedin_square.svg" /></a>
                        </div>
                    </div>

                    <div className="content">

                        <p className="intro">If you're thinking of purchasing a second-hand vehicle, investing in a car check is certainly worthwhile. The last thing you want is to end up with a problematic car because you didn’t thoroughly check it. Even if you don’t get all the necessary information from the seller (and this is often the case), you can easily find it with a quick search online - there are many car check providers available to choose from. But how do you know which provider is the best? Fear not! We’ve tested 10 providers currently on the market to find out.</p>

                        <h2><a href="#What-to-Look-For">What Makes a Vehicle Check Provider a Good Choice?</a></h2>

                        <p>Whilst the breadth of information a car check offers is important, equally as much is the design and style of the website. Is the information presented clear and easy to understand? Can you navigate through the information quickly and find what you need? Also important is the depth of check that the provider offers, and at what price. Some companies will offer a free check, but very often this is limited to the most basic information. For a fee, a provider will give you a full check that often includes things like stolen checks, scrap records and any recorded damages or write offs.

                            <br /><br /> Always remember that when buying a used car, especially from a private seller, it's crucial to perform your own checks on the vehicle to ensure there are no hidden issues. Check out our <a className='outbound' target="_blank" href='/blog/used-car-inspection-checklist'>Used Car Inspection Checklist</a> for all the information you need when going to inspect a car.</p>

                        <img className="img_full" src="./assets/blog/inspection/inspector.webp" alt="A man in the back of a car taking notes" />

                        <h2><a href="#The-Top-10">The Top 10</a></h2>

                        <ol>
                            <li className="emphasise"><b><a className='outbound' target="_blank" href='/pricing'>Auto Reg Check</a></b>: If you want a comprehensive, cost-effective check, look no further than Auto Reg Check. With a 12-point history check and loads of information about any car, this site is easy to use and has the most visually appealing design. Features like the eco score, which is quite addictive to explore with different cars to discover their environmental impact, as well as the cost of ownership calculator are interesting and informative. At £4.99 this is the cheapest site on test, and they provide a basic check for free.</li>
                            <img className="img_full" src="./assets/blog/top10/arc.webp" alt="A person looking at an ARC report." />
                            <li><b>Instant Car Check</b>: Next up is Instant Car Check, another excellent option. The website is user-friendly, and there is plenty of information on offer. Simply enter the correct vehicle registration, and the site will run a check for you. However, they do not offer any free checks.</li>
                            <li><b>Check Reg</b>: Check Reg is the third choice on our list, offering an interesting package for motorists. In the free report, you can expect to find MOT history, basic vehicle information, tax details, and the V5C logbook date.</li>
                            <li><b>Full Car Checks</b>: Full Car Checks is another reliable source of information, helping you make informed decisions when purchasing a second-hand vehicle.</li>
                            <li><b>Car Reg Checks</b>: Car Reg Checks is just as easy to use as the other options on this list. It provides all the basic history checks, including MOT history and write-off claims.</li>
                            <li><b>Motorcheck</b>: Motorcheck can also help ensure you make the right vehicle choice. By providing all the necessary details about any given vehicle, you can make informed decisions with up-to-date information.</li>
                            <li><b>Total Car Check</b>: Total Car Check is a highly rated website where you can get all the information you need with a simple click.</li>
                            <li><b>Rapid Car Check</b>: While most car check websites are fast, Rapid Car Check is quicker than most. It provides the information you need in seconds, including ownership details, to ensure you are not buying a stolen vehicle.</li>
                            <li><b>Car Tax Check</b>: If you want to focus more specifically on car tax, Car Tax Check is the best choice. Although it doesn’t offer a fully comprehensive check like some others, it still provides key information.</li>
                            <li><b>Gov.uk</b>: Lastly, the DVLA offer free information on <a className='outbound' target="_blank" href='https://www.gov.uk/get-vehicle-information-from-dvla'>their site</a>.</li>
                        </ol>

                        <h2><a href="#Conclusion">Conclusion</a></h2>
                        <p>We hope you found this article helpful and that you consider using one of these sites if you purchase a used car. It's always better to be safe than sorry, even if it means delaying your purchase a bit to research the vehicle thoroughly. We wish you the best of luck in your car search and hope you find a car that you love.</p>
                        <img className="img_full" src="./assets/blog/inspection/mechanic.webp" alt="" />

                    </div>

                    <div className="nav">
                        <div id="scroll_point_cont" style={{ height: `${HEADINGS.length * 75}px` }}>
                            {HEADINGS.map((h, i) => (
                                <div onClick={this.handle_scroll_click} key={i} data-key={h} className='scroll_item'>
                                    <p>{h.replaceAll("-", " ")}</p>
                                    <div className="scroll_icon">
                                        <div className='inner_circle'></div>
                                        <div className='inner_bar'>
                                            <div className='inner_bar_progress'></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </section>

        );
    }
}
export default Top10;