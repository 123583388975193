import React, { Component } from "react";
import { PRICING, stripePromise } from "../../utils";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../CheckoutForm";
import ExitIntent from '../ExitIntent_v2'
import '../../styles/components/user_journey/confirm_and_pay.scss';
import '@material/web/button/outlined-button.js';
import Review from "../cards/Review";

let loaded = false

class ConfirmAndPay extends Component {

    constructor(props) {
        super(props)
        this.state = {
            spacer_width: 0,
            clientSecret: null,
            valid_plates: [],
            updated: false,
            alert_fired: false,
            discount: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        document.getElementById(`btn_back`).addEventListener('click', () => { window.history.back() })
        window.addEventListener('click', function (e) {
            if (e.target.tagName !== 'IMG') {
                document.querySelectorAll('.menu_items').forEach((list) => {
                    list.classList.add('hide')
                })
            }
        })
    }

    componentDidUpdate = () => {

        if (this.state.clientSecret && this.props.dvla_results) {
            loaded = true
        }

        if (this.props.dvla_results && !loaded) {
            let plates = this.update_valid_plates()
            if(plates.length > 0){
                this.create_intent(plates)
            }
        }
    }

    update_valid_plates = () => {
        let valid_plates = []
        for (let plate of this.props.dvla_results) {
            if (Object.values(plate).length > 1) {
                valid_plates.push(plate['plate'])
            }
        }
        return valid_plates
    }

    create_intent = (plates) => {
        try {
            fetch("/api/create-payment-intent", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ plates: plates }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.clientSecret) {
                        this.setState({ valid_plates: plates, clientSecret: data.clientSecret })
                    }
                });
        } catch (error) {
            window.location.href = '/server-error'
        }
    }

    handle_trash = (idx) => {

        let new_v = this.props.dvla_results
        new_v.splice(idx, 1)

        let url = `/buy?count=${new_v.length}`
        for (let i = 0; i < new_v.length; i++) {
            url += `&plate${i + 1}=${new_v[i]['plate']}`
        }

        window.location.href = url
    }

    handleImageError = (event) => {
        event.target.onerror = null; // Prevent infinite loop if fallback image also fails
        event.target.src = './assets/logos/NO_LOGO.png';
    }

    handle_discount_apply = (resp_obj) => {
        this.setState({ discount: resp_obj, updated: true })
    }

    toggle_menu = (e) => {
        document.querySelectorAll('.menu_items').forEach((list) => {
            if (list !== e.target.nextElementSibling) {
                list.classList.add('hide')
            }
        })

        if (e.target.nextElementSibling) {
            e.target.nextElementSibling.classList.toggle('hide')
        }
    }

    get_logo = (details) => {
        if (details.make) {
            return details.make.replace(" ", "").toLowerCase()
        } else {
            return 'ERROR'
        }
    }

    render() {

        const { clientSecret } = this.state;
        const appearance = { theme: "stripe", }
        const options = { clientSecret, appearance, }

        let none_selected = [{
            price: "£0.00",
            product: 'No reports selected',
            discount: null,
            total: "£0.00",
            numeric: 0,
        }]

        let product_list = none_selected.concat(PRICING)

        let product = this.state.valid_plates ? product_list[this.state.valid_plates.length] : []

        let contains_old_car = false

        for (let v of this.props.dvla_results) {
            if (v.year) {
                contains_old_car = contains_old_car || v.year <= new Date().getFullYear() - 30
            }
        }

        return (
            <div id="confirm_and_pay_panel">
                {/* <ExitIntent /> */}
                <h1>Payment</h1>

                <div id='content_cont'>
                    <div id="plate_check">
                        {this.props.dvla_results.map((v, i) => (
                            <div key={i} className={Object.keys(v).length === 1 ? 'info_box error' : "info_box"}>

                                <div className='info_left'>
                                    <div className="info_left_cont">
                                        <div className="info_image">
                                            <img src={`./assets/logos/${this.get_logo(v)}.png`} onError={this.handleImageError} alt="Manufacturer logo" />
                                        </div>

                                        {Object.keys(v).length > 1 &&
                                            <div className="info_vehicle">
                                                <p className="make">{v.make}</p>
                                                <p className="year">{v.year}</p>
                                            </div>
                                        }

                                        {Object.keys(v).length === 1 &&
                                            <div className="info_vehicle">
                                                <p className='make'>Error</p>
                                                <p className='year'>No data available for this vehicle</p>
                                            </div>
                                        }
                                    </div>

                                    {window.innerWidth <= 599 && <div className="info_plate">
                                        <p>{v.plate}</p>
                                    </div>}
                                </div>


                                <div className='info_right'>
                                    {window.innerWidth > 599 && <div className="info_plate">
                                        <p>{v.plate}</p>
                                    </div>}

                                    <div className="info_menu" onClick={this.toggle_menu}>
                                        <img src={Object.keys(v).length === 1 ? './assets/icons/dots_dark.svg' : './assets/icons/dots_white.svg'} draggable='false' alt='Three dots, indicating a menu' />

                                        <div className="menu_items hide">
                                            <div className="item" onClick={() => { this.handle_trash(i) }}><p>Remove plate</p></div>
                                            <a target='_blank' rel="noreferrer" href='/help?section=troubleshooting'><div className="item"><p>Report incorrect details</p></div></a>
                                            {Object.keys(v).length > 1 && <a target='_blank' rel="noreferrer" href={`/free-check/${v.plate}`}><div className="item"><p>View free check</p></div></a>}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        ))}

                        <Review free_check={true} class_name='not_report' avatar='0' text='This was a great a find! So much helpful information across many different areas'/>

                        <div id="btn_cont">
                            <md-outlined-button id='btn_back'>Back</md-outlined-button>
                            <md-outlined-button id='btn_sample' target='_blank' href='/report/1234'>View sample report</md-outlined-button>
                        </div>

                    </div>

                    <div id='payment_info'>
                        {contains_old_car &&
                            <div className='alert'>
                                <img alt="" src='./assets/icons/marker_warn_v2.svg' />
                                <div id='alert_text'>
                                    <h3>Older vehicle detected</h3>
                                    <p>The vehicle you have entered is more than 30 years old. Our data package covers cars up to the age of 30, therefore it is likely that there will be missing data in your report.</p>
                                </div>
                            </div>
                        }

                        <div id='package_info'>
                            <div className="package_row" id='product'>
                                <p>{product.product}</p>
                                <p>{product.price}</p>
                            </div>

                            {product.discount &&
                                <div className="package_row" id='discount'>
                                    <p>{product.discount_perc}</p>
                                    <p>-{product.discount}</p>
                                </div>
                            }

                            {this.state.discount &&
                                <div className="package_row" id='discount'>
                                    <p>{this.state.discount.msg}</p>
                                    <p>-£{(product.numeric * this.state.discount.discount).toFixed(2)}</p>
                                </div>
                            }

                            <div className='sep'></div>
                            <div className="package_row" id='total'>
                                <p>Total</p>
                                <p>£{this.state.discount ? (product.numeric - product.numeric * this.state.discount.discount).toFixed(2) : product.numeric}</p>
                            </div>
                        </div>

                        <div id='stripe_cont'>
                            {clientSecret && (
                                <Elements options={options} stripe={stripePromise}>
                                    <CheckoutForm
                                        client_secret={this.state.clientSecret}
                                        plates={this.state.valid_plates}
                                        handle_confirm={this.props.handle_confirm}
                                        updated={this.state.updated}
                                        handle_discount_apply={this.handle_discount_apply}
                                        discount={this.state.discount}
                                        active_code={this.state.discount && this.state.discount.valid ? this.state.discount.msg : 'N/A'}
                                    />
                                </Elements>
                            )}
                        </div>
                    </div>


                </div>


            </div >
        );
    }

}
export default ConfirmAndPay;