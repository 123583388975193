import "../../styles/views/errors/404.scss";

import React, { Component } from 'react';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import CookiePopup from '../../components/CookiePopup'
import { Helmet, HelmetProvider } from 'react-helmet-async';

class NewRegistration extends Component {
    
    componentDidMount(){
        document.getElementById("root").style.height = `${window.screen.height}px`
        document.getElementById("main_404").style.height = `${window.screen.height}px`
    }

    render() {
        return (
            <HelmetProvider>

            <Helmet>
                <title>New Registration | Auto Reg Check</title>
                <link rel="canonical" href="https://www.autoregcheck.co.uk/new-registration/" />
            </Helmet>
            <main id="main_404">
                <Header />
                <section>
                    <div id="content">
                        <h1 className="red">Error</h1>
                        <h2>No data</h2>
                        <p className="p_content">As this vehicle is newly registered, there is no DVLA data available yet. <br />Check back in a month's time to purchase your ARC check.</p>

                        <button onClick={() => { window.location.href = "/" }}>Return home</button>

                    </div>

                    <img id="main_img" alt="A car with a red cross over it" src="./assets/errors/no_plate.png" />
                </section>
                <Footer />
                <CookiePopup />
            </main>
            </HelmetProvider>

        )
    }
}

export default NewRegistration;
