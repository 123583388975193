import React, { Component } from "react";
import '../../styles/components/cards/blog_card.scss';
import '@material/web/button/filled-button.js';

class BlogCard extends Component {

    render() {
        const AR = this.props.article
        const url = AR.title.replaceAll(" ", "-").replaceAll("'", "").replaceAll("?", "").toLowerCase()

        return (
            <div className={AR.live ? "blog_card live" : "blog_card"} >
                {AR.img_src && <img className="title_img" alt={AR.img_alt} src={AR.img_src} />}
                {!AR.img_src && <div className="title_img"><p>Coming Soon</p></div>}

                <div className="content">
                    <div className="card_text">
                        <h2>{AR.title}</h2>
                        <p className="date">{AR.date}</p>
                        <p className="subtitle">{AR.subtitle}</p>
                    </div>


                    <div className="card_footer">
                        <div className="marker">
                            <p>#{AR.marker}</p>
                        </div>
                        {!AR.live && <md-filled-button disabled={true} style={{ fontFamily: "'Nunito Sans', sans-serif" }}>Read</md-filled-button>}
                        {AR.live && <md-filled-button style={{ fontFamily: "'Nunito Sans', sans-serif" }} href={`/blog/${url}`}>Read</md-filled-button>}
                    </div>
                </div>

            </div>

        );
    }
}
export default BlogCard;