import React, { Component } from "react";
import '../styles/components/no_data.scss';

class NoData extends Component {
    render() {
        return (
            <div className="no_data">
                <img alt='' draggable='' src='./assets/icons/nodata.svg' />
                <p>Sorry, there is no data available for this section</p>
            </div>
        );
    }
}
export default NoData;