import React, { Component } from "react";
import '../styles/components/tooltip.scss';

class Tooltip extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show_tt: false,
            mouse_x: -100,
            mouse_y: -100,
            most_recent: "",
            using_touch: false
        }
    }

    on_mouse_enter = (event) => {
        if (!this.state.using_touch) {

            this.setState({
                show_tt: true,
                most_recent: "enter"
            });
        }
    }

    on_mouse_leave = (event) => {
        if (!this.state.using_touch) {
            this.setState({
                show_tt: false,
                most_recent: "leave"
            });
        }

    }

    on_click_text = (event) => {
        this.setState({
            show_tt: false,
            most_recent: "leave"
        });
    }


    on_mouse_move = (event) => {
        this.setState({
            mouse_x: event.pageX + 20,
            mouse_y: event.pageY - 3,
        });
    };

    on_touch_start = (event) => {
        if (!this.state.using_touch) {
            this.setState({ using_touch: true })
        }
        this.setState({
            show_tt: true,
            most_recent: "enter"
        });
    }


    render() {
        const { show_tt, mouse_x, mouse_y } = this.state;
        const TT_STYLE_MOB = {
            top: mouse_y + 20,
            left: 0,
            margin: '0 10px'
        }

        const TT_STYLE_WIDE = {
            top: mouse_y,
            left: mouse_x,
            maxWidth: '30vw'
        }

        let text = this.props.text
        if (text) {
            if (text.substring(text.length - 1) !== '.') {
                text = `${text}.`
            }
        }

        let src = this.props.green ? './assets/icons/tooltip_green.svg' : './assets/icons/tooltip.svg'
        src = this.props.dark ? './assets/icons/tooltip_dark.svg' : src

        return (
            <div className="tooltip tt_elem" >
                <img
                    onMouseEnter={this.on_mouse_enter}
                    onMouseLeave={this.on_mouse_leave}
                    onMouseMove={this.on_mouse_move}
                    onTouchStart={this.on_touch_start}
                    onClick={this.on_click}
                    onTouchEnd={this.on_touch_end}
                    className="tt_icon tt_elem" 
                    src={src}
                    alt="Tooltip Icon" />

                {show_tt && (
                    <div onClick={this.on_click_text} className="tt_text tt_elem" style={window.innerWidth > 750 ? TT_STYLE_WIDE : TT_STYLE_MOB}>
                        <p className="tt_text_p tt_elem">{text}</p>
                        {this.state.using_touch && <div className="close_btn tt_elem"><p className="tt_close tt_elem">Close</p></div>}
                    </div>
                )}
            </div>
        );
    }
}

export default Tooltip;
