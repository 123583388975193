import '../../styles/components/report_panels/certification.scss'
import { get_relevant_data, move_items } from "../../utils"
import React, { Component } from "react"

import ReportFrame from "../ReportFrame"
import StackedColumn from "../graphs/StackedColumn"
import LineChartCert from "../graphs/LineChartCert"

import { capitalize } from '@mui/material'
import MOTRow from '../MOTRow'
import FreeCheckCTA from '../cards/FreeCheckCTA'
import StatusDropdown from '../StatusDropdown'
import Review from '../cards/Review'
import ListAd from '../cards/ListAd'

let loaded = false

class Certification extends Component {

    constructor(props) {
        super(props)
        this.state = {
            car_img: "",
            current_col: 0,
            history: {
                n_tests: 0,
                mot_perc: 0,
                n_passes: 0,
                n_fails: 0,
                n_failure: 0,
                n_advise: 0,
                mot_table: [],
            },
            mileage: {
                mileage_years: [],
                mileage_values: [],
                mileage_sources: [],
                mileage_fail: false
            },
            statuses: {
                mot_status: 'warn',
                mot_expiry: 'Unknown',
                tax_status: 'warn',
                tax_expiry: 'Unknown'
            },
            mot: {
                mot_agg_name: "",
                mot_items: [],
                mot_insights_figures: {},
                insights_data: [],
            }
        }
    }

    componentDidUpdate() {
        if (!loaded & Object.values(this.props.data).length > 0) {
            this.set_status(this.props.data)
            if (this.props.free_check) {
                this.set_free_check(this.props.data)
            }
        }
        this.organise_columns()
        window.addEventListener('resize', this.organise_columns)
    }

    componentDidMount() {
        if (Object.values(this.props.data).length > 0) {
            this.set_status(this.props.data)
            if (this.props.free_check) {
                this.set_free_check(this.props.data)
            }
        }
        loaded = false

    }

    organise_columns = () => {
        const CURR = this.state.current_col

        const ITEMS = ["1-1-1", "1-1-2", "1-1-3", "1-1-4", "1-1-5", "1-1-6", "1-1-7", "1-1-8", "1-1-9",
            "2-1-1", "2-1-2", "2-1-3", "2-1-4", "2-1-5", "2-1-6", "2-2-1", "2-2-2", "2-2-3",
            "3-1-1", "3-1-2", "3-1-3", "3-1-4", "3-1-5", "3-2-1", "3-2-2", "3-3-1", "3-3-2"]

        if (window.innerWidth < 600 && CURR !== 1) {
            move_items(1, ITEMS)
            this.setState({ current_col: 1 })

        } else if (window.innerWidth >= 600 && window.innerWidth < 1440 && CURR !== 2) {
            move_items(2, ITEMS)
            this.setState({ current_col: 2 })

        } else if (window.innerWidth >= 1440 && CURR !== 3) {
            move_items(3, ITEMS)
            this.setState({ current_col: 3 })
        }
    }

    set_free_check = (data) => {
        this.setState({
            statuses: {
                mot_status: data.free_check_results.mot_valid,
                mot_expiry: data.free_check_results.mot_date,
                tax_status: data.free_check_results.tax_valid,
                tax_expiry: data.free_check_results.tax_date,
            }
        })
    }

    set_status = (data) => {

        try {
            // Get the relevant metadata
            let metadata = get_relevant_data(data.api_metadata, 'C')

            let filtered_data = {}
            for (let m of metadata) {
                m['value'] = data.api_response[m['item_name']]
                filtered_data[m['item_name']] = m
            }

            // MOT list
            filtered_data['mot_test_list'].value.reverse()
            const N_TESTS = filtered_data['mot_test_list'].value.length
            let mot_list_formatted = []
            let n_passes = 0
            let n_failure = 0
            let n_advisories = 0
            let count = N_TESTS
            let tot_fails = 0
            for (let mot_test of filtered_data['mot_test_list'].value) {
                mot_list_formatted.push({
                    count: count,
                    date: mot_test['TestDate'] ? mot_test['TestDate'] : "",
                    no: mot_test['TestNumber'] ? mot_test['TestNumber'] : "",
                    result: mot_test['TestResult'] === 'Fail' || mot_test['FailureReasonList'].length > 1 ? 'FAIL' : 'PASS',
                    mileage: mot_test['OdometerInMiles'] ? mot_test['OdometerInMiles'] : "",
                    expiry: mot_test['ExpiryDate'] ? mot_test['ExpiryDate'] : "",
                    advisory_list: mot_test['AdvisoryNoticeList'] ? mot_test['AdvisoryNoticeList'] : [],
                    failure_list: mot_test['FailureReasonList'] ? mot_test['FailureReasonList'] : []
                })

                tot_fails += mot_test['TestResult'] === 'Fail' ? 1 : 0

                n_passes += mot_test['TestResult'] === 'Fail' || mot_test['FailureReasonList'].length > 1 ? 0 : 1
                n_failure += mot_test['FailureReasonList'] ? mot_test['FailureReasonList'].length : 0
                n_advisories += mot_test['AdvisoryNoticeList'] ? mot_test['AdvisoryNoticeList'].length : 0

                count--
            }

            if (this.props.free_check) {
                if (mot_list_formatted.length > 1) {
                    for (let i = 1; i < mot_list_formatted.length; i++) {
                        mot_list_formatted[i].date = '00/00/00'
                        mot_list_formatted[i].mileage = '00000'
                        mot_list_formatted[i].expiry = '00/00/00'
                        mot_list_formatted[i].no = `${i}0000000000`

                        let new_ad = []
                        let new_fail = []

                        for (let advisory of mot_list_formatted[i].advisory_list) {
                            new_ad.push("Buy a full check to view these details")
                        }

                        for (let failure of mot_list_formatted[i].failure_list) {
                            new_fail.push("Buy a full check to view these details")
                        }

                        mot_list_formatted[i].advisory_list = new_ad
                        mot_list_formatted[i].failure_list = new_fail
                    }
                }
            }

            const HISTORY_OBJ = {
                n_tests: N_TESTS,
                n_passes: n_passes,
                n_fails: tot_fails,
                mot_perc: N_TESTS > 0 ? parseInt(n_passes * 100 / N_TESTS) : 100,
                n_failure: n_failure,
                total_failures: tot_fails,
                n_advise: n_advisories,
                mot_table: mot_list_formatted,
            }

            // MILEAGE LIST
            let mile_list_formatted = {
                mileage_years: [],
                mileage_values: [],
                mileage_sources: [],
            }

            for (let record of filtered_data['mileage_list'].value) {

                if (record['Mileage'] && record['DateOfInformation'] && record['DateOfInformation'].length > 6) {
                    mile_list_formatted['mileage_values'].push(record['Mileage'])
                    mile_list_formatted['mileage_years'].push(record['DateOfInformation'].substring(6))

                    if (record['SourceOfInformation']) {
                        mile_list_formatted['mileage_sources'].push(record['SourceOfInformation'])
                    } else {
                        mile_list_formatted['mileage_sources'].push("Unknown Source")
                    }
                }
            }

            mile_list_formatted['mileage_years'].reverse()
            mile_list_formatted['mileage_values'].reverse()
            mile_list_formatted['mileage_sources'].reverse()

            mile_list_formatted['mileage_fail'] = filtered_data['mileage_anomaly'].value

            const format_data = (date) => { return `${date.substring(8)}/${date.substring(5, 7)}/${date.substring(2, 4)}` }

            const STATUSES_OBJ = {
                mot_status: data.api_response['DVLA_motStatus'] === 'Valid' ? 'pass' : 'fail',
                tax_status: data.api_response['DVLA_taxStatus'] === 'Taxed' ? 'pass' : 'fail',
                mot_expiry: data.api_response['DVLA_motExpiryDate'] ? format_data(data.api_response['DVLA_motExpiryDate']) : "Unknown",
                tax_expiry: data.api_response['DVLA_taxDueDate'] ? format_data(data.api_response['DVLA_taxDueDate']) : "Unknown"
            }

            const format_number = (x) => { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }

            let mot_obj = {
                mot_agg_name: "",
                mot_items: [],
                mot_insights_figures: {},
                insights_data: [],
            }

            if (data['mot_agg'][0]) {
                const MAKE = this.props.free_check ? data.api_response.make : capitalize(data['mot_agg'][0]['make'].toLowerCase())
                const MODEL = this.props.free_check ? data.api_response.model : capitalize(data['mot_agg'][0]['model'].toLowerCase())

                // data['mot_items'] = data['mot_items'].slice(0, 5)

                let mot_items = []
                let total_count = 0

                for (let item of data['mot_items']) {
                    total_count += parseInt(item['defect_count'])
                }

                for (let item of data['mot_items']) {
                    mot_items.push([
                        item['defect'].replace('(A)', '(Advisory)').replace('(F)', '(Failure)'),
                        Math.round(item['defect_count'] * 100 / total_count, 1),
                        format_number(item['defect_count'])
                    ])
                }

                let n_tests = 0
                let n_pass = 0
                let current_obj = { year: parseInt(data['mot_yearly'][0]['test_year']) }
                let insights_data = []

                for (let entry of data['mot_yearly']) {
                    // Populate total count
                    if (entry['result'] !== 'F') {
                        n_pass += parseInt(entry['count'])
                    }
                    n_tests += parseInt(entry['count'])

                    // Set insights obj
                    if (current_obj['year'] !== parseInt(entry['test_year'])) {

                        // Add missing keys
                        for (let key of ['fail', 'with_rect', 'first_time']) {

                            if (!(key in current_obj)) {
                                current_obj[key] = 0
                            }

                        }
                        insights_data.push(current_obj)
                        current_obj = { year: parseInt(entry['test_year']) }
                    }

                    switch (entry['result']) {
                        case 'P':
                            current_obj['first_time'] = parseInt(entry['count'])
                            break
                        case 'PRS':
                            current_obj['with_rect'] = parseInt(entry['count'])
                            break
                        case 'F':
                            current_obj['fail'] = parseInt(entry['count'])
                            break
                        default:
                            break
                    }
                }

                // Add missing keys
                for (let key of ['fail', 'with_rect', 'first_time']) {

                    if (!(key in current_obj)) {
                        current_obj[key] = 0
                    }

                }

                insights_data.push(current_obj)

                let n_perc = Math.round(n_pass * 100 / n_tests, 0)
                mot_obj = {
                    mot_agg_name: `${MAKE} ${MODEL}`,
                    mot_items: mot_items,
                    mot_insights_figures: {
                        n_vehicles: format_number(data['mot_agg'][0]['vehicle_count']),
                        pass_rate: `${n_perc}%`,
                        avg_mileage: format_number(data['mot_agg'][0]['avg_mileage']),
                        top_failure: data['mot_agg'][0]['top_failure'] ? data['mot_agg'][0]['top_failure'].slice(0, -4) : "",
                        top_advisory: data['mot_agg'][0]['top_advisory'] ? data['mot_agg'][0]['top_advisory'].slice(0, -4) : ""
                    },
                    insights_data: insights_data
                }
            }

            this.setState({
                history: HISTORY_OBJ,
                mileage: mile_list_formatted,
                statuses: STATUSES_OBJ,
                mot: mot_obj,
                car_img: filtered_data['image'].value
            })

            loaded = true
        } catch (error) {
            console.error(error)
        }
    }

    handleImageError = (event) => {
        event.target.onError = null; // Prevent infinite loop if fallback image also fails
        event.target.src = "./assets/car_no_image.webp"
        if (this.props.car_img) {
            event.target.src = this.props.car_img
        }
    }

    get_cert_status = (check) => {
        if (check === 'mot') {

            if (this.state.statuses.mot_status === 'pass') {
                return 'Pass'
            } else if (this.state.statuses.mot_status === 'fail') {
                return 'Fail'
            } else {
                return 'No Data'
            }

        } else {

            if (this.state.statuses.tax_status === 'pass') {
                return 'Pass'
            } else if (this.state.statuses.tax_status === 'fail') {
                return 'Fail'
            } else {
                return 'No Data'
            }

        }
    }

    get_cert_details = (check) => {
        try {
            let EX = check === 'mot' ? this.state.statuses.mot_expiry : this.state.statuses.tax_expiry

            if (EX.length === '8') {
                return 'Unknown'
            }

            let parts = EX.split('/')
            let day = parseInt(parts[0], 10)
            let month = parseInt(parts[1], 10) - 1
            let year = 2000 + parseInt(parts[2], 10)

            let js_date = new Date(year, month, day);
            let js_date_now = new Date();

            let diff_days = Math.floor((js_date_now - js_date) / (1000 * 60 * 60 * 24));

            let suffix_str = diff_days < 0 ? `(${diff_days} days)` : `(${diff_days} days ago)`

            suffix_str = diff_days ? suffix_str : ''

            return `${EX.replaceAll('/', '-')} ${suffix_str.replace('-', '')}`

        } catch (err) {
            return 'Unknown'
        }

    }

    render() {

        const FIGS = this.state.mot.mot_insights_figures
        const INS = this.state.mot.insights_data
        const TOP_MOT = this.state.history.mot_table.length > 0 ? this.state.history.mot_table[0] : null
        const STAT = this.state.statuses

        let mot_alerts = STAT.mot_status === "pass" ? 0 : 1
        mot_alerts += STAT.tax_status === "pass" ? 0 : 1

        let otr_count = INS.length > 0 ? INS[INS.length - 1]['fail'] + INS[INS.length - 1]['first_time'] + INS[INS.length - 1]['with_rect'] : 0

        return (
            <div id="certification_panel" className="report_panel">
                {this.props.free_check && <FreeCheckCTA plate={this.props.plate} img='./assets/CTA/mot.webp' title={"Discover the full story"} text="29% of cars fail their MOT. Upgrade to check this car’s full MOT history." />}

                <div id='columns'>
                    <div id='col_1' className='col active'></div>
                    <div id='col_2' className='col'></div>
                    <div id='col_3' className='col'></div>
                </div>

                <ReportFrame
                    alert={mot_alerts > 0}
                    title="MOT & Tax Check"
                    col_classes={"1-1-1 2-1-1 3-1-1"}
                >
                    <div id="mot_checks">
                        <StatusDropdown
                            title={'MOT'}
                            status_class={STAT.mot_status}
                            stataus_text={this.get_cert_status('mot')}
                            item_key={STAT.mot_status === "pass" ? 'Expires' : 'Expired'}
                            item_value={this.get_cert_details('mot')}
                            default_expand={this.props.hist_check === 'MOT'}
                        />

                        <div className='item_sep'></div>

                        <StatusDropdown
                            title={'Tax'}
                            status_class={STAT.tax_status}
                            stataus_text={this.get_cert_status('tax')}
                            item_key={STAT.tax_status === "pass" ? 'Expires' : 'Expired'}
                            item_value={this.get_cert_details('tax')}
                            default_expand={this.props.hist_check === 'Tax'}
                        />
                    </div>
                </ReportFrame>


                {this.props.free_check && TOP_MOT &&
                    <ReportFrame
                        alert={TOP_MOT.result === 'FAIL'}
                        title="Most Recent MOT Test"
                        col_classes={"1-1-2 2-1-2 3-1-2"}
                    >
                        <div className="mot_history">

                            <div className="mot_rows">

                                <MOTRow
                                    testdate={TOP_MOT.date}
                                    count={TOP_MOT.count}
                                    testno={TOP_MOT.no}
                                    result={TOP_MOT.result}
                                    result_icon={TOP_MOT.result === "FAIL" ? './assets/icons/marker_fail_v2.svg' : './assets/icons/marker_pass_v2.svg'}
                                    mileage={TOP_MOT.mileage}
                                    expiry={TOP_MOT.expiry}
                                    advisory_list={TOP_MOT.advisory_list}
                                    failure_list={TOP_MOT.failure_list}
                                />
                            </div>

                        </div>
                    </ReportFrame>}

                {(!this.props.free_check || (this.props.free_check && this.state.history.n_tests > 1)) &&
                    <ReportFrame
                        free_check={this.props.free_check}
                        alert={this.state.history.total_failures > 0 && !this.props.free_check}
                        title="Full MOT History"
                        nodata={this.state.history.n_tests === 0}
                        col_classes={"1-1-3 2-2-1 3-2-1"}
                        plate={this.props.plate}
                    >
                        <div className="mot_history">
                            {!this.props.free_check && <div className="mot_rows">
                                {this.state.history.mot_table.map((row, i) => (
                                    <div key={i}>
                                        <MOTRow
                                            testdate={row.date}
                                            count={row.count}
                                            testno={row.no}
                                            result={row.result}
                                            result_icon={row.result === "FAIL" ? './assets/icons/marker_fail_v2.svg' : './assets/icons/marker_pass_v2.svg'}
                                            mileage={row.mileage}
                                            expiry={row.expiry}
                                            advisory_list={row.advisory_list}
                                            failure_list={row.failure_list}
                                            free_check={this.props.free_check}

                                        />
                                        {i < this.state.history.mot_table.length - 1 && <div className='mot_row_sep'></div>}
                                    </div>
                                ))}
                            </div>}

                            {this.props.free_check &&
                                <div className='mot_rows'>
                                    {this.state.history.mot_table.map((row, i) => (
                                        <div key={i}>

                                            <MOTRow
                                                i={i}
                                                testdate={row.date}
                                                count={row.count}
                                                testno={row.no}
                                                result={row.result}
                                                result_icon={row.result === "FAIL" ? './assets/icons/marker_fail_v2.svg' : './assets/icons/marker_pass_v2.svg'}
                                                mileage={row.mileage}
                                                expiry={row.expiry}
                                                advisory_list={row.advisory_list}
                                                failure_list={row.failure_list}
                                                free_check={this.props.free_check}
                                            />
                                            {i < this.state.history.mot_table.length - 1 && i !== 0 && <div className='mot_row_sep'></div>}
                                        </div>
                                    ))}
                                </div>

                            }

                        </div>
                    </ReportFrame>}


                <Review free_check={this.props.free_check} class_name='1-1-4 2-1-5 3-1-4' avatar='5' text='I learnt so much about my purchase, I recommend purchasing a full report!' />

                {this.state.history.n_tests > 0 && <ReportFrame
                    free_check={this.props.free_check}
                    title="MOT Stats"
                    col_classes={"1-1-5 2-1-3 3-3-1"}
                    plate={this.props.plate}
                >
                    <div id="mot_stats">

                        <div id="pass_rate">
                            <p id='pass_rate_title'>MOT Pass Rate:</p>
                            <div id='mot_perc'>
                                <img alt='' src={this.props.free_check ? './assets/icons/marker_warn_v2.svg' : './assets/icons/list.svg'} />
                                <p className={this.props.free_check ? 'blur' : ''}>{this.state.history.mot_perc}%</p>
                            </div>
                        </div>

                        <div id="summary_breakdown">
                            <div className="breakdown_item">
                                <p className="default_key">Total passes:</p>
                                <p className={this.props.free_check ? 'blur default_val' : 'default_val'}>{this.state.history.n_passes}</p>
                            </div>

                            <div className='mot_sep'></div>

                            <div className="breakdown_item">
                                <p className="default_key">Total fails:</p>
                                <p className={this.props.free_check ? 'blur default_val' : 'default_val'}>{this.state.history.n_fails}</p>
                            </div>

                            <div className='mot_sep'></div>

                            <div className="breakdown_item">
                                <p className="default_key">Total advisories:</p>
                                <p className={this.props.free_check ? 'blur default_val' : 'default_val'}>{this.state.history.n_advise}</p>
                            </div>

                            <div className='mot_sep'></div>

                            <div className="breakdown_item">
                                <p className="default_key">Total failure items:</p>
                                <p className={this.props.free_check ? 'blur default_val' : 'default_val'}>{this.state.history.n_failure}</p>
                            </div>

                        </div>
                    </div>
                </ReportFrame>}


                <ReportFrame
                    alert={false}
                    nodata={this.state.mileage.mileage_years.length === 0}
                    free_check={this.props.free_check}
                    block_children={true}
                    title="Mileage"
                    col_classes={"1-1-6 2-2-2 3-1-3"}
                    plate={this.props.plate}
                >
                    <div className="highcharts_cont">
                        <LineChartCert years={this.state.mileage.mileage_years} data={this.state.mileage.mileage_values} sources={this.state.mileage.mileage_sources} />
                    </div>
                </ReportFrame>

                <ListAd free_check={this.props.free_check} class_name='1-1-7 2-1-6 3-1-5' />

                <ReportFrame
                    title={'Buyer Beware'}
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    nodata={this.state.mot.mot_items.length < 2}
                    col_classes={"1-1-8 2-1-4 3-2-2"}
                >
                    <div id='mot_issues'>
                        <h3>Top 5 MOT issues for {this.state.mot.mot_agg_name}s</h3>

                        {this.state.mot.mot_items.length > 0 &&
                            <div id='top_item' className="issues_table_row">
                                <div id='top_name'>
                                    <img src='./assets/icons/one.svg' alt='Number one' />
                                    <p className={this.props.free_check ? "blur default_key" : "default_key"}>{this.state.mot.mot_items[0][0]}</p>
                                </div>
                                <p className={this.props.free_check ? "blur default_val" : "default_val"}>{this.state.mot.mot_items[0][2]}</p>
                            </div>}

                        <div id="issues_table">
                            {this.state.mot.mot_items.map((item, i) => (
                                <div key={i}>
                                    {i > 0 && <div className="issues_table_row">
                                        <p className={this.props.free_check ? "blur " : "default_key"}>{item[0]}</p>
                                        <p className={this.props.free_check ? "blur default_val" : "default_val"}>{item[2]}</p>
                                    </div>}
                                    {i > 0 && i < this.state.mot.mot_items.length - 1 && <div className='sep'></div>}
                                </div>
                            ))}
                        </div>
                    </div>
                </ReportFrame>

                <ReportFrame
                    title="Did You Know?"
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    nodata={this.state.mot.insights_data.length === 0}
                    col_classes={"1-1-9 2-2-3 3-3-2"}
                >
                    <div id="mot_insights">
                        <div className="highcharts_cont">
                            <StackedColumn free_check={this.props.free_check} data={this.state.mot.insights_data} />
                        </div>

                        {FIGS && <div id="insights_figures">
                            {FIGS.n_vehicles &&
                                <div className="insights_item">
                                    <p className="default_key">{this.state.mot.mot_agg_name}s on the road</p>
                                    <p className={this.props.free_check ? "blur default_val" : "default_val"}>{otr_count.toLocaleString('en-GB')}</p>
                                </div>
                            }
                            {FIGS.n_vehicles && <div className="insights_sep"></div>}

                            {FIGS.pass_rate &&
                                <div className="insights_item">
                                    <p className="default_key">MOT Pass Rate</p>
                                    <p className={this.props.free_check ? "blur default_val" : "default_val"}>{this.props.free_check ? "??%" : FIGS.pass_rate}</p>
                                </div>
                            }
                            {FIGS.pass_rate && <div className="insights_sep"></div>}

                            {FIGS.avg_mileage &&

                                <div className="insights_item">
                                    <p className="default_key">Avg. Mileage</p>
                                    <p className={this.props.free_check ? "blur default_val" : "default_val"}>{FIGS.avg_mileage} mi</p>
                                </div>
                            }

                        </div>}
                    </div>
                </ReportFrame>

            </div>
        )
    }
}

Certification.displayName = 'MOT'

export default Certification