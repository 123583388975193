import '../../styles/components/graphs/donut_chart.scss';

import React, { Component } from "react";

import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import HC_access from "highcharts/modules/accessibility";

HC_access(Highcharts);
HC_more(Highcharts);

class DonutChart extends Component {
    chartRef = React.createRef();

    render() {

        let arm_dist = 12

        let W = window.innerWidth
        let H = ''
        if (W >= 1240) {
            H = '75%'
        } else if (W >= 900) {
            H = '60%'
        } else if (W >= 700) {
            H = '75%'
        } else if (W >= 600) {
            H = '80%'
            arm_dist = 5
        } else if (W >= 400) {
            H = "60%"
            arm_dist = 12
        }else{
            H = "75%"
            arm_dist = 8
        }

        const options = {
            chart: {
                type: 'pie', 
                height: H,
                style: {
                    fontFamily: 'Nunito Sans, sans-serif'
                }
            },
            title: { text: null },
            credits: { enabled: false, },

            plotOptions: {
                pie: {
                    animation: false,
                    innerSize: '60%',
                    dataLabels: {
                        enabled: true,
                        distance: arm_dist,
                        style: {
                            fontSize: 12,
                            fontFamily: 'Nunito Sans, sans-serif',
                            fontWeight: 500,
                            color: '#3F4946',
                            textOutline: false
                        }
                    },
                    tooltip: {
                        pointFormat: this.props.tooltip
                    }
                }
            },


            series: [{
                name: '',
                colors: this.props.colours,
                data: this.props.data,
                backgroundColor: null,
            }]
        }

        return (
            <div id='donut'>
                <div className={this.props.free_check ? "block" : ""}></div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={this.chartRef}
                />
            </div>
        );
    }
}
export default DonutChart;