import React, { Component } from "react";

import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import highchartsMap from "highcharts/modules/map";
import HC_access from "highcharts/modules/accessibility";
import map_data from "../../uk-counties.json";

HC_access(Highcharts);
HC_more(Highcharts);
highchartsMap(Highcharts);

class MapUK extends Component {
    chartRef = React.createRef();

    get_colour = (status) => {
        let colour = "#6ddba7"

        if (status === "Fail") {
            colour = "#dd5b52"
        } else if (status === "unknown") {
            colour = "#f0b261"
        }

        return colour
    }

    render() {

        const ZC = this.props.checks

        const cities = [
            { name_tooltip: 'London', lat: 51.5074, lon: -0.1278, colour: this.get_colour(ZC.lndn), name: 'London' },
            { name_tooltip: 'Bath', lat: 51.3794, lon: -2.3656, colour: this.get_colour(ZC.bath), name: 'Bath' },
            { name_tooltip: 'Birmingham', lat: 52.4862, lon: -1.8904, colour: this.get_colour(ZC.bham), name: 'Birmingham' },
            { name_tooltip: 'Bradford', lat: 53.7939, lon: -1.7521, colour: this.get_colour(ZC.brad), name: 'Bradford' },
            { name_tooltip: 'Bristol', lat: 51.4545, lon: -2.5879, colour: this.get_colour(ZC.bris), name: 'Bristol' },
            { name_tooltip: 'Portsmouth', lat: 50.8198, lon: -1.0875, colour: this.get_colour(ZC.port), name: 'Portsmouth' },
            { name_tooltip: 'Sheffield', lat: 53.3811, lon: -1.4701, colour: this.get_colour(ZC.shef), name: 'Sheffield' },
            { name_tooltip: 'Tyneside', lat: 54.9783, lon: -1.6178, colour: this.get_colour(ZC.newc), name: 'Tyneside' },
        ];

        const options = {

            chart: {
                map: map_data,
                height: 360,
                spacing: [0, 0, 0, 0],
                animation: false,
                style: {
                    fontFamily: 'Nunito Sans, sans-serif'
                }
            },
            title: { text: null },
            credits: { enabled: false },
            mapNavigation: { enabled: false },
            legend: { enabled: false },

            tooltip: {
                headerFormat: '',
                pointFormat: '{point.name_tooltip}'
            },



            series: [
                {
                    name: 'Basemap',
                    mapData: map_data,
                    nullColor: 'rgba(200, 200, 200, 0.3)',
                    showInLegend: false
                },
                { type: 'mappoint', color: [cities[0].colour][0], data: [cities[0]], marker: { symbol: "circle", radius: 10, lineWidth: 1 } },
                { type: 'mappoint', color: [cities[1].colour][0], data: [cities[1]], marker: { symbol: "circle", radius: 10, lineWidth: 1 } },
                { type: 'mappoint', color: [cities[2].colour][0], data: [cities[2]], marker: { symbol: "circle", radius: 10, lineWidth: 1 } },
                { type: 'mappoint', color: [cities[3].colour][0], data: [cities[3]], marker: { symbol: "circle", radius: 10, lineWidth: 1 } },
                { type: 'mappoint', color: [cities[4].colour][0], data: [cities[4]], marker: { symbol: "circle", radius: 10, lineWidth: 1 } },
                { type: 'mappoint', color: [cities[5].colour][0], data: [cities[5]], marker: { symbol: "circle", radius: 10, lineWidth: 1 } },
                { type: 'mappoint', color: [cities[6].colour][0], data: [cities[6]], marker: { symbol: "circle", radius: 10, lineWidth: 1 } },
                { type: 'mappoint', color: [cities[7].colour][0], data: [cities[7]], marker: { symbol: "circle", radius: 10, lineWidth: 1 } },

            ]

        };

        return (
            <div id="map_uk">
                <HighchartsReact
                    constructorType={'mapChart'}
                    highcharts={Highcharts}
                    options={options}
                    ref={this.chartRef}

                />
            </div>
        );
    }
}

export default MapUK;