import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Breadcrumb = ({ breadcrumbs }) => {
    const itemListElement = breadcrumbs.map((breadcrumb, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "name": breadcrumb.name,
        "item": breadcrumb.item,
    }));

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": itemListElement,
    };

    return (
        <HelmetProvider>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
        </HelmetProvider>
    );
};

export default Breadcrumb;
