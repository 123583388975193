export const TT = {
    "home": {
        "Finance": "An unpaid balance on a car loan or lease agreement may impact the car's ownership and legal status.",
        "Stolen": "If a used vehicle is currently recorded as stolen, it could be seized by law enforcement and returned to its rightful owner.",
        "Scrapped": "A scrapped vehicle is no longer roadworthy and cannot be driven legally on public roads.",
        "VIC": "Damaged cars between 2003-2015 with repair costs that exceeded the pre-accident value were given a VIC marker, which was only removed when the vehicle passed a VIC check.",
        "Imported": "Imported vehicles might not meet all UK safety and emission standards, and might also be costlier to maintain.",
        "Exported": "A vehicle that has been sold or transferred to a buyer in another country.",
        "Plate Change": "Changes to the vehicle's registration number (also known as license plate or tag) could be a sign of previous owners attempting to conceal the vehicle's past or identity.",
        "Mileage Issue": "Any discrepancies in the reported mileage of a vehicle, such as differences between the mileage shown on the odometer and the vehicle's service records, can falsely inflate the car's value and mask its true condition.",
        "Written-off": "A vehicle that has been deemed a total loss by an insurance company or other authority could have hidden issues as a result of significant damage from an accident or other incident.",
        "High Risk": "Third parties may track the movement of vehicles within the market, as they have an interest in preventing them from being sold on, often related to an ownership dispute or a finance agreement.",
        "Colour Change": "Any changes made to the vehicle's exterior colour could potentially indicate attempts to disguise damage or neglect. This could include repainting or refinishing the body panels or other exterior components.",
        "Recalls": "An official notice issued by a manufacturer or regulatory authority, alerting vehicle owners to a safety defect or other issue that has been identified with the vehicle.",
    },
    "ove": {
        "ncap": "The European New Car Assessment Programme (Euro NCAP) is a car-safety performance assessment programme. \n\nThe safety rating is determined from a series of vehicle tests, designed and carried out by Euro NCAP. These tests represent, in a simplified way, important real-life accident scenarios that could result in injured or killed car occupants or other road users. The number of stars reflects how well the car performs in Euro NCAP tests, but it is also influenced by what safety equipment the vehicle manufacturer is offering in each market.",
        "vin": "The Vehicle Identification Number (VIN) is a vehicle's fingerprint. it is a unique, stamped-in number used to identify an individual vehicle, with no two cars having the same VIN.",
        "v5c": "A V5C - also known as the registration document, vehicle logbook, or simply the V5 form - is a paper document that is issued to the registered keeper of a vehicle by the DVLA containing specific details about the vehicle.",
        "engine": "A unique code assigned to the vehicle's engine by the manufacturer, used to identify specific engines for warranties, maintenance etc."
    },
    "his": {
        "Finance": "Outstanding finance on a car refers to the remaining unpaid balance on a car loan or lease agreement that the borrower has yet to settle. Should the borrower default on their payments, the finance company has the legal right to repossess the vehicle, leaving the new owner at risk of losing the car despite having paid for it.",
        "Stolen": "A car's status as stolen is a critical red flag for potential buyers, indicating that the vehicle has been unlawfully taken from its rightful owner. Purchasing a stolen car can lead to legal consequences, including the possibility of criminal charges for receiving stolen property and the inevitable forfeiture of the vehicle back to its rightful owner. If a vehicle's stolen status is revealed, it is crucial to report this information to local authorities and avoid engaging in any transactions with the seller.",
        "Scrapped": "If a vehicle has been declared scrapped, it means that it is no longer roadworthy and cannot be driven legally on public roads. This designation is usually given by the relevant authorities when the vehicle has suffered irreparable damage or has reached the end of its useful life. A scrapped vehicle will typically be sent to a scrapyard for disposal, and any usable parts may be salvaged for use in other vehicles.",
        "VIC": "The Vehicle Identity Check (VIC) was in force between 2003 and 2015 to make car “ringing” more difficult. Damaged cars with repair costs that exceeded the pre-accident value were given a VIC marker, which was only removed when the vehicle passed a VIC.",
        "Imported": "An imported vehicle is one that has been brought into the country from another country, rather than being manufactured domestically. Imported vehicles may have different safety and emission standards that must be taken into account.",
        "Exported": "An exported vehicle is one that has been sold or transferred to a buyer in another country and is no longer registered or available in the local market. This designation may be relevant when considering the purchase of a used vehicle, as it could indicate that the vehicle has been subjected to different climate or road conditions, as well as different safety and emission standards.",
        "Plate Change": "Plate changes refer to any changes made to the vehicle's registration number (also known as license plate or tag). This could include changes made to the number itself or the format of the plate. Plate changes may be a cause for concern when considering the purchase of a used vehicle, as they could suggest that the vehicle has been re-registered for reasons related to its history or quality.",
        "Mileage Issue": "Mileage discrepancy refers to any inconsistencies in the reported mileage of a vehicle, such as differences between the mileage shown on the odometer and the vehicle's service or repair records. This could suggest that that the mileage has not been accurately reported in the vehicle's history or that the odometer has been tampered with.",
        "Written-off": "A vehicle that has been written off has been deemed a total loss by an insurance company or other authority, usually as a result of significant damage from an accident or other incident. Written off vehicles may be repaired and returned to the road, but they may also present ongoing safety or quality issues that could impact their value and usability.",
        "High Risk": "When a vehicle is recorded as being high-risk it usually means that a third party is tracking the movement of the vehicle within the market as it has an interest in preventing it from being sold on, often related to an ownership dispute or a finance agreement.",
        "Colour Change": "Colour changes refer to any changes made to the vehicle's exterior colour. This could include repainting or refinishing the body panels or other exterior components. While colour changes are not necessarily a cause for concern, they could suggest that the vehicle has undergone repairs or modifications that should be investigated further.",
        "Recalls": "A recall is an official notice issued by a manufacturer or regulatory authority, alerting vehicle owners to a safety defect or other issue that has been identified with the vehicle. Recalls may require repairs or modifications to be made to the vehicle in order to address the safety issue. It's essential to check with your local dealer to see if these issues affect your specific vehicle, and if so, whether they have been remedied.",
        "Written-off_items": {
            "Category": "A: Cannot be repaired, entire vehicle should be destroyed\n B: Cannot be repaired, but parts may be salvaged\n C: Repairs would cost more than the car's value\n D: Total cost to make the car road-worthy would be more than the car's value\n N: Can be repaired following non-structural damage\n S: Can be repaired following structural damage",
            "MIAFTR Entry Date": "The Motor Insurers Anti-Fraud and Theft Register (MIAFTR) is a database containing records of written-off and stolen vehicles.",
        },
        "Tax": "The vehicle's current tax status, indicating whether the vehicle is legally registered for use on public roads.",
        "MOT": "The current result of the vehicle's most recent MOT test, indicating whether the vehicle is considered roadworthy and meets safety and environmental standards. A vehicle must have passed an MOT by the third anniversary of its registration, and each year thereafter."
    },
    "val": {
        "current": "A current valuation using the cost of the vehicle brand new, accounting for depreciation over the years since it was first used. This does not take into account the specific attributes of a vehicle such as cosmetic damage, alterations, or maintenance requirements.",
        "new": "The manufacturer's cost for the vehicle brand new.",
        "dealership": "The retail price that a main dealer (franchise or independent) would ask for the vehicle.",
        "private": "The price that a private individual would ask for when selling the vehicle e.g. in classified ads.",
        "trade": "The retail price that a smaller independent dealer would ask for the vehicle.",
        "auction": "The price that a dealer would expect to pay when buying the vehicle from a reputable car auction.",
        "depreciation": "This graph shows the depreciation in value over time for this vehicle. It is based on an estimated depreciation rate that decreases each year, to a minimum of 3%. In reality, the price will fluctuate over time - and certain cars may even increase in value.",
        "mileage": "The average mileage for a UK driver over one year is 7400 miles. You can change this value if you have a more accurate idea of your estimated annual mileage, which will produce a more accurate cost of ownership value.",
        "fuel_price": "This is the current UK average price of fuel for this vehicle. If you have an idea of prices in your local area, enter a new value and the cost will update accordingly.",
        "expenses": {
            "Fuel": "The total cost of fuel over 1 year, calculated using the estimated annual mileage and fuel price. All results are based upon the theoretical optimum operating efficiency of the vehicle as quoted by the manufacturer. \n\nThe real-world fuel economy figures are based on real-world driving tests or where this data is not available, estimated from official NEDC or WLTP figures (depending on model), which are obtained from the official EU test data. These figures are intended for comparisons between vehicles and may not reflect real driving results.",
            "Insurance": "The average cost of insurance for UK drivers in 2023. This value is an average figure taken from the ONS and may not reflect factors such as your age and driving history.",
            "Repairs & <br>Servicing": "The average cost of repairs and servicing for UK drivers in 2023. This value will scale with the annual mileage input i.e. the higher the mileage, the higher your servicing costs are likely to be.",
            "Tax": "The average cost of car tax for UK drivers in 2023. This will differ for individual vehicles.",
            "MOT": "The cost of an MOT, based on the current legal maximum MOT cost of £55.",
            "Other": "This cost includes additional expenses such as anti-freeze and cleaning materials."
        }
    },

    "eco": {
        "eco_score": {
            "Euro Score": "All cars in the European Union must meet a certain Euro emissions standard (1-6) when they are manufactured. These standards aim to reduce pollution/improve air quality and change over time, meaning older cars adhere to a different Euro Standard than newer cars, which are manufactured to much stricter regulations.",
            "Size": "The size of a vehicle impacts the environment around them in several ways.\n\nDue to their elevated stance and wider dimensions, larger vehicles present an increased risk of death or serious injury to pedestrians in the event of a collision, compared to a 'normal' sized vehicle.\n\nLarger vehicles also tend to require more materials and therefore energy during production, leading to a higher carbon footprint.",
            "Noise": "Noise pollution from vehicles can cause stress, sleep disturbances, and hearing loss in humans, and can disturb animal habitats. \n\nWhile it doesn't have a direct impact on air quality or climate change, noise pollution contributes to the overall environmental impact of vehicles and affects the quality of life.",
            "Efficiency": "Efficiency is measured in MPGe, which stands for 'Milles Per Gallon Equivalent'. This is a useful measure that allows us to include electric and hybrid vehicles in comparisons.\n\n High-efficiency vehicles use less energy overall, and therefore are much better for the environment.",
            "Emissions": "Pollution is the most significant factor in a vehicle's environmental impact. Different types of pollution, such as NOx, CO2, CO, and particulates, contribute to air-quality degradation, climate change, and public health issues.",
            "Weight": "Vehicle weight has a direct impact on fuel consumption and emissions. These factors are accounted for in the ARC eco-score within the efficiency and emissions scores, therefore weight has a lower effect on the overall score - it is still important however, as a higher weight indicates more raw materials are used in it's construction, which is less sustainable.",
            "Fuel": "The type of fuel a vehicle uses greatly influences its environmental impact. For example, electric vehicles produce virtually zero tailpipe emissions, whereas diesel and petrol vehicles emit various pollutants.\n\nAlternative fuels can offer reduced emissions compared to traditional fossil fuels, but their environmental benefits depend on the specific fuel type and production methods.\n\nIt is important to note this score focuses solely on the fuel of the vehicle itself, not the source where it has come from."
        },
        "pollution": {
            "CO2 (g/km)": "The amount of carbon dioxide emitted by the vehicle, measured in grams emitted per kilometer driven (g/km).",
            "CO (mg/km)": "The amount of carbon monoxide emitted by the vehicle, measured in milligrams emitted per kilometer driven (mg/km).",
            "THC (mg/km)": "The total hydrocarbon count (THC) emitted by the vehicle, measured in milligrams emitted per kilometer driven (mg/km).",
            "Particulates (mg/km)": "The amount of particulate matter - such as fine dust or soot particles - emitted by the vehicle, measured in milligrams emitted per kilometer driven (mg/km).",
            "NOx (mg/km)": "The amount of nitrogen oxide emitted by the vehicle, measured in milligrams emitted per kilometer driven (mg/km)."
        },
        "zones": {
            "London": "Greater London operates an Ultra Low Emission Zone, or ULEZ.\n\nThe ULEZ standards are:\n\nEuro 3 for motorcycles, mopeds, motorised tricycles and quadricycles (L category)\n\nEuro 4 (NOx) for petrol cars, vans, minibuses and other specialist vehicles\n\nEuro 6 (NOx and PM) for diesel cars, vans and minibuses and other specialist vehicles",
            "Bath": "Bath has a Class C clean air zone.\n\nA Class C zone means buses, coaches and heavy goods vehicles must meet Euro 6 standard, and vans, minibuses, taxis and private hire vehicles must meet Euro 6 (diesel) and Euro 4 (petrol) standard.\n\nIt is important to check the local authority's regulations directly before making a journey to this location.",
            "Birmingham": "Birmingham has a Class D clean air zone.\n\nA Class D zone means buses, coaches and heavy goods vehicles must meet Euro 6 standard, and cars, vans, minibuses, taxis and private hire vehicles must meet Euro 6 (diesel) and Euro 4 (petrol) standard.\n\nIt is important to check the local authority's regulations directly before making a journey to this location.",
            "Bradford": "Bradford has a Class C clean air zone.\n\nA Class C zone means buses, coaches and heavy goods vehicles must meet Euro 6 standard; and vans, minibuses, taxis and private hire vehicles must meet Euro 6 (diesel) and Euro 4 (petrol) standard.\n\nIt is important to check the local authority's regulations directly before making a journey to this location.",
            "Bristol": "Bristol has a Class D clean air zone.\n\nA Class D zone means buses, coaches and heavy goods vehicles must meet Euro 6 standard, and cars, vans, minibuses, taxis and private hire vehicles must meet Euro 6 (diesel) and Euro 4 (petrol) standard.\n\nIt is important to check the local authority's regulations directly before making a journey to this location.",
            "Portsmouth": "Portsmouth has a Class B clean air zone.\n\nA Class B zone means buses, coaches and heavy goods vehicles must meet Euro 6 standard, and taxis and private hire vehicles must meet Euro 6 (diesel) and Euro 4 (petrol) standard.\n\nIt is important to check the local authority's regulations directly before making a journey to this location.",
            "Sheffield": "Sheffield has a Class C clean air zone.\n\nA Class C zone means buses, coaches and heavy goods vehicles must meet Euro 6 standard, and vans, minibuses, taxis and private hire vehicles must meet Euro 6 (diesel) and Euro 4 (petrol) standard.\n\nIt is important to check the local authority's regulations directly before making a journey to this location.",
            "Tyneside": "Tyneside (Newcastle and Gateshead) has a Class C clean air zone.\n\nA Class C zone means buses, coaches and heavy goods vehicles must meet Euro 6 standard, and vans, minibuses, taxis and private hire vehicles must meet Euro 6 (diesel) and Euro 4 (petrol) standard.\n\nIt is important to check the local authority's regulations directly before making a journey to this location."
        },
        "fuel_econ": "Fuel economy refers to the distance a vehicle can drive in miles, per gallon of fuel. It is affected by the speed you drive, hence why there are different values for different environments.\n\nUrban: City driving (low speed)\nExtra urban: Country roads and motorways (high speed)\nCombined: Overall figure encompassing urban and extra-urban",
        "fuel_econ_elec": "Fuel economy for electric vehicles refers to the distance a vehicle can drive in Watt-hours per mile.",
        "zone": "A clean air zone is an area within the UK where there are targeted measures to improve air quality by reducing harmful levels of pollution. The zones work by applying a daily charge to vehicles that do not meet minimum emissions standards.",
        "emissions": "This graphic gives an indication of how eco-friendly this vehicle is in terms of its CO2 tailpipe emissions, based on the grading system by the UK Vehicle Certification Agency.",
        "ncap": "The Green NCAP rating is an independent measure of a vehicle's environmental friendliness, focussing on factors such as clean air, performance, and energy efficiency.",
        "radar": "This graphic shows a comparison between the pollutants of this car against the average vehicle with the same Euro Status. \n\nThe values on the radar graph are the raw pollutant values normalised to between 0 and 100, based on the minimum/maximum values in our emissions database."
    },
    "spec": {
        "card_header": "This section lists all the available specification data for this vehicle. The data is grouped into different sections that you can browse below, or use the search bar if you are looking for a specific item."
    },
    "free": {
        "mot": "The vehicle's current MOT status. It cannot legally be driven on public roads if the MOT is expired, unless the vehicle is under 3 years old.",
        "tax": "The vehicle's current tax status, indicating whether the vehicle is legally registered for use on public roads.",
        "history": "Our basic history checks include a check for any recalls (an official notice issued by a manufacturer or regulatory authority, alerting vehicle owners to a safety defect or other issue that has been identified with the vehicle) or if the vehicle has been exported (a vehicle that has been sold or transferred to a buyer in another country)",
        "recalls": "Our basic history checks include a check for any recalls - an official notice issued by a manufacturer or regulatory authority, alerting vehicle owners to a safety defect or other issue that has been identified with the vehicle.",
        "export": "Our basic history checks include an export check - which is where a vehicle has been sold or transferred to a buyer in another country",
        "all": "Our basic check includes the MOT and tax status of the vehicle, as well as two history checks (manufacturer recalls and export check). \n\nPurchase a full check for a 12-point history check, valuation, specs, and much more!",

    }
}