import React, { Component } from "react";
import '../../styles/components/cards/review.scss';

class Review extends Component {
    render() {

        return (this.props.free_check ?
            <div className={`review_card ${this.props.class_name}`}>
                <p>Join hundreds of satisfied customers</p>
                <q>{this.props.text}</q>
            </div> : <></>
        );
    }
}
export default Review;