import React, { Component } from "react";
import '../styles/components/mot_row.scss';

class MOTRow extends Component {

    handle_expand = (e) => {
        let img = e.currentTarget.querySelectorAll('.dropdown')[0]

        if (!this.props.free_check) {
            let container = img.parentNode.parentNode.parentNode

            if (img.style.transform === 'rotate(45deg)') {
                img.style.transform = 'none'
            } else {
                img.style.transform = 'rotate(45deg)'
            }

            container.children[1].classList.toggle('hide')
        }
    }

    render() {

        const { i, testdate, count, testno, result, result_icon, mileage, expiry, advisory_list, failure_list, free_check } = this.props

        return i === 0 ? (<div className="remove_row"></div>) : (
            <div className="mot_row" >

                <div className="row_main" onClick={this.handle_expand}>
                    <div className="row_main_left">
                        <div className="count_cont">
                            <div className="count">
                                <p>{count}</p>
                            </div>
                        </div>

                        <div className="date_cont">
                            <p className={free_check ? "test_date blur" : "testdate"}>{testdate}</p>
                            <p className={free_check ? "testno blur" : "testno"}>{testno}</p>
                        </div>
                    </div>

                    <div className="result_cont">
                        <div className={free_check ? `marker blur ${result}` : `marker ${result}`}>
                            <p className={free_check ? `result blur ${result.toLowerCase()}` : `result ${result.toLowerCase()}`}>{result.toLowerCase()}</p>
                            <img className={free_check ? 'result_icon blur_img' : "result_icon"} alt="" src={result_icon} />
                        </div>

                        <img className={free_check ? 'dropdown blur_img' : "dropdown"} alt="Dropdown symbol" src="./assets/icons/expand.svg" />
                    </div>
                </div>

                <div className="row_details hide">

                    <div className="expiry">
                        <p className="default_key">Expiry</p>
                        <p className="default_val">{failure_list.length > 0 ? 'N/A' : expiry}</p>
                    </div>

                    <div className={advisory_list.length + failure_list.length > 0 ? "mileage" : "mileage no_border"}>
                        <p className="default_key" >Mileage</p>
                        <p className="default_val">{mileage.toLocaleString('en-GB')} mi</p>
                    </div>

                    {advisory_list.length > 0 && <div className="notes_list advi">
                        <h3>Advisories</h3>
                        <ul>
                            {advisory_list.map((row, i) => (<li className="row" key={i}>{row.charAt(0).toUpperCase() + row.slice(1).replace("!!!", "")}</li>))}
                        </ul>
                    </div>}

                    {failure_list.length > 0 && <div className="notes_list fail">
                        <h3>Failures</h3>
                        <ul>
                            {failure_list.map((row, i) => (<li className="row" key={i}>{row.charAt(0).toUpperCase() + row.slice(1).replace("!!!", "")}</li>))}
                        </ul>

                    </div>}


                </div>

            </div>
        );
    }
}
export default MOTRow;