import '../../styles/components/report_panels/eco_impact.scss'
import { get_relevant_data, move_items, calc_eco_score, get_avg_weight } from '../../utils'
import { TT } from '../../tooltips'
import FreeCheckCTA from '../cards/FreeCheckCTA'
import React, { Component } from "react"

import ReportFrame from "../ReportFrame"
import EmissionsBar from "../graphs/EmissionsBar"
import ComparisonBarElec from '../graphs/ComparisonBarElec'
import MultiDonut from "../graphs/MultiDonut"
import ComparisonBar from "../graphs/ComparisonBar"
import MapUK from "../graphs/MapUK"
import Tooltip from '../Tooltip'
import Review from '../cards/Review'
import ListAd from '../cards/ListAd'

const NOISE_GEN = {
    avg: 71,
    loud: 96,
    quiet: 60
}

const SCORE_TITLES = [
    "Emissions",
    "Fuel",
    "Efficiency",
    "Euro Score",
    "Size",
    "Weight",
    "Noise",
]


const ZC_ITEMS = [
    "bath",
    "bham",
    "brad",
    "bris",
    "port",
    "shef",
    "newc",
]

const FULLNAMES = [
    "London",
    "Bath",
    "Birmingham",
    "Bradford",
    "Bristol",
    "Portsmouth",
    "Sheffield",
    "Tyneside"
]

let loaded = false

class EcoImpact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            car_type: "",
            car_name: "",
            active_score: "this_car",
            euro_status: "",
            show_noise: true,
            fuel_type: "",
            elec_whpm: null,
            clean_air_pass: false,
            checks: {
                drop_eco: false,
                drop_eco_switch: false,
            },
            noise: {
                this_car: 0,
                avg: 0,
            },
            fuel_economy: {
                this_car: [0, 0, 0],
                avg: [0, 0, 0],
                worst: [0, 0, 0]
            },
            pollution: {
                this_car: [0, 0, 0, 0, 0],
                avg: [0, 0, 0, 0, 0],
            },
            green_ncap: {
                nodata: true,
                link: "",
                overall: 0,
                clean_air: 0,
                efficiency: 0,
                greenhouse: 0,
            },
            eco_score: {
                this_car: {
                    weighted: [0, 0, 0, 0, 0, 0, 0],
                    unweighted: [0, 0, 0, 0, 0, 0, 0],
                    overall: 0,
                    raw: [0, 0, 0, 0, 0, 0, 0],
                },
                avg: {
                    weighted: [0, 0, 0, 0, 0, 0, 0],
                    unweighted: [0, 0, 0, 0, 0, 0, 0],
                    overall: 0,
                    raw: [0, 0, 0, 0, 0, 0, 0],

                },
            },
            zone_check: {
                lndn: "unknown",
                bath: "unknown",
                bham: "unknown",
                brad: "unknown",
                bris: "unknown",
                port: "unknown",
                shef: "unknown",
                newc: "unknown",
            }

        }
    }

    componentDidUpdate() {
        if (!loaded & Object.values(this.props.data).length > 0) {
            this.set_state(this.props.data)
        }
    }

    componentDidMount() {
        if (Object.values(this.props.data).length > 0) {
            this.set_state(this.props.data)
        }

        this.organise_columns()
        window.addEventListener('resize', this.organise_columns)
    }

    calc_zone_check = (euro, fuel, year) => {
        let result = "unknown"
        let pass = true

        if (euro && fuel) {
            pass = ((fuel === "PETROL" && parseInt(euro.substring(0, 1)) >= 4)) || ((fuel === "DIESEL" && parseInt(euro.substring(0, 1)) >= 6) || (fuel !== "PETROL" && fuel !== "DIESEL"))
            result = pass ? "Pass" : "Fail"
        } else {
            if (year) {
                if (parseInt(year) <= 2005) {
                    result = "Fail"
                    pass = false
                }
            }
        }

        if (this.props.free_check) {
            result = "unknown"
        }

        this.setState({ clean_air_pass: pass })

        return {
            lndn: result,
            bath: result,
            bham: result,
            brad: result,
            bris: result,
            port: result,
            shef: result,
            newc: result
        }

    }


    set_state = (data) => {
        try {
            // Get the relevant metadata
            let metadata = get_relevant_data(data.api_metadata, 'E')

            let filtered_data = {}
            for (let m of metadata) {
                m['value'] = data.api_response[m['item_name']]
                filtered_data[m['item_name']] = m
            }

            let emissions_agg = data.emissions_agg.length > 0 ? data.emissions_agg[0] : []
            let emissions = data.emissions.length > 0 ? data.emissions[0] : []

            // GENERAL
            const NAME = `${filtered_data['make'].value} ${filtered_data['model'].value}`
            const TYPE = filtered_data['body_style'].value

            // FUEL ECONOMY
            const MPG_URBAN_EX = filtered_data['mpg_urban_cold'].value ? parseFloat(filtered_data['mpg_extra_urban'].value) : null
            const MPG_URBAN = filtered_data['mpg_urban_cold'].value ? parseFloat(filtered_data['mpg_urban_cold'].value) : null
            const MPG_COMB = filtered_data['mpg_combined'].value ? parseFloat(filtered_data['mpg_combined'].value) : null

            let fuel_avg = [0, 0, 0]
            let fuel_worst = [0, 0, 0]
            let noise_avg = 69
            let pollution_avg = [0, 0, 0, 0, 0]

            if (data.emissions_agg.length > 0) {
                fuel_avg = [emissions_agg['mpg_low_avg'], emissions_agg['mpg_high_avg'], emissions_agg['mpg_combined_avg']]
                fuel_worst = [emissions_agg['mpg_low_worst'], emissions_agg['mpg_high_worst'], emissions_agg['mpg_combined_worst']]
                noise_avg = emissions_agg['noise_db_avg']

                pollution_avg = [
                    emissions_agg['co2_g_per_km_avg'],
                    emissions_agg['co_mg_per_km_avg'],
                    emissions_agg['thc_mg_per_km_avg'],
                    emissions_agg['particulates_mg_per_km_avg'],
                    emissions_agg['nox_mg_per_km_avg']
                ]
            }

            let fuel_econ = {
                this_car: [MPG_URBAN, MPG_URBAN_EX, MPG_COMB],
                avg: fuel_avg,
                worst: fuel_worst
            }

            const CO2 = emissions['co2_g_per_km'] ? emissions['co2_g_per_km'] : parseInt(filtered_data['co2'].value)
            const CO = emissions['co_mg_per_km']
            const THC = emissions['thc_mg_per_km']
            const PART = emissions['particulates_mg_per_km'] ? emissions['particulates_mg_per_km'] : parseInt(filtered_data['particles'].value)
            const NOX = emissions['nox_mg_per_km']

            let show_noise = true
            if (!emissions['noise_db'] && !this.props.free_check) {
                show_noise = false
            }

            const NOISE_OBJ = {
                this_car: emissions['noise_db'],
                avg: noise_avg,
            }

            // POLLUTION
            const POLLUTION = {
                this_car: [CO2, CO, THC, PART, NOX],
                avg: pollution_avg,
            }

            // GREEN CAP
            let green_ncap = { nodata: true, link: "", overall: 0, clean_air: 0, efficiency: 0, greenhouse: 0 }
            if (data.green_ncap.length > 0) {
                const GC = data.green_ncap[0]

                green_ncap = {
                    nodata: false,
                    link: GC['url'],
                    overall: GC['rating_overall'] * 5,
                    clean_air: GC['rating_air'],
                    efficiency: GC['rating_efficiency'],
                    greenhouse: GC['rating_greenhouse']
                }
            }

            // CLEAN AIR ZONE CHECKS
            const ZONE_CHECK = this.calc_zone_check(filtered_data['euro_status'].value, filtered_data['fuel_type'].value)

            // ECO SCORE
            const FUEL_OBJ = {
                avg: {
                    type: "PETROL",
                    mpg: fuel_econ.avg[2],
                    elec: 0
                },
                this_car: {
                    type: filtered_data['fuel_type'] ? filtered_data['fuel_type'].value : '',
                    mpg: MPG_COMB,
                    elec: data.api_response['elec-Energy_Consumption_WH_per_mile'] ? data.api_response['elec-Energy_Consumption_WH_per_mile'] : 0
                }
            }

            const SIZE_OBJ = {
                avg: {
                    length: 4400,
                    width: 1800,
                    height: 1500,
                },
                this_car: {
                    length: filtered_data['length_mm'].value,
                    width: filtered_data['width_mm'].value,
                    height: filtered_data['height_mm'].value
                }
            }

            const SCORE_AVG = calc_eco_score(SIZE_OBJ.avg, get_avg_weight(filtered_data['euro_status'].value), filtered_data['euro_status'].value, POLLUTION.avg, NOISE_OBJ.avg, FUEL_OBJ.avg)
            const SCORE_THIS = calc_eco_score(SIZE_OBJ.this_car, filtered_data['weight_kg'].value, filtered_data['euro_status'].value, POLLUTION.this_car, NOISE_OBJ.this_car, FUEL_OBJ.this_car)

            // Check if we have enough data
            let pol_count = 0
            for (let i = 0; i < 5; i++) {
                if (POLLUTION.this_car[i]) {
                    pol_count++
                }
            }
            const pol_valid = pol_count >= 3 || POLLUTION.this_car[0]
            const size_valid = SIZE_OBJ.this_car.length || SIZE_OBJ.this_car.width || SIZE_OBJ.this_car.height ? true : false
            const shape_valid = TYPE ? true : false
            const weight_valid = filtered_data['weight_kg'].value > 0 ? true : false
            const euro_valid = filtered_data['euro_status'].value ? true : false
            const noise_valid = NOISE_OBJ.this_car ? true : false
            const fuel_valid = FUEL_OBJ.this_car.type && (FUEL_OBJ.this_car.mpg || FUEL_OBJ.this_car.elec) ? true : false

            const valid_arr = [pol_valid, size_valid, shape_valid, weight_valid, euro_valid, noise_valid, fuel_valid]

            let errors = 0
            for (let i = 0; i < valid_arr.length; i++) {
                if (!valid_arr[i]) {
                    errors += 1
                }
            }

            let drop_eco = (errors >= 4 || (!pol_valid && (FUEL_OBJ.this_car.type === 'PETROL' || FUEL_OBJ.this_car.type === 'DIESEL'))) && !this.props.free_check

            for (let i = 0; i < 3; i++) {
                if (!fuel_econ.this_car[i]) {
                    fuel_econ.this_car[i] = 0
                }
            }

            this.setState({
                checks: {
                    drop_eco: drop_eco,
                    drop_eco_switch: !euro_valid || this.props.free_check,
                },
                show_noise: show_noise,
                car_name: NAME,
                car_type: TYPE,
                fuel_economy: fuel_econ,
                fuel_type: filtered_data['fuel_type'] ? filtered_data['fuel_type'].value : '',
                elec_whpm: FUEL_OBJ.this_car.elec,
                pollution: POLLUTION,
                noise: NOISE_OBJ,
                zone_check: ZONE_CHECK,
                green_ncap: green_ncap,
                euro_status: filtered_data['euro_status'].value ? filtered_data['euro_status'].value.substring(0, 1) : '',
                eco_score: {
                    this_car: SCORE_THIS,
                    avg: SCORE_AVG,
                }
            })

            loaded = true
        } catch (error) {
            console.error(error)
        }
    }

    handle_eco_change = (value) => {
        this.setState({
            active_score: value
        })
    }

    get_class = (val) => {
        let classname = val > 33.3 ? 'ok_score' : 'bad_score'
        classname = val > 66.6 ? 'good_score' : classname
        return classname
    }

    order_scores = (vals_u, vals_w, raw, numerical) => {


        if (raw) {
            const SCORE_OBJs = SCORE_TITLES.map((title, i) => ({
                title,
                val_u: vals_u[i],
                val_w: vals_w[i],
                raw: raw[i],
                fixed_order: i,
                classname: this.get_class(vals_u[i])
            }))

            const SORTED_OBJs = numerical ? SCORE_OBJs.sort((a, b) => b.val_u - a.val_u) : SCORE_OBJs.sort((a, b) => a.fixed_order - b.fixed_order)

            const NEW_TITLES = SORTED_OBJs.map(obj => obj.title)

            const NEW_VALS = SORTED_OBJs.map(obj => obj.val_u)
            const NEW_WEIGHTED = SORTED_OBJs.map(obj => obj.val_w)
            const NEW_RAW = SORTED_OBJs.map(obj => obj.raw)
            const NEW_CLASSNAMES = SORTED_OBJs.map(obj => obj.classname)

            return [NEW_TITLES, NEW_VALS, NEW_WEIGHTED, NEW_RAW, NEW_CLASSNAMES]
        }
    }

    handle_expand_zone = (e) => {
        let img = e.currentTarget.getElementsByTagName('IMG')[0]

        if (img.style.transform === 'rotate(45deg)') {
            img.style.transform = 'none'
        } else {
            img.style.transform = 'rotate(45deg)'
        }

        document.getElementById('zone_table').classList.toggle('hide')
    }

    handle_expand_eco = (e) => {
        let img = e.currentTarget.getElementsByTagName('IMG')[0]

        if (img.style.transform === 'rotate(45deg)') {
            img.style.transform = 'none'
        } else {
            img.style.transform = 'rotate(45deg)'
        }

        document.getElementById('eco_table').classList.toggle('hide')
    }

    organise_columns = () => {
        const CURR = this.state.current_col

        const ITEMS = ["1-1-1", "1-1-2", "1-1-3", "1-1-4", "1-1-5", "1-1-6", "1-1-7", "1-1-8",
            "2-1-1", "2-1-2", "2-1-3", "2-1-4", "2-1-5", "2-2-1", "2-2-2", "2-2-3",
            "3-1-1", "3-1-2", "3-1-3", "3-1-4", "3-2-1", "3-2-2", "3-3-1", "3-3-2"]

        if (window.innerWidth < 600 && CURR !== 1) {
            move_items(1, ITEMS)
            this.setState({ current_col: 1 })

        } else if (window.innerWidth >= 600 && window.innerWidth < 1440 && CURR !== 2) {
            move_items(2, ITEMS)
            this.setState({ current_col: 2 })

        } else if (window.innerWidth >= 1440 && CURR !== 3) {
            move_items(3, ITEMS)
            this.setState({ current_col: 3 })
        }
    }

    render() {
        const GN = this.state.green_ncap
        let ZC = this.state.zone_check

        const FE_THIS = this.state.fuel_economy.this_car
        const POL = this.state.pollution

        const ECO_VALS_RAW = this.state.eco_score[this.state.active_score].raw
        const ECO_VALS = this.state.eco_score[this.state.active_score].unweighted
        const ECO_VALS_WEIGHTED = this.state.eco_score[this.state.active_score].weighted
        const TABLE_INPUTS = this.order_scores(ECO_VALS, ECO_VALS_WEIGHTED, ECO_VALS_RAW, false)
        const CHART_INPUTS = this.order_scores(ECO_VALS, ECO_VALS_WEIGHTED, ECO_VALS_RAW, true)

        const USE_ELEC = this.state.fuel_type === 'ELECTRICITY' && this.state.elec_whpm
        const green_blur = this.props.free_check && GN.efficiency === 99


        const CO = POL.this_car[1] ? `${POL.this_car[1]} mg/km` : 'Unknown'
        const NOX = POL.this_car[4] ? `${POL.this_car[4]} mg/km` : 'Unknown'
        const PART = POL.this_car[3] ? `${POL.this_car[3]} mg/km` : 'Unknown'
        const THC = POL.this_car[2] ? `${POL.this_car[2]} mg/km` : 'Unknown'

        const HIDE_POL = CO === 'Unknown' && NOX === 'Unknown' && PART === 'Unknown' && THC === 'Unknown'

        return (
            <div id="eco_panel" className="report_panel">
                {this.props.free_check && <FreeCheckCTA plate={this.props.plate} title={"Reveal this car's impact"} text="Discover the eco-footprint of this car and make informed decisions" img={'./assets/CTA/eco.webp'} />}

                <div id='columns'>
                    <div id='col_1' className='col active'></div>
                    <div id='col_2' className='col'></div>
                    <div id='col_3' className='col'></div>
                </div>

                <ReportFrame
                    title="Economy"
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    col_classes={'1-1-1 2-1-1 3-1-1'}
                    nodata={!((USE_ELEC && this.state.elec_whpm > 0) || (!USE_ELEC && FE_THIS[2] > 0))}

                >
                    <div id='fuel_economy'>
                        <div id='economy_values'>

                            <div id='combined'>
                                <div className='key_cont'>
                                    <p className='default_key'>{USE_ELEC ? 'Fuel Economy:' : 'Combined:'}</p>
                                    <Tooltip text={USE_ELEC ? TT['eco']['fuel_econ_elec'] : TT['eco']['fuel_econ']} />
                                </div>
                                <div className='key_val_cont'>
                                    <img src={USE_ELEC ? './assets/icons/ev_bolt.svg' : './assets/icons/mpg.svg'} alt='' />
                                    <p className={this.props.free_check ? 'blur' : ''}>{USE_ELEC ? `${parseInt(this.state.elec_whpm)} Wh/mi` : `${Math.floor(FE_THIS[2])} MPG`}</p>
                                </div>

                            </div>

                            {!USE_ELEC &&
                                <>
                                    {FE_THIS[0] > 0 && <div id='urban'>
                                        <div className='key_cont'>
                                            <p className='default_key'>Urban:</p>
                                        </div>
                                        <p className={this.props.free_check ? 'blur default_val' : 'default_val'}>{FE_THIS[0]} MPG</p>
                                    </div>}

                                    {FE_THIS[0] > 0 && FE_THIS[1] > 0 && <div className='sep'></div>}

                                    {FE_THIS[1] > 0 && <div id='extra_urban'>
                                        <div className='key_cont'>
                                            <p className='default_key'>Extra-urban:</p>
                                        </div>
                                        <p className={this.props.free_check ? 'blur default_val' : 'default_val'}>{FE_THIS[1]} MPG</p>

                                    </div>}
                                </>}

                        </div>


                        <div className='highcharts_cont'>
                            {!USE_ELEC &&
                                <ComparisonBar
                                    free_check={this.props.free_check}
                                    this_car={FE_THIS}
                                    avg={this.props.free_check ? [65, 70, 65] : this.state.fuel_economy.avg}
                                    worst={this.props.free_check ? [30, 50, 40] : this.state.fuel_economy.worst}
                                    type={`Euro ${this.state.euro_status} Vehicle`}
                                />}

                            {USE_ELEC &&
                                <ComparisonBarElec
                                    free_check={this.props.free_check}
                                    elec={parseInt(this.state.elec_whpm)}
                                />}
                        </div>
                    </div>
                </ReportFrame>

                <ReportFrame
                    title="Emissions"
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    nodata={!(POL.this_car[0] >= 0)}
                    col_classes={'1-1-2 2-2-1 3-2-1'}
                >

                    <div id="emissions">

                        <div id='emissions_main_row'>
                            {window.innerWidth >= 290 && <p className='default_key'>CO<sub>2</sub> emissions:</p>}
                            {window.innerWidth < 290 && <p className='default_key'>CO<sub>2</sub>"</p>}
                            <div className='key_val_cont'>
                                <img src='./assets/icons/co2.svg' alt='' />
                                <p>{POL.this_car[0]}g/km</p>
                            </div>
                        </div>

                        <EmissionsBar car={this.props.details} value={POL.this_car[0]} name={this.state.car_name} />
                        {!HIDE_POL && <>

                            <div className='emissions_row'>
                                <div className='row_key'>

                                    <p className='default_key'>CO</p>
                                    <Tooltip text={TT.eco.pollution['CO (mg/km)']} />
                                </div>
                                <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{this.props.free_check ? '??? mg/km' : CO}</p>
                            </div>

                            <div className='sep'></div>

                            <div className='emissions_row'>
                                <div className='row_key'>
                                    <p className='default_key'>THC</p>
                                    <Tooltip text={TT.eco.pollution['THC (mg/km)']} />
                                </div>
                                <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{this.props.free_check ? '??? mg/km' : THC}</p>
                            </div>

                            <div className='sep'></div>

                            <div className='emissions_row'>
                                <div className='row_key'>
                                    <p className='default_key'>Particulates</p>
                                    <Tooltip text={TT.eco.pollution['Particulates (mg/km)']} />
                                </div>
                                <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{this.props.free_check ? '??? mg/km' : PART}</p>
                            </div>

                            <div className='sep'></div>

                            <div className='emissions_row' style={{ marginBottom: '8px' }}>
                                <div className='row_key'>
                                    <p className='default_key'>NOx</p>
                                    <Tooltip text={TT.eco.pollution['NOx (mg/km)']} />
                                </div>
                                <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{this.props.free_check ? '??? mg/km' : NOX}</p>
                            </div>

                        </>}

                    </div>
                </ReportFrame>

                <Review free_check={this.props.free_check} class_name='1-1-3 2-1-4 3-1-3' avatar='3' text='The report was very informative and the website is very easy to use' />

                <ReportFrame
                    title="Eco Score"
                    display={true}
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    nodata={this.state.checks.drop_eco}
                    col_classes={'1-1-4 2-1-2 3-3-1'}
                >
                    <div id='eco_score'>

                        <div className='highcharts_cont'>
                            <MultiDonut
                                titles={CHART_INPUTS[0]}
                                values={CHART_INPUTS[1]}
                                overall={this.state.eco_score[this.state.active_score].overall}
                                free_check={this.props.free_check}
                            />
                        </div>

                        {!this.state.checks.drop_eco_switch &&
                            <div id="score_switcher">
                                <div className={this.state.active_score === "this_car" ? "active" : "inactive"} onClick={() => this.handle_eco_change('this_car')}>This vehicle</div>
                                <div className={this.state.active_score === "avg" ? "active" : "inactive"} onClick={() => this.handle_eco_change('avg')}>Avg. vehicle</div>
                            </div>}

                        <div className='sep'></div>

                        <div id='eco_list'>
                            <div id='eco_dropdown' onClick={this.handle_expand_eco}>
                                <p className='default_key'>View breakdown</p>
                                <img className='dropdown' alt='Dropdown icon' src='./assets/icons/expand.svg' />
                            </div>

                            <div id="eco_table" className='hide'>
                                {TABLE_INPUTS[0].map((item, i) => (
                                    <div className="eco_row" key={i}>
                                        <div className='eco_key'>
                                            <p className='default_key'>{TABLE_INPUTS[3][i] && !this.props.free_check ? `${item} ${window.innerWidth > 300 ? TABLE_INPUTS[3][i] : ''}` : `${item}`}</p>
                                            <Tooltip text={TT.eco.eco_score[item]} />
                                        </div>
                                        <div className='val_cont'>
                                            <p className={this.props.free_check ? `default_val blur` : `default_val ${TABLE_INPUTS[4][i]}`}><b>{this.props.free_check ? '??' : (TABLE_INPUTS[1][i] / 10).toFixed(1)}</b>{window.innerWidth > 300 && <span><i> / </i>10</span>}</p>
                                            {!this.props.free_check && <div className={`dot ${TABLE_INPUTS[4][i]}_dot`}></div>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </ReportFrame>

                <ReportFrame
                    title="LEZ Check"
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    col_classes={'1-1-5 2-2-2 3-1-2'}
                >
                    <div id="zone_check">
                        <div id='zone_header'>
                            <p className='default_key'>London ULEZ Check: </p>
                            <div className='key_val_cont'>
                                <img src={ZC['lndn'] === 'Pass' ? './assets/icons/tick.svg' : './assets/icons/cross.svg'} alt='GBP icon' />
                                <p className={this.props.free_check ? 'blur' : ''}>{this.props.free_check ? "Abcd" : ZC['lndn']}</p>
                            </div>
                        </div>

                        <div className="highcharts_cont">
                            <MapUK checks={this.state.zone_check} />
                        </div>

                        <div className='sep'></div>

                        <div id='zone_list'>
                            <div id='zone_dropdown' onClick={this.handle_expand_zone}>
                                <p className='default_key'>View breakdown</p>
                                <img className='dropdown' alt='Dropdown icon' src='./assets/icons/expand.svg' />
                            </div>

                            <div id="zone_table" className='hide'>
                                {ZC_ITEMS.map((item, i) => (
                                    <div className="zone_row" key={i}>
                                        <div className='zone_key'>
                                            <p className='default_key'>{FULLNAMES[i + 1]}</p>
                                            <Tooltip text={TT.eco.zones[FULLNAMES[i + 1]]} />
                                        </div>

                                        <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{this.props.free_check ? 'SAMPLE' : ZC[item]}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </ReportFrame>

                <ListAd free_check={this.props.free_check} class_name='1-1-6 2-1-5 3-1-4' />

                <ReportFrame
                    title="Green NCAP"
                    free_check={green_blur}
                    plate={this.props.plate}
                    col_classes={'1-1-7 2-1-3 3-2-2'}
                    nodata={GN.nodata}

                >
                    <div id="green_ncap">
                        <div id='ncap_summary'>
                            <div id='rating_cont'>
                                <div id='prefix'>
                                    <p>Green NCAP Rating:</p>
                                    {!this.props.free_check && <Tooltip text={'Green NCAP is an independent initiative which promotes the development of cars which are clean and energy efficient.'} />}
                                </div>
                                <div className='key_val_cont'>
                                    <img alt='' src={'./assets/icons/star_green.svg'} />
                                    <p className={this.props.free_check ? ' blur_green' : ''}>{`${GN.overall.toFixed(1)} / 5`}</p>
                                </div>
                            </div>
                        </div>

                        <div id='ncap_details'>
                            <div className='detail_item'>
                                <div className='ncap_text'>
                                    <p>Clean Air</p>
                                    <p className={this.props.free_check ? 'details_rating blur' : 'details_rating'}>{GN.clean_air} <span>/ 10</span></p>
                                </div>

                                <div className='ncap_bar'>
                                    <div className='ncap_bar_inner' style={{ width: `${GN.clean_air * 10}%` }}></div>
                                </div>
                            </div>

                            <div className='detail_item'>
                                <div className='ncap_text'>
                                    <p>Energy Efficiency</p>
                                    <p className={this.props.free_check ? 'details_rating blur' : 'details_rating'}>{GN.efficiency} <span>/ 10</span></p>
                                </div>

                                <div className='ncap_bar'>
                                    <div className='ncap_bar_inner' style={{ width: `${(GN.efficiency <= 10 ? GN.efficiency * 10 : 60)}%` }}></div>
                                </div>
                            </div>

                            <div className='detail_item'>
                                <div className='ncap_text'>
                                    <p>Grenhouse Gas</p>
                                    <p className={this.props.free_check ? 'details_rating blur' : 'details_rating'}>{GN.greenhouse} <span>/ 10</span></p>
                                </div>

                                <div className='ncap_bar'>
                                    <div className='ncap_bar_inner' style={{ width: `${GN.greenhouse * 10}%` }}></div>
                                </div>
                            </div>
                        </div>

                    </div>

                </ReportFrame>

                <ReportFrame
                    nodata={!this.state.show_noise}
                    title="Noise"
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    col_classes={'1-1-8 2-2-3 3-3-2'}

                >
                    <div id="noise">

                        <div id='noise_main_row'>
                            <p className='default_key'>Noise level for this car:</p>
                            <div className='key_val_cont'>
                                <img src='./assets/icons/speaker.svg' alt='' />
                                <p className={this.props.free_check ? 'blur' : ''}>{this.props.free_check ? '00' : this.state.noise.this_car}dB</p>
                            </div>
                        </div>

                        <div className='noise_row'>
                            <p className='default_key'>The quietest car:</p>
                            <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{this.props.free_check ? '00' : NOISE_GEN.quiet} dB</p>
                        </div>

                        <div className='sep'></div>

                        <div className='noise_row'>
                            <p className='default_key'>The average car:</p>
                            <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{this.props.free_check ? '00' : NOISE_GEN.avg} dB</p>
                        </div>

                        <div className='sep'></div>

                        <div className='noise_row' style={{ marginBottom: '8px' }}>
                            <p className='default_key'>The loudest car:</p>
                            <p className={this.props.free_check ? 'default_val blur' : 'default_val'}>{this.props.free_check ? '00' : NOISE_GEN.loud} dB</p>
                        </div>


                    </div>
                </ReportFrame >
            </div >
        )
    }
}
EcoImpact.displayName = 'Eco-Impact'

export default EcoImpact