import "../../styles/views/docs.scss";

import React, { Component } from 'react';

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import CookiePopup from '../../components/CookiePopup'

import { Helmet, HelmetProvider } from 'react-helmet-async';

class Cookies extends Component {


    render() {
        return (
            <HelmetProvider>

                <Helmet>
                    <title>Cookie Policy | Auto Reg Check</title>
                    <link rel="canonical" href="https://www.autoregcheck.co.uk/cookies-policy/" />
                </Helmet>
                <main id="main_doc">
                    <Header />
                    <section>
                        <iframe title="Cookie Policy" className="cookies" src="https://docs.google.com/document/d/e/2PACX-1vQ1cQNPyDXmsMm9o65JU84Jy2N1dY_Z3FW7V2YYRNZxEbWMu3u1Cfy49KLGN7sekGTTYpw0EMZ_XGEY/pub?embedded=true"></iframe>
                    </section>
                    <Footer />
                    <CookiePopup />
                </main>
            </HelmetProvider>

        )
    }
}

export default Cookies;
