import '../../styles/components/graphs/multi_donut.scss';

import React, { Component } from "react";

import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import HC_access from "highcharts/modules/accessibility";

HC_access(Highcharts);
HC_more(Highcharts);

require("highcharts/modules/solid-gauge")(Highcharts);

class MultiDonut extends Component {
    chartRef = React.createRef();

    componentDidMount() {
        this.chartRef.current.chart.reflow()
    }

    render() {
        const VALS = this.props.values
        const OVERALL = this.props.overall

        let W = window.innerWidth
        let H = ''
        if (W >= 1240) {
            H = '100%'
        } else if (W >= 800) {
            H = '60%'
        } else if (W >= 700) {
            H = '80%'
        } else if (W >= 599) {
            H = "100%"
        } else if (W >= 400) {
            H = "75%"
        }else{
            H = "100%"

        }

        let easeInOutQuart = function (pos) {
            if ((pos /= 0.5) < 1) return 0.5 * Math.pow(pos, 4);
            return -0.5 * ((pos -= 2) * Math.pow(pos, 3) - 2);
        };

        Math.easeInOutQuart = easeInOutQuart;

        const label = this.props.free_check ? `<div class="blur" id="label"><img src='./assets/icons/eco_score.svg' alt='' /><p>${OVERALL/10}/10</p></div>` : `<div id="label"><img src='./assets/icons/eco_score.svg' alt='' /><p>${OVERALL/10}/10</p></div>`

        function renderIcons() {
            setTimeout(() => {
                if (this.titleText) {
                    this.titleText.destroy()
                }
    
                this.titleText = this.renderer.html(
                    label,
                    this.chartWidth / 2,
                    this.chartHeight / 2 - 8 
                )
                .attr({
                    'stroke-width': 0,
                    zIndex: 10,
                    align: 'center',
                    padding: 0,
                })
                .add();
            }, 200);
            
        }


        const options = {
            chart: {
                type: 'solidgauge',
                events: {
                    load: renderIcons,
                    redraw: renderIcons
                },
                height: H,
                margin: 0,
                marginTop: -10,
                marginBottom: -10,
                animation: {
                    duration: 700,
                    easing: 'easeInOutQuart'
                },
                style: {
                    fontFamily: 'Nunito Sans, sans-serif' // Use 'Nunito Sans' with 'sans-serif' as a fallback
                }
            },

            title: { text: null },
            credits: { enabled: false },

            tooltip: {
                enabled: false
            },

            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [{
                    outerRadius: '100%',
                    innerRadius: '92.5%',
                    backgroundColor: Highcharts.color('rgb(41, 52, 143)').setOpacity(0.25).get(),
                    borderWidth: 0
                }, {
                    outerRadius: '92.5%',
                    innerRadius: '85%',
                    backgroundColor: Highcharts.color('rgb(52, 94, 163)').setOpacity(0.25).get(),
                    borderWidth: 0
                }, {
                    outerRadius: '85%',
                    innerRadius: '77.5%',
                    backgroundColor: Highcharts.color('rgb(70, 143, 188)').setOpacity(0.25).get(),
                    borderWidth: 0
                }, {
                    outerRadius: '77.5%',
                    innerRadius: '70%',
                    backgroundColor: Highcharts.color('rgb(100, 181, 195)').setOpacity(0.25).get(),
                    borderWidth: 0
                }, {
                    outerRadius: '70%',
                    innerRadius: '62.5%',
                    backgroundColor: Highcharts.color('#rgb(146, 203, 188)').setOpacity(0.25).get(),
                    borderWidth: 0
                }, {
                    outerRadius: '62.5%',
                    innerRadius: '55%',
                    backgroundColor: Highcharts.color('rgb(205, 232, 184)').setOpacity(0.25).get(),
                    borderWidth: 0
                }, {
                    outerRadius: '55%',
                    innerRadius: '47.5%',
                    backgroundColor: Highcharts.color('rgb(239, 248, 186)').setOpacity(0.25).get(),
                    borderWidth: 0
                }]
            },

            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: []
            },

            plotOptions: {
                series: {
                    states: {
                        enableMouseTracking: false,
                        hover: {
                            enabled: false
                        }
                    }
                },
                solidgauge: {
                    dataLabels: { enabled: false },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true
                }
            },

            series: [{
                name: this.props.titles[0],
                data: [{ color: 'rgb(41, 52, 143)', radius: '100%', innerRadius: '92.5%', y: VALS[0] }]
            }, {
                name: this.props.titles[1],
                data: [{ color: 'rgb(52, 94, 163)', radius: '92.5%', innerRadius: '85%', y: VALS[1] }]
            }, {
                name: this.props.titles[2],
                data: [{ color: 'rgb(70, 143, 188)', radius: '85%', innerRadius: '77.5%', y: VALS[2] }]
            }, {
                name: this.props.titles[3],
                data: [{ color: 'rgb(100, 188, 195)', radius: '77.5%', innerRadius: '70%', y: VALS[3] }]
            }, {
                name: this.props.titles[4],
                data: [{ color: 'rgb(146, 203, 188)', radius: '70%', innerRadius: '62.5%', y: VALS[4] }]
            }, {
                name: this.props.titles[5],
                data: [{ color: 'rgb(205, 232, 184)', radius: '62.5%', innerRadius: '55%', y: VALS[5] }]
            }, {
                name: this.props.titles[6],
                data: [{ color: 'rgb(239, 248, 186)', radius: '55%', innerRadius: '47.5%', y: VALS[6] }]
            }]
        }

        return (
            <div className="multi_donut">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={this.chartRef}
                />
            </div>
        );
    }
}
export default MultiDonut;