import '../../styles/components/report_panels/history.scss'
import { HISTORY_METADATA, ALL_TITLES, get_history_data, set_history_status, get_relevant_data } from '../../utils'
import { TT } from '../../tooltips'
import React, { Component } from "react"
import FreeCheckCTA from '../cards/FreeCheckCTA'

import ReportFrame from "../ReportFrame"
import Tooltip from '../Tooltip'

let loaded = false

class History extends Component {
    constructor(props) {
        super(props)
        this.state = {

            all_statuses: {
                finance: { status: "warn", marker: "No Data", data: [] },
                stolen: { status: "warn", marker: "No Data", data: [] },
                scrapped: { status: "warn", marker: "No Data", data: [] },
                vic: { status: "warn", marker: "No Data", data: [] },
                imported: { status: "warn", marker: "No Data", data: [] },
                exported: { status: "warn", marker: "No Data", data: [] },
                plate: { status: "warn", marker: "No Data", data: [] },
                mileage: { status: "warn", marker: "No Data", data: [] },
                written_off: { status: "warn", marker: "No Data", data: [] },
                high_risk: { status: "warn", marker: "No Data", data: [] },
                colour: { status: "warn", marker: "No Data", data: [] },
                recalls: { status: "warn", marker: "No Data", data: [] },
            },
        }
    }

    filter_props_data = (data) => {

        let metadata_history = get_relevant_data(data.api_metadata, 'H')

        let filtered_data_history = {}
        for (let m of metadata_history) {
            m['value'] = data.api_response[m['item_name']]
            filtered_data_history[m['item_name']] = m
        }

        filtered_data_history['n_recalls'] = data.recalls.length
        filtered_data_history['recalls_list'] = { value: data.recalls }
        return filtered_data_history
    }

    componentDidUpdate() {
        if (!loaded & Object.values(this.props.data).length > 0) {
            this.set_all_data()
        }
    }

    componentDidMount() {
        if (Object.values(this.props.data).length > 0) {
            this.set_all_data()
            if (this.props.hist_check) {
                let item_name = HISTORY_METADATA[this.props.hist_check].item_name
                let icon = document.getElementById(`icon_${item_name}`)
                if (icon) {
                    this.handle_check_expand(icon, false, false)
                    icon.scrollIntoView({ block: "center", inline: "nearest" })
                }
            }
        }
    }

    set_all_data = () => {
        try {
            let statuses = {}
            let titles = []
            let all_data = this.filter_props_data(this.props.data)

            if (this.props.free_check) {
                statuses = {
                    finance: { status: "nodata", marker: "Unlock" },
                    stolen: { status: "nodata", marker: "Unlock" },
                    scrapped: { status: "nodata", marker: "Unlock" },
                    vic: { status: "nodata", marker: "Unlock" },
                    imported: { status: "nodata", marker: "Unlock" },
                    plate: { status: "nodata", marker: "Unlock" },
                    mileage: { status: "nodata", marker: "Unlock" },
                    written_off: { status: "nodata", marker: "Unlock" },
                    high_risk: { status: "nodata", marker: "Unlock" },
                    colour: { status: "nodata", marker: "Unlock" },
                }
                statuses.exported = this.props.data.free_check_results.export ? { status: "fail", marker: "Fail" } : { status: "pass", marker: "Pass" }
                statuses.recalls = this.props.data.free_check_results.recall ? { status: "fail", marker: "Fail" } : { status: "pass", marker: "Pass" }

                titles = ['Exported', 'Recalls']
            } else {
                statuses = set_history_status(all_data)
                titles = ALL_TITLES
            }

            for (let title of titles) {
                let data = get_history_data(all_data, title, false)
                statuses[HISTORY_METADATA[title].item_name]['data'] = data
            }

            this.setState({ all_statuses: statuses })

            loaded = true
        } catch (error) {
            console.error(error)
        }
    }

    get_data = (title) => {
        return this.state.all_statuses[HISTORY_METADATA[title].item_name]['data']
    }

    handle_check_expand = (e, free_check, is_not_elem) => {
        if (free_check) {
            window.location.href = `/buy?count=1&plate1=${this.props.plate}`
        } else {

            let elem = is_not_elem ? e.currentTarget.querySelectorAll('.img_expand')[0] : e

            if(is_not_elem){
                let tgt = e.target
                if(tgt.classList.contains('full_title') || tgt.classList.contains('tt_elem') || tgt.classList.contains('title_cont')){
                    return
                }
            }
            
            
            if (elem.style.transform !== 'rotate(45deg)') {
                elem.style.transform = 'rotate(45deg)'
            } else {
                elem.style.transform = 'none'
            }

            let parent = elem.parentNode.parentNode.parentNode
            parent.querySelectorAll('.content')[0].classList.toggle('hide')
        }

    }

    get_status_or_marker = (title, attr) => {
        const ITEM = HISTORY_METADATA[title].item_name
        return this.state.all_statuses[ITEM][attr]
    }

    handle_recall_expand = (elem) => {

        let div = elem.target.tagName === "DIV" ? elem.target : elem.target.parentNode

        if (div.children[0].innerHTML === "Show Details") {
            div.children[0].innerHTML = "Hide Details"
            div.children[1].style.transform = 'rotate(45deg)'
        } else {
            div.children[0].innerHTML = "Show Details"
            div.children[1].style.transform = 'none'
        }

        div.parentNode.parentNode.querySelectorAll('.row_cont')[0].classList.toggle('active')

    }

    handle_read_more = () => {
        let text = document.getElementById('desc');

        text.classList.toggle('trunc')

        let read_text = document.getElementById('read_more')
        if (read_text.innerHTML === 'Read more') {
            read_text.innerHTML = "Read less"
        } else {
            read_text.innerHTML = "Read more"
        }
    }

    render() {
        const STATUSES = this.state.all_statuses

        let history_alerts = 0
        for (const key in STATUSES) {
            if (STATUSES.hasOwnProperty(key) && STATUSES[key].status === 'fail') {
                history_alerts++;
            }
        }

        let full_check_titles = this.props.free_check ? ["Written-off", "Stolen", "Scrapped", "Mileage Issue", "Finance", "Plate Change", "High Risk", "Imported", "Colour Change", "VIC"] : ALL_TITLES
        let free_check_titles = ['Exported', 'Recalls']

        return (
            <div className="report_panel" id='history_panel'>
                {this.props.free_check && <FreeCheckCTA plate={this.props.plate} title={"Avoid nasty surprises"} text="Get the full history for this car and ensure there are no hidden secrets" img={'./assets/CTA/history.webp'} />}

                <div className='history_columns'>
                    {this.props.free_check && <ReportFrame
                        alert={history_alerts > 0}
                        title={'Free History Check'}
                        plate={this.props.plate}
                        free_check={false}
                    >

                        <div className="history_table">
                            {free_check_titles.map((title, index) => (
                                <div key={index}>
                                    <div className={`header_row ${this.get_status_or_marker(title, 'status')}`} onClick={(e) => { this.handle_check_expand(e, false, true) }}>
                                        <div className='title_cont'>
                                            <p className="full_title">{HISTORY_METADATA[title]['home_title']}</p>
                                            <Tooltip text={TT.his[title]} />
                                        </div>

                                        <div className="marker_container">
                                            {this.props.free_check && title !== 'Exported' && title !== "Recalls" && <div className='free_check'></div>}
                                            <div className={this.props.free_check && title !== 'Exported' && title !== "Recalls" ? "full_marker full_marker_nodata" : `full_marker full_marker_${this.get_status_or_marker(title, "status")}`}>
                                                {!(this.props.free_check && title !== 'Exported' && title !== "Recalls") && <p>{this.get_status_or_marker(title, "marker")}</p>}
                                                <img className='marker_img' alt="" src={this.props.free_check && title !== 'Exported' && title !== "Recalls" ? "./assets/icons/marker_warn_v2.svg" : `./assets/icons/marker_${this.get_status_or_marker(title, "status")}_v2.svg`} />
                                            </div>

                                            <img
                                                alt=""
                                                src='./assets/icons/expand.svg'
                                                className='img_expand'
                                            />

                                        </div>
                                    </div>

                                    <div className='content hide'>

                                        {this.get_status_or_marker(title, 'status') === 'pass' && <div className='pass_card'><p>No Records</p></div>}

                                        {this.get_status_or_marker(title, 'status') === 'fail' && !this.props.free_check &&
                                            <div className="fail_data">
                                                {(this.get_data(title).length <= 5 || title !== "Recalls") && this.get_data(title).map((entry, i) => (
                                                    <div key={i} className="entry_cont">

                                                        <div className="entry_header">
                                                            <h3>{`Record ${i + 1} of ${this.get_data(title).length}`}</h3>
                                                            <div className="sep"></div>
                                                        </div>


                                                        {entry.map((item, j) => (
                                                            <div key={j} className="details_row">
                                                                <div className='details_key'>
                                                                    <p className="default_key">{item.name.replace("Vrm", "VRM")}</p>
                                                                    {
                                                                        TT.his[`${title}_items`] && TT.his[`${title}_items`][item.name] &&
                                                                        <Tooltip text={TT.his[`${title}_items`][item.name]} override={true} />
                                                                    }
                                                                </div>
                                                                <p className="default_val">{item.value ? item.value : "N/A"}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}

                                                {(this.get_data(title).length > 5 && title === "Recalls") &&
                                                    this.get_data(title).map((entry, i) => (
                                                        <div key={i} className="entry_cont_dropdown">
                                                            <div className="entry_header">
                                                                <h3>{`Record ${i + 1} of ${this.get_data(title).length}`}</h3>

                                                                <div className='expand' onClick={this.handle_recall_expand}>
                                                                    <p>Show Details</p>
                                                                    <img src="./assets/icons/expand.svg" className='img_expand' alt="expand" />
                                                                </div>
                                                            </div>

                                                            <div className='row_cont'>
                                                                {entry.map((item, j) => (
                                                                    <div key={j} className={item.name !== 'Concern' && item.name !== 'Remedy' ? "details_row" : "details_row stack"}>
                                                                        <div className='details_key'>
                                                                            <p className="default_key">{item.name}</p>
                                                                            {
                                                                                TT.his[`${title}_items`] && TT.his[`${title}_items`][item.name] &&
                                                                                <Tooltip text={TT.his[`${title}_items`][item.name]} override={true} />
                                                                            }
                                                                        </div>
                                                                        <p className="default_val">{item.value ? item.value : "N/A"}</p>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                        </div>
                                                    ))}

                                            </div>
                                        }
                                        {this.get_status_or_marker(title, 'status') === 'fail' && this.props.free_check &&
                                            <div className='free_check_fail'>
                                                <div className='premium_card'>
                                                    <p className='footer_title'>Unlock additional information for £4.99</p>
                                                    <p>Reveal this vehicle’s complete history</p>
                                                    <md-filled-button href={`/buy?count=1&plate1=${this.props.plate}`} id='footer_btn'>Upgrade now</md-filled-button>
                                                </div>
                                            </div>}
                                    </div>

                                    {index < free_check_titles.length - 1 && <div className='sep'></div>}
                                </div>

                            ))}
                        </div>
                    </ReportFrame>}

                    <ReportFrame
                        alert={history_alerts > 0 && !this.props.free_check}
                        title={this.props.free_check ? "Full Check" : "Full History Check"}
                        plate={this.props.plate}
                        free_check={this.props.free_check}
                    >

                        <div className="history_table">
                            {full_check_titles.map((title, index) => (
                                <div key={index}>
                                    <div
                                        className={`header_row ${this.get_status_or_marker(title, 'status')}`}
                                        onClick={(e) => { this.handle_check_expand(e, this.props.free_check && (title !== 'Exported' && title !== "Recalls"), true) }}>
                                        <div className='title_cont'>
                                            <p className="full_title">{HISTORY_METADATA[title]['home_title']}</p>
                                            <Tooltip text={TT.his[title]} />
                                        </div>

                                        <div className="marker_container">
                                            {this.props.free_check && title !== 'Exported' && title !== "Recalls" && <div className='free_check'></div>}
                                            <div className={this.props.free_check && title !== 'Exported' && title !== "Recalls" ? "full_marker full_marker_nodata" : `full_marker full_marker_${this.get_status_or_marker(title, "status")}`}>
                                                {!(this.props.free_check && title !== 'Exported' && title !== "Recalls") && <p>{this.get_status_or_marker(title, "marker")}</p>}
                                                <img className='marker_img' alt="" src={this.props.free_check && title !== 'Exported' && title !== "Recalls" ? "./assets/icons/marker_warn_v2.svg" : `./assets/icons/marker_${this.get_status_or_marker(title, "status")}_v2.svg`} />
                                            </div>

                                            <img
                                                alt=""
                                                id={`icon_${HISTORY_METADATA[title]['item_name']}`}
                                                src={!this.props.free_check || title === 'Exported' || title === "Recalls" ? './assets/icons/expand.svg' : './assets/icons/right_arrow_full.svg'}
                                                className='img_expand'

                                            />

                                        </div>
                                    </div>

                                    <div className='content hide'>

                                        {this.get_status_or_marker(title, 'status') === 'pass' && <div className='pass_card'><p>No Records</p></div>}

                                        {this.get_status_or_marker(title, 'status') === 'fail' && !this.props.free_check &&
                                            <div className="fail_data">
                                                {(this.get_data(title).length <= 5 || title !== "Recalls") && this.get_data(title).map((entry, i) => (
                                                    <div key={i} className="entry_cont">

                                                        <div className="entry_header">
                                                            <h3>{`Record ${i + 1} of ${this.get_data(title).length}`}</h3>
                                                            <div className="sep"></div>
                                                        </div>


                                                        {entry.map((item, j) => (
                                                            <div key={j} className="details_row">
                                                                <div className='details_key'>
                                                                    <p className="default_key">{item.name.replace("Vrm", "VRM")}</p>
                                                                    {
                                                                        TT.his[`${title}_items`] && TT.his[`${title}_items`][item.name] &&
                                                                        <Tooltip text={TT.his[`${title}_items`][item.name]} override={true} />
                                                                    }
                                                                </div>
                                                                <p className="default_val">{item.value ? item.value : "N/A"}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}

                                                {(this.get_data(title).length > 5 && title === "Recalls") &&
                                                    this.get_data(title).map((entry, i) => (
                                                        <div key={i} className="entry_cont_dropdown">
                                                            <div className="entry_header">
                                                                <h3>{`Record ${i + 1} of ${this.get_data(title).length}`}</h3>

                                                                <div className='expand' onClick={this.handle_recall_expand}>
                                                                    <p>Show Details</p>
                                                                    <img src="./assets/icons/expand.svg" className='img_expand' alt="expand" />
                                                                </div>
                                                            </div>

                                                            <div className='row_cont'>
                                                                {entry.map((item, j) => (
                                                                    <div key={j} className={item.name !== 'Concern' && item.name !== 'Remedy' ? "details_row" : "details_row stack"}>
                                                                        <div className='details_key'>
                                                                            <p className="default_key">{item.name}</p>
                                                                            {
                                                                                TT.his[`${title}_items`] && TT.his[`${title}_items`][item.name] &&
                                                                                <Tooltip text={TT.his[`${title}_items`][item.name]} override={true} />
                                                                            }
                                                                        </div>
                                                                        <p className="default_val">{item.value ? item.value : "N/A"}</p>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                        </div>
                                                    ))}

                                            </div>
                                        }
                                        {this.get_status_or_marker(title, 'status') === 'fail' && this.props.free_check &&
                                            <div className='free_check_fail'>
                                                <div className='premium_card'>
                                                    <p className='footer_title'>Unlock additional information for £4.99</p>
                                                    <p>Reveal this vehicle’s complete history</p>
                                                    <md-filled-button href={`/buy?count=1&plate1=${this.props.plate}`} id='footer_btn'>Upgrade now</md-filled-button>
                                                </div>
                                            </div>}
                                    </div>

                                    {index < full_check_titles.length - 1 && <div className='sep'></div>}
                                </div>

                            ))}
                        </div>
                    </ReportFrame>
                </div>


            </div >
        )
    }
}

History.displayName = 'Checks'

export default History