import "../../styles/views/errors/503.scss";

import React, { Component } from 'react';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import CookiePopup from '../../components/CookiePopup'
import { Helmet, HelmetProvider } from 'react-helmet-async';

class Maintenance extends Component {
    
    componentDidMount(){
        document.getElementById("root").style.height = `${window.screen.height + 45}px`
        document.getElementById("main_503").style.height = `${window.screen.height + 45}px`
    }

    render() {
        return (
            <HelmetProvider>

            <Helmet>
                <title>Down For Maintenance | Auto Reg Check</title>
                <link rel="canonical" href="https://www.autoregcheck.co.uk/maintenance/" />
            </Helmet>
            <main id="main_503">
                <Header />
                <section>
                    <div>
                        <h1>503 error</h1>
                        <h2>Down for maintenance</h2>
                        <p>Sorry, this page is currently unavailable due to maintenance. Please try again later.</p>
                        <button onClick={() => { window.location.href = "/" }}>Return home</button>
                    </div>

                    <img alt="A maintenance graphic" src="./assets/errors/503.svg" />
                </section>
                <Footer />
                <CookiePopup />
            </main>
            </HelmetProvider>

        )
    }
}

export default Maintenance;
