import "../styles/views/pricing.scss";

import React, { Component } from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'
import { PRICING, remove_listeners, clean_plate } from "../utils";
import CookiePopup from '../components/CookiePopup'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import '@material/web/button/filled-button.js';
import Breadcrumb from "../components/Breadcrumb";

class Homepage extends Component {

    componentDidMount() {
        this.set_enter_plate([1, 2, 3], false)
    }

    set_enter_plate = (btn_ids, to_submit) => {
        for (let id of btn_ids) {
            remove_listeners(`btn_${id}`)

            if (to_submit) {
                document.getElementById(`btn_${id}`).addEventListener('click', () => { this.handle_submit(id) })
                document.getElementById(`btn_${id}`).innerHTML = 'Start Check'

            } else {
                document.getElementById(`inputs_${id}`).classList.add('hide')
                document.getElementById(`btn_${id}`).addEventListener('click', () => { this.handle_select_plate(id) })
                document.getElementById(`btn_${id}`).innerHTML = 'Enter Plates'
            }
        }
    }

    handle_submit = (id) => {
        let plates = []
        switch (id) {
            case 1:
                plates.push(clean_plate('1a'))
                break
            case 2:
                plates.push(clean_plate('2a'))
                plates.push(clean_plate('2b'))
                break
            case 3:
                plates.push(clean_plate('3a'))
                plates.push(clean_plate('3b'))
                plates.push(clean_plate('3c'))
                break
            default:
                break
        }

        plates = plates.filter(n => n)

        if (plates.length === id) {
            let url = `/buy?count=${id}`
            for (let i = 0; i < plates.length; i++) {
                url += `&plate${i + 1}=${plates[i]}`
            }
            window.location.href = url
        }
    }

    handle_select_plate = (id) => {

        document.getElementById(`inputs_${id}`).classList.remove('hide')
        this.set_enter_plate([id], true)

        switch (id) {
            case 1:
                this.set_enter_plate([2, 3], false)
                break
            case 2:
                this.set_enter_plate([1, 3], false)
                break
            case 3:
                this.set_enter_plate([1, 2], false)
                break
            default: 
                break
        }
    }

    render() {
        const breadcrumbs = [
            {name: "Home", item: "https://www.autoregcheck.co.uk/"},
            {name: "Pricing", item: "https://www.autoregcheck.co.uk/pricing"}
        ]

        return (

            <HelmetProvider>
                <Helmet>
                    <title>Pricing | Auto Reg Check</title>
                    <meta name="description" content="Auto Reg Check: Transparent pricing for comprehensive car checks. Affordable rates for peace of mind. Start now and stay informed!" />
                    <link rel="canonical" href="https://www.autoregcheck.co.uk/pricing/" />
                </Helmet>

                <Breadcrumb breadcrumbs={breadcrumbs} />

                <main id="main_pricing">
                    <Header active_nav={'pricing'} show_banner={true} />

                    <section id="pricing">
                        <h1>Pricing</h1>

                        <div id="pricing_cards">
                            <div className="card">
                                <div className="quantity">
                                    <div className="marker">
                                        <p>1x</p>
                                    </div>
                                    <div><h2>One check</h2></div>
                                </div>
                                <img src='./assets/pricing/one_check.svg' draggable='false' alt='A woman selecting one car check on a computer screen' />
                                <p className="price">{PRICING[0].total}</p>
                                <div className='discount'>
                                </div>

                                <div className="inputs hide" id='inputs_1'>
                                    <input className="plate_input" id='1a' placeholder="Enter your reg..." maxLength="10" />
                                </div>

                                <md-filled-button id='btn_1'>Enter plate</md-filled-button>

                            </div>

                            <div className="card">
                                <div className="quantity">
                                    <div className="marker">
                                        <p>2x</p>
                                    </div>
                                    <div><h2>Two checks</h2></div>
                                </div>

                                <img src='./assets/pricing/two_checks.svg' draggable='false' alt='A woman selecting two car checks on a computer screen' />

                                <p className="price">{PRICING[1].total}</p>
                                <div className='discount'>
                                    <p className="prev">{PRICING[1].price}</p>
                                    <p className="perc">{PRICING[1].discount_perc_short}</p>
                                </div>

                                <div className="inputs hide" id='inputs_2'>
                                    <input className="plate_input" id='2a' placeholder="Enter your reg..." maxLength="10" />
                                    <input className="plate_input" id='2b' placeholder="Enter your reg..." maxLength="10" />
                                </div>

                                <md-filled-button id='btn_2'>Enter plates</md-filled-button>

                            </div>

                            <div className="card">
                                <div className="quantity">
                                    <div className="marker">
                                        <p>3x</p>
                                    </div>
                                    <div><h2>Three checks</h2></div>
                                </div>

                                <img src='./assets/pricing/three_checks.svg' draggable='false' alt='A woman selecting three car checks on a computer screen' />

                                <p className="price">{PRICING[2].total}</p>
                                <div className='discount'>
                                    <p className="prev">{PRICING[2].price}</p>
                                    <p className="perc">{PRICING[2].discount_perc_short}</p>
                                </div>

                                <div className="inputs hide" id='inputs_3'>
                                    <input className="plate_input" id='3a' placeholder="Enter your reg..." maxLength="10" />
                                    <input className="plate_input" id='3b' placeholder="Enter your reg..." maxLength="10" />
                                    <input className="plate_input" id='3c' placeholder="Enter your reg..." maxLength="10" />
                                </div>

                                <md-filled-button id='btn_3'>Enter plates</md-filled-button>

                            </div>
                        </div>

                    </section>

                    <section id="why_choose_us">
                        <div id='why_inner'>
                            <h2>Why choose us?</h2>
                            <div id='why_content'>
                                <p id='why_text'>
                                    When it comes to purchasing a vehicle, accurate and reliable information is crucial for making an informed decision. With a multitude of auto reg checker services available, it can be challenging to find one that truly serves your needs.
                                    <br /><br />That's where Auto Reg Check comes in – a new service that offers a unique blend of innovative data-engineering, immersive design, and unprecedented levels of information.
                                </p>
                                <div id='list'>
                                    <div className="list_item">
                                        <div className="marker"><p>1</p></div>
                                        <p>Try our free car check before you buy</p>
                                        <img src='./assets/icons/small_check.svg' draggable='false' alt='A checkbox' />
                                    </div>

                                    <div className="list_item">
                                        <div className="marker"><p>2</p></div>
                                        <p>Comprehensive info from multiple sources</p>
                                        <img src='./assets/icons/small_check.svg' draggable='false' alt='A checkbox' />
                                    </div>

                                    <div className="list_item">
                                        <div className="marker"><p>3</p></div>
                                        <p>Innovative environmental insights</p>
                                        <img src='./assets/icons/small_check.svg' draggable='false' alt='A checkbox' />
                                    </div>

                                    <div className="list_item">
                                        <div className="marker"><p>4</p></div>
                                        <p>Transparent, competitive pricing</p>
                                        <img src='./assets/icons/small_check.svg' draggable='false' alt='A checkbox' />
                                    </div>

                                    <div className="list_item">
                                        <div className="marker"><p>5</p></div>
                                        <p>An intuitive, user-friendly platform</p>
                                        <img src='./assets/icons/small_check.svg' draggable='false' alt='A checkbox' />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </section>

                    <Footer show_input={true}/>
                    <CookiePopup />
                </main>
            </HelmetProvider>

        )
    }
}

export default Homepage;
