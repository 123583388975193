import '../../styles/components/report_panels/overview.scss'
import { ALL_TITLES, HISTORY_METADATA, set_history_status, normalise_date, get_relevant_data, move_items } from '../../utils'
import { TT } from '../../tooltips'

import React, { Component } from "react"

import MiniDonutGuage from '../graphs/MiniDonutGuage'
import ReportFrame from "../ReportFrame"
import Tooltip from '../Tooltip'
import FreeCheckCTA from '../cards/FreeCheckCTA'
import Review from '../cards/Review'
import ListAd from '../cards/ListAd'

let loaded = false

const FLAGS = ["canada", "china", "england", "france", "gb", "germany", "india", "italy", "japan", "korea", "mexico", "spain", "us", 'slovakia', 'romania', 'poland']

const NCAP_COMMENTS = [
    "Meeting type-approval standards so can legally be sold but lacking critical modern safety technology",
    "Marginal crash protection and little in the way of crash avoidance technology",
    "Nominal crash protection but lacking crash avoidance technology",
    "At least average occupant protection but not always equipped with the latest crash avoidance features",
    "Overall good performance in crash protection and all round; additional crash avoidance technology may be present",
    "Overall excellent performance in crash protection and well equipped with comprehensive and robust crash avoidance technology",
]

class Overview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            car_img: "",
            show_img_note: false,
            smmt_model: "",
            show_ncap: true,
            mot_status: 'fail',
            tax_status: 'fail',
            car_details: {
                plate: "",
                make: "",
                model: "",
                year: 0,
                colour: "",
                fuel_type: "",
                fuel_size: ""
            },
            report_details: {
                logo: "",
                ref: "",
                date: "",
                completeness_max: 0,
                completeness_actual: 0,
            },
            history_statuses: {
                finance: { status: "warn", marker: "No Data" },
                stolen: { status: "warn", marker: "No Data" },
                scrapped: { status: "warn", marker: "No Data" },
                vic: { status: "warn", marker: "No Data" },
                imported: { status: "warn", marker: "No Data" },
                exported: { status: "warn", marker: "No Data" },
                plate: { status: "warn", marker: "No Data" },
                mileage: { status: "warn", marker: "No Data" },
                written_off: { status: "warn", marker: "No Data" },
                high_risk: { status: "warn", marker: "No Data" },
                colour: { status: "warn", marker: "No Data" },
                recalls: { status: "warn", marker: "No Data" },
            },
            identification: {
                vin: 0,
                engine: "",
                V5C_no: 0,
                V5C_date: ""
            },
            ncap: {
                name: "",
                year: 0,
                url: "",
                rating: 0,
                adult: "",
                child: "",
                ped: "",
                assist: ""
            },
            timeline: {
                keeper_dates: [],
                country_origin: "",
                date_reg: "",
                origin_logo: "",
                data: []
            },
            current_col: 0
        }
    }

    componentDidUpdate() {
        if (!loaded & Object.values(this.props.data).length > 0) {
            this.set_state(this.props.data)
            if (this.props.free_check) {
                this.set_free_check(this.props.data)
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        if (Object.values(this.props.data).length > 0) {
            this.set_state(this.props.data)
            if (this.props.free_check) {
                this.set_free_check(this.props.data)
            }
        }
        loaded = false

        this.organise_columns()
        window.addEventListener('resize', this.organise_columns)
    }

    set_free_check = (data) => {

        let exported = { status: "nodata", marker: "No Data" }
        let recalls = { status: "nodata", marker: "No Data" }

        if (data.free_check_results.recall === false) {
            recalls = { status: "pass", marker: "Pass" }
        } else if (data.free_check_results.recall === true) {
            recalls = { status: "fail", marker: "Fail" }
        }

        if (data.free_check_results.export === false) {
            exported = { status: "pass", marker: "Pass" }
        } else if (data.free_check_results.export === true) {
            exported = { status: "fail", marker: "Fail" }
        }

        this.setState({
            mot_status: data.free_check_results.mot_valid,
            tax_status: data.free_check_results.tax_valid,
            history_statuses: {
                exported: exported,
                recalls: recalls

            }
        })

    }

    set_state = (data) => {
        try {

            // Get the relevant metadata
            let metadata = get_relevant_data(data.api_metadata, 'O')

            let filtered_data = {}
            for (let m of metadata) {
                m['value'] = data.api_response[m['item_name']]
                filtered_data[m['item_name']] = m
            }

            const MAKE = filtered_data['make'].value

            let logo = `./assets/logos/${MAKE.replace(" ", "").toLowerCase()}.png`
            if (MAKE === 'IRIS.BUS') {
                logo = `./assets/logos/iveco.png`
            }

            const REPORT_DET_OBJ = {
                ref: data.report_metadata['arc_id'],
                date: data.report_metadata['datetime_generated'].replace('T', ' ').substring(0, 19),
                completeness_actual: data.report_metadata['actual_data_count'],
                completeness_max: data.report_metadata['max_data_count'],
                logo: logo
            }

            const ID_OBJ = {
                vin: filtered_data['vin_last_5'].value,
                engine: filtered_data['engine_no'].value,
                V5C_date: filtered_data['v5c_date'].value && filtered_data['v5c_date'].value.length > 10 ? filtered_data['v5c_date'].value.substring(0, 10) : filtered_data['v5c_date'].value
            }

            const CAR_DET_OBJ = {
                plate: filtered_data['vrm'].value,
                make: MAKE,
                model: filtered_data['model'].value,
                year: filtered_data['year'].value ? parseInt(filtered_data['year'].value) : filtered_data['year'].value,
                colour: filtered_data['colour'].value,
                fuel_type: filtered_data['fuel_type'].value,
                fuel_size: filtered_data['engine_size'].value
            }

            // EURO NCAP
            let euro_ncap = JSON.parse(filtered_data['euro_ncap'].value)
            let euro_ncap_obj = {}

            if (data.euro_ncap.length === 1) {
                // NCAP results from our DB
                euro_ncap = data.euro_ncap[0]
                euro_ncap_obj = {
                    year: euro_ncap['year'],
                    url: euro_ncap['url'],
                    rating: euro_ncap['rating'],
                    adult: euro_ncap['protection_adult'],
                    child: euro_ncap['protection_child'],
                    ped: euro_ncap['protection_pedestrian'],
                    assist: euro_ncap['safety_assists'],
                    fallback: false
                }
            } else {
                // NCAP results from UKVD
                if (euro_ncap['Adult'] && euro_ncap['Child'] && euro_ncap['Pedestrian']) {
                    euro_ncap_obj = {
                        year: parseInt(filtered_data['year'].value),
                        url: 'https://www.euroncap.com',
                        rating: -1,
                        adult: euro_ncap['Adult'],
                        child: euro_ncap['Child'],
                        ped: euro_ncap['Pedestrian'],
                        assist: '',
                        fallback: true
                    }
                } else {
                    // If not a free check, just don't show NCAP
                    if (!this.props.free_check) {
                        this.setState({ show_ncap: false })
                    } else {
                        // If free check, create fake data and block
                        euro_ncap_obj = {
                            year: 2030,
                            url: 'https://www.euroncap.com',
                            rating: '5',
                            adult: '60%',
                            child: '30%',
                            ped: '60%',
                            assist: '80%',
                            fallback: false
                        }
                    }
                }
            }

            // TIMELINE
            let keeper_list = []
            for (let k of filtered_data['keeper_list'].value) {
                let date_str = k['date'].substring(8, 10) + '/' + k['date'].substring(5, 7) + '/' + k['date'].substring(2, 4)
                keeper_list.push(date_str)
            }

            let dr = filtered_data['date_registered'].value

            // Get origin country normalised to determine flag
            let origin = filtered_data['origin_country'].value ? filtered_data['origin_country'].value.toLowerCase() : ""
            origin = origin === "uk" ? "gb" : origin
            origin = ['great britain', 'united kingdom', 'great-britain', 'united-kingdom', 'unitedkingdom', 'greatbritain'].includes(origin) ? 'gb' : origin
            origin = ['usa', 'united states', 'united states of america', 'us of america', 'united-states', 'unitedstates'].includes(origin) ? 'us' : origin
            origin = ['south korea', 'southkorea', 'south-korea'].includes(origin) ? 'korea' : origin

            let country = FLAGS.includes(origin) ? origin : 'fallback'

            let TL = {
                keeper_dates: keeper_list.reverse(),
                country_origin: filtered_data['origin_country'].value ? filtered_data['origin_country'].value : "",
                date_reg: dr ? dr.substring(8, 10) + '/' + dr.substring(5, 7) + '/' + dr.substring(2, 4) : "",
                origin_logo: `./assets/flags/${country}.svg`,
                data: [],
            }

            let tl_data = []

            // Add origin
            tl_data.push({
                name: "Manufactured",
                label: `Date of Manufacture<br><span class=data>${CAR_DET_OBJ.year}</span>`,
                label_mobile: 'Date of Manufacture',
                data_mobile: CAR_DET_OBJ.year,
                icon: this.props.free_check ? './assets/flags/free_check.svg' : TL.origin_logo,
                color: '#E3EAE7',
                year: CAR_DET_OBJ.year
            })


            // Add reg
            tl_data.push({
                name: "UK Registration",
                label: `Registered with the DVLA</b><br><span class=data>${normalise_date(TL.date_reg)}</span>`,
                label_mobile: window.innerWidth < 360 ? 'DVLA Registration' : 'Registered with the DVLA',
                data_mobile: normalise_date(TL.date_reg),
                icon: this.props.free_check ? './assets/flags/free_check.svg' : './assets/flags/gb.svg',
                color: '#E3EAE7',
                year: normalise_date(TL.date_reg).substring(6, 8)
            })

            // Add each keeper
            let i = 1
            for (let x of TL.keeper_dates) {
                let year = normalise_date(x).substring(6, 8)

                tl_data.push({
                    name: "New Keeper",
                    label: `Keeper ${i} Registered<br><span class=data>${normalise_date(x)}</span>`,
                    label_mobile: `Keeper ${i} Registered`,
                    data_mobile: normalise_date(x),
                    icon: this.props.free_check ? './assets/flags/free_check.svg' : './assets/flags/gb.svg',
                    color: '#E3EAE7',
                    year: year
                })

                i++
            }

            TL.data = tl_data

            // HISTORY STUFF
            let metadata_history = get_relevant_data(data.api_metadata, 'H')

            let filtered_data_history = {}
            for (let m of metadata_history) {
                m['value'] = data.api_response[m['item_name']]
                filtered_data_history[m['item_name']] = m
            }

            filtered_data_history['n_recalls'] = data.recalls.length

            // MOT and Tax
            const TAX_STATUS = data.api_response['DVLA_taxStatus']
            const MOT_STATUS = data.api_response['DVLA_motStatus']

            let mot_state = 'nodata'
            let tax_state = 'nodata'

            if (MOT_STATUS === 'Valid') {
                mot_state = 'pass'
            } else if (MOT_STATUS === 'Not valid') {
                mot_state = 'fail'
            }

            if (TAX_STATUS === 'Taxed') {
                tax_state = 'pass'
            } else if (TAX_STATUS === 'Untaxed' || TAX_STATUS === 'SORN' || TAX_STATUS === 'Not Taxed for on Road Use') {
                tax_state = 'fail'
            }

            let image = this.props.free_check && this.props.details.car_img !== "" ? this.props.details.car_img : filtered_data['image'].value

            this.setState({
                smmt_model: data.api_response['smmt-Range'],
                ncap: euro_ncap_obj,
                history_statuses: set_history_status(filtered_data_history),
                report_details: REPORT_DET_OBJ,
                identification: ID_OBJ,
                car_details: CAR_DET_OBJ,
                timeline: TL,
                car_img: image,
                mot_status: mot_state,
                tax_status: tax_state,
                show_img_note: image ? true : false
            })

            loaded = true

        } catch (error) {
            console.error(error)
        }
    }

    get_status_or_marker = (title, attr) => {
        const ITEM = HISTORY_METADATA[title].item_name
        if (this.state.history_statuses[ITEM]) {
            return this.state.history_statuses[ITEM][attr]
        }
    }

    get_cert_status = (check, type) => {
        if (check === 'MOT') {
            if (type === 'status') {
                return this.state.mot_status
            } else {
                if (this.state.mot_status === 'pass') {
                    return 'Pass'
                } else if (this.state.mot_status === 'fail') {
                    return 'Fail'
                } else {
                    return ' No Data'
                }
            }
        } else {
            if (type === 'status') {
                return this.state.tax_status
            } else {
                if (this.state.tax_status === 'pass') {
                    return 'Pass'
                } else if (this.state.tax_status === 'fail') {
                    return 'Fail'
                } else {
                    return ' No Data'
                }
            }
        }
    }

    handleImageError = (event) => {
        event.target.onError = null; // Prevent infinite loop if fallback image also fails
        event.target.src = "./assets/car_no_image.webp"
        if (this.props.car_img) {
            event.target.src = this.props.car_img
        } else {
            this.setState({ show_img_note: true })
        }
    }

    handleLogoError = (event) => {
        event.target.onError = null; // Prevent infinite loop if fallback image also fails
        event.target.src = "./assets/logos/NO_LOGO.png"
    }

    handle_table_click = (title, e) => {
        let tgt = e.target

        if (tgt.classList.contains('title_cont') || tgt.classList.contains('tt_elem') || tgt.classList.contains('full_title') || tgt.classList.contains('row_key')) {
            return
        } else {
            if (title === 'MOT' || title === 'Tax') {
                this.props.hist_change('hist', title)
            } else {
                if (this.props.free_check && title !== 'Exported' && title !== 'Recalls') {
                    window.location.href = `/buy?count=1&plate1=${this.state.car_details.plate}`
                } else {
                    this.props.hist_change('hist', title)
                }
            }
        }


    }

    organise_columns = () => {
        const CURR = this.state.current_col

        const ITEMS = ["1-1-1", "1-1-2", "1-1-3", "1-1-4", "1-1-5", "1-1-6", "1-1-7", "1-1-8", "1-1-9",
            "2-1-1", "2-1-2", "2-1-3", "2-1-4", "2-1-5", "2-1-6", "2-2-1", "2-2-2", "2-2-3",
            "3-1-1", "3-1-2", "3-1-3", "3-1-4", "3-2-1", "3-2-2", "3-2-3", "3-3-1", "3-3-2"]

        if (window.innerWidth < 600 && CURR !== 1) {
            move_items(1, ITEMS)
            this.setState({ current_col: 1 })

        } else if (window.innerWidth >= 600 && window.innerWidth < 1440 && CURR !== 2) {
            move_items(2, ITEMS)
            this.setState({ current_col: 2 })

        } else if (window.innerWidth >= 1440 && CURR !== 3) {
            move_items(3, ITEMS)
            this.setState({ current_col: 3 })
        }
    }

    render() {
        const RD = this.state.report_details
        const TL = this.state.timeline
        const ID = this.state.identification
        const CD = this.props.details

        let hist_results = !this.props.free_check ? ALL_TITLES : ['Finance', 'Stolen', 'Scrapped', 'VIC', 'Imported', 'Plate Change', 'Mileage Issue', 'Written-off', 'High Risk', 'Colour Change']

        let ncap_comment = ''
        let NC = {}

        if (this.state.show_ncap) {
            NC = this.state.ncap
            ncap_comment = NC.rating !== -1 ? NCAP_COMMENTS[NC.rating] : ""
            ncap_comment = this.props.free_check ? "For just £4.99 you can upgrade your ARC report to include 10 more history checks, full valuation and eco-impact, specs, and more!" : ncap_comment
            let items = ['adult', 'child', 'ped', 'assist']

            for (let item of items) {
                let rating = NC[item]
                if (rating) {
                    if (rating > 10) {
                        rating = rating / 10
                    }
                    rating = rating.toString().search("%") !== -1 ? parseInt(rating.replace("%", "")) / 10 : rating
                    rating = rating.toString().search("star") !== -1 ? parseInt(rating[0]) * 2 : rating
                } else {
                    rating = null
                }

                NC[item] = rating
            }

            if (NC.year) {
                NC.year = parseInt(NC.year)
            }
        }

        let fails = []
        let fail_count = 0
        if (loaded) {
            fails = Object.entries(this.state.history_statuses)
                .filter(([item, status]) => status.status === "fail")
                .flatMap(([item]) =>
                    Object.entries(HISTORY_METADATA)
                        .filter(([h]) => HISTORY_METADATA[h].item_name === item)
                        .map(([h]) => [
                            HISTORY_METADATA[h].desc_titles.fail,
                            HISTORY_METADATA[h].icon,
                            h
                        ])
                )

            fail_count = fails.length

            if (this.state.tax_status === 'fail') {
                fails.unshift(["This vehicle is currently untaxed", "./assets/icons/tax", "Tax"])
            }

            if (this.state.mot_status === 'fail') {
                fails.unshift(["This vehicle does not have a valid MOT", "./assets/icons/mot", "MOT"])
            }

        }

        return (
            <div id="overview_panel" className="report_panel">
                {this.state.report_details.ref === '1234' && <h1>Sample Car Check Report</h1>}

                {this.props.free_check && <FreeCheckCTA plate={this.props.plate} img='./assets/CTA/overview.webp' title={"Don't leave it to chance"} text="Only our full check can ensure a vehicle is safe and in good condition" />}

                <div id='columns'>
                    <div id='col_1' className='col active'></div>
                    <div id='col_2' className='col'></div>
                    <div id='col_3' className='col'></div>
                </div>

                <div id='car_details' className='1-1-1 2-1-1 3-1-1'>
                    <div id='details_header'>
                        <img alt="The report car manufacturer logo" src={CD.logo} onError={this.handleLogoError} />
                        <p>{`${CD.car.replace(' UNKNOWN', ' (MODEL UNKNOWN)')}`}</p>
                    </div>
                    {this.state.show_img_note && <p className='img_note'>Colour shown may not match vehicle</p>}
                    <img className="car_img" alt="The report car" src={this.state.car_img} onError={this.handleImageError} />

                    <div id='details_table'>
                        <div className='details_row'>
                            <p className='default_key'>Year</p>
                            <p className='default_val'>{CD.year}</p>
                        </div>
                        <div className='sep'></div>
                        <div className='details_row'>
                            <p className='default_key'>Colour</p>
                            <p className='default_val'>{CD.colour}</p>
                        </div>
                        <div className='sep'></div>

                        <div className='details_row'>
                            <p className='default_key'>Fuel</p>
                            <p className='default_val'>{CD.fuel_size ? `${CD.fuel_type} (${CD.fuel_size}L)` : CD.fuel_type}</p>
                        </div>

                        {CD.weight && <div className='sep'></div>}

                        {CD.weight && <div className='details_row'>
                            <p className='default_key'>Weight</p>
                            <p className='default_val'>{`${CD.weight.toLocaleString('en-GB')}kg`}</p>
                        </div>}

                        {CD.co2 && <div className='sep'></div>}

                        {CD.co2 && <div className='details_row'>
                            <p className='default_key'>CO<sub>2</sub> emissions</p>
                            <p className='default_val'>{`${CD.co2} g/km`}</p>
                        </div>}

                    </div>

                </div>

                <ReportFrame
                    title="MOT & Tax Check"
                    alert={this.state.mot_status === 'fail' || this.state.tax_status === 'fail'}
                    plate={this.props.plate}
                    col_classes={"1-1-2 2-2-1 3-2-1"}
                >
                    <div className="check_table mot_tax">
                        {['MOT', 'Tax'].map((title, index) => (
                            <div key={index} className={`full_row ${this.get_cert_status(title, 'status')}`} onClick={(e) => { this.handle_table_click(title, e) }}>
                                <div className='row_key'>
                                    <div className='title_cont'>
                                        <p className="full_title">{title}</p>
                                    </div>
                                    <Tooltip text={TT.his[title]} />
                                </div>

                                <div className="marker_container" >
                                    <div className={`full_marker full_marker_${this.get_cert_status(title, 'status')}`}>
                                        <p>{this.get_cert_status(title, 'marker')}</p>
                                        <img className='marker_img' alt="" src={`./assets/icons/marker_${this.get_cert_status(title, 'status')}_v2.svg`} />
                                    </div>
                                    <img alt="" src='./assets/icons/right_arrow_full.svg' />
                                </div>

                            </div>
                        ))}
                    </div>
                </ReportFrame>

                {this.props.free_check &&
                    <ReportFrame
                        title="Free History Check"
                        alert={fail_count}
                        plate={this.props.plate}
                        col_classes={"1-1-3 2-2-2 3-3-1"}
                    >
                        <div className="check_table free_separate">
                            {['Exported', 'Recalls'].map((title, index) => (
                                <div key={index} className={`full_row ${this.get_status_or_marker(title, 'status')}`} onClick={(e) => { this.handle_table_click(title, e) }}>
                                    <div className='row_key'>
                                        <div className='title_cont'>
                                            <p className="full_title">{title}</p>
                                        </div>
                                        <Tooltip text={TT.his[title]} />
                                    </div>

                                    <div className="marker_container">
                                        <div className={`full_marker full_marker_${this.get_status_or_marker(title, 'status')}`}>
                                            <p>{this.get_status_or_marker(title, 'marker')}</p>
                                            <img className='marker_img' alt="" src={`./assets/icons/marker_${this.get_status_or_marker(title, 'status')}_v2.svg`} />
                                        </div>
                                        <img alt="" src='./assets/icons/right_arrow_full.svg' />
                                    </div>

                                </div>
                            ))}
                        </div>
                    </ReportFrame>
                }

                <ReportFrame
                    title={this.props.free_check ? 'Full Check' : 'Full History Check'}
                    alert={fail_count > 0 && !this.props.free_check}
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    col_classes={"1-1-4 2-2-3 3-3-2"}
                >
                    <div id="summary_cont">
                        <div className="check_table">
                            {hist_results.map((title, index) => (
                                <div key={index} className={`full_row ${this.get_status_or_marker(title, 'status')}`} onClick={(e) => { this.handle_table_click(title, e) }}>
                                    <div className='row_key'>
                                        <div className='title_cont'>
                                            <p className="full_title">{HISTORY_METADATA[title]['home_title']}</p>
                                        </div>
                                        <Tooltip text={TT.his[title]} />
                                    </div>


                                    <div className="marker_container">
                                        {this.props.free_check && title !== 'Exported' && title !== "Recalls" && <div className='free_check'></div>}
                                        <div className={this.props.free_check && title !== 'Exported' && title !== "Recalls" ? "full_marker full_marker_nodata" : `full_marker full_marker_${this.get_status_or_marker(title, "status")}`}>
                                            {!(this.props.free_check && title !== 'Exported' && title !== "Recalls") && <p>{this.get_status_or_marker(title, "marker")}</p>}
                                            <img className='marker_img' alt="" src={this.props.free_check && title !== 'Exported' && title !== "Recalls" ? "./assets/icons/marker_warn_v2.svg" : `./assets/icons/marker_${this.get_status_or_marker(title, "status")}_v2.svg`} />
                                        </div>
                                        <img alt="" src='./assets/icons/right_arrow_full.svg' />
                                    </div>

                                </div>

                            ))}
                        </div>

                        {RD.completeness_actual && RD.completeness_max && !this.props.free_check &&
                            <div id="completeness">
                                <MiniDonutGuage percentage={Math.round(RD.completeness_actual * 100 / RD.completeness_max, 0)} />
                                <div id="completeness_text">
                                    <p>{`Report completion: ${Math.round(RD.completeness_actual * 100 / RD.completeness_max, 0)}%`}</p>
                                    <p>{`We have found ${this.props.free_check ? "???" : RD.completeness_actual} / ${this.props.free_check ? "???" : RD.completeness_max} data records`}</p>
                                </div>
                            </div>
                        }
                    </div>
                </ReportFrame>

                <Review free_check={this.props.free_check} class_name='1-1-5 2-1-5 3-1-3' avatar='0' text='This was a great a find! So much helpful information across many different areas' />

                <ReportFrame
                    title="Timeline"
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    col_classes={"1-1-6 2-1-2 3-2-2"}
                >
                    <div id='timeline_frame'>
                        {TL.data.map((item, j) => (
                            <div key={j}>
                                <div className='timeline_mobile_row'>
                                    <div className='timeline_row_left'>
                                        <img src={item.icon} alt='' />
                                        <p className='default_key'>{item.label_mobile}</p>
                                    </div>

                                    {this.props.free_check ? <p className='blur default_val'>??/??/??</p> : <p className='default_val'>{item.data_mobile}</p>}

                                </div>
                                {j < TL.data.length - 1 && <div className='sep'></div>}
                            </div>

                        ))}
                    </div>

                </ReportFrame>

                <ReportFrame
                    nodata={!this.state.show_ncap}
                    title="Safety"
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    col_classes={"1-1-7 2-1-3 3-1-2"}
                >
                    <div id="ncap">
                        {NC.rating && NC.rating !== -1 && <div id='ncap_summary'>
                            <div id='rating_cont'>
                                <div id='prefix'>
                                    <p>Euro NCAP Rating:</p>
                                    {!this.props.free_check && <Tooltip text={ncap_comment} />}
                                </div>
                                <div id='rating'>
                                    <img alt='' src={'./assets/icons/star_green.svg'} />
                                    <p className={this.props.free_check ? ' blur_green' : ''}>{`${NC.rating} / 5`}</p>
                                </div>
                            </div>
                        </div>}

                        <div id='ncap_details'>
                            {NC.adult && <div className='detail_item'>
                                <div className='ncap_text'>
                                    <p>Adult Safety</p>
                                    <p className={this.props.free_check ? 'details_rating blur' : 'details_rating'}>{NC.adult} <span>/ 10</span></p>
                                </div>

                                <div className='ncap_bar'>
                                    <div className='ncap_bar_inner' style={{ width: `${NC.adult * 10}%` }}></div>
                                </div>
                            </div>}

                            {NC.child && <div className='detail_item'>
                                <div className='ncap_text'>
                                    <p>Child Safety</p>
                                    <p className={this.props.free_check ? 'details_rating blur' : 'details_rating'}>{NC.child} <span>/ 10</span></p>
                                </div>

                                <div className='ncap_bar'>
                                    <div className='ncap_bar_inner' style={{ width: `${NC.child * 10}%` }}></div>
                                </div>
                            </div>}

                            {NC.ped && <div className='detail_item'>
                                <div className='ncap_text'>
                                    <p>Pedestrian Safety</p>
                                    <p className={this.props.free_check ? 'details_rating blur' : 'details_rating'}>{NC.ped} <span>/ 10</span></p>
                                </div>

                                <div className='ncap_bar'>
                                    <div className='ncap_bar_inner' style={{ width: `${NC.ped * 10}%` }}></div>
                                </div>
                            </div>}

                            {NC.assist && <div className='detail_item'>
                                <div className='ncap_text'>
                                    <p>Safety Assists</p>
                                    <p className={this.props.free_check ? 'details_rating blur' : 'details_rating'}>{NC.assist} <span>/ 10</span></p>
                                </div>

                                <div className='ncap_bar'>
                                    <div className='ncap_bar_inner' style={{ width: `${NC.assist * 10}%` }}></div>
                                </div>
                            </div>}
                        </div>

                    </div>
                </ReportFrame>

                <ListAd free_check={this.props.free_check} class_name='1-1-8 2-1-6 3-1-4' />

                <ReportFrame
                    title="VIN"
                    free_check={this.props.free_check}
                    plate={this.props.plate}
                    col_classes={"1-1-9 2-1-4 3-2-3"}
                >
                    <div id="vehicle_id">
                        <div className="id_row">
                            <p className='default_key'>VIN last 5 digits</p>
                            <p className={this.props.free_check ? 'blur default_val' : 'default_val'}>...{ID.vin ? ID.vin : "Unknown"}</p>
                        </div>
                        <div className='sep'></div>
                        <div className="id_row">
                            <p className='default_key'>Most Recent V5C Update</p>
                            <p className={this.props.free_check ? 'blur default_val' : 'default_val'}>{ID.V5C_date ? ID.V5C_date : "Unknown"}</p>
                        </div>
                        <div className='sep'></div>
                        <div className="id_row">
                            <p className='default_key'>Engine Number</p>
                            <p className={this.props.free_check ? 'blur default_val' : 'default_val'}>{ID.engine ? ID.engine : "Unknown"}</p>
                        </div>
                    </div>
                </ReportFrame>

            </div>
        )
    }
}

Overview.displayName = 'Overview'

export default Overview

