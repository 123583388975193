import '../styles/components/checkout_form.scss';
import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import axios from 'axios';

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements()

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [t_and_c, setT_and_c] = useState(false);
    const [promotional, setPromotional] = useState(false);

    useEffect(() => {

        document.getElementById('discount_input').addEventListener('focus', function () {
            document.getElementById('discount_input').classList.remove('invalid')
            document.getElementById('discount_msg').innerHTML = ''
            document.getElementById('discount_msg').classList.remove('show')
        });

        if (!stripe) {
            return;
        }

        if (props.updated) {
            try {
                stripe.retrievePaymentIntent(props.client_secret).then(({ paymentIntent }) => {
                    fetch("/api/update-payment-intent-amount", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            plates: props.plates,
                            payment_intent_id: paymentIntent.id,
                            discount: props.discount
                        }),
                    })
                });
            } catch (err) {
                window.location.href = '/server-error'
            }
        }


        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

        if (!clientSecret) { return; }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe, props]);

    // ON PAY SUBMIT
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements || !t_and_c || props.plates.length < 1) { return; }

        setIsLoading(true);

        // Update email on submit
        try {
            stripe.retrievePaymentIntent(props.client_secret)

                // UPDATE INTENT AND CHARGE OBJ s
                .then(({ paymentIntent }) => {
                    return fetch("/api/update-payment-intent-submit", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            payment_intent_id: paymentIntent.id,
                            plates: props.plates,
                            email: email,
                            promo_opt_in: promotional,
                            discount_code: props.active_code
                        }),
                    })
                })

                .then(response => {
                    if (response.ok) {
                        return response.text()
                    } else {
                        window.location.href = '/report-error'
                    }
                })

                // THEN CONFIRM PAYMENT
                .then(async () => {
                    const { error } = await stripe.confirmPayment({ elements, redirect: 'if_required', });

                    // Update messages
                    if (error) {
                        if (error.type === "card_error" || error.type === "validation_error") {
                            setMessage(error.message);
                        } else {
                            setMessage("An unexpected error occurred.");
                        }
                    } else {
                        setMessage("Thank you! Redirecting...");

                        stripe.retrievePaymentIntent(props.client_secret).then(({ paymentIntent }) => {
                            let payment_id = paymentIntent['id']

                            const fetchReportContent = async () => {
                                const response = await fetch(`/api/get-report-info?payment_intent_id=${payment_id}`, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                });

                                if (response.ok) {
                                    const report_data = await response.json();
                                    props.handle_confirm(report_data, email)
                                } else {
                                    window.location.href = "/report-error"
                                    console.error('Failed to fetch data');
                                }
                            };

                            fetchReportContent();

                        });

                    }

                    setIsLoading(false);
                })

        } catch (err) {
            window.location.href = '/report-error'
        }

    };

    const handle_discount = async (e) => {
        let code = document.getElementById('discount_input').value.toUpperCase()
        if (code) {

            try {
                const response = await axios.get(`/api/add-promo/${code}`);
                if (response.data.valid) {

                    props.handle_discount_apply(response.data)
                } else {
                    document.getElementById('discount_input').classList.add('invalid')
                    document.getElementById('discount_msg').innerHTML = response.data.msg
                    document.getElementById('discount_msg').classList.add('show')
                }

                document.getElementById('discount_input').value = ''
            } catch (error) {
                window.location.href = '/server-error'
            }

        }
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    const handle_promotional = () => { setPromotional(!promotional); }
    const handle_t_and_c = () => { setT_and_c(!t_and_c); }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>

            <div id='discount_cont'>
                <p>Discount</p>

                <div id="discount">
                    <input
                        id='discount_input'
                        type="input"
                        placeholder='Enter promo code'
                        maxLength={20}
                        onKeyDown={handleKeyDown}
                    />

                    <div id='discount_btn' onClick={handle_discount}>Apply</div>
                </div>
                <p id='discount_msg'></p>
                <div className='sep'></div>
            </div>


            <LinkAuthenticationElement
                id="link-authentication-element"
                onChange={(e) => setEmail(e.value.email)}
            />

            <PaymentElement id="payment-element" options={{ layout: "tabs", wallets: { applePay: 'never', googlePay: 'never' } }} />

            <div id='checkboxes'>
                <label>
                    <input
                        type="checkbox"
                        checked={t_and_c}
                        onChange={handle_t_and_c}
                    />
                    <a target='_blank' href='./terms-and-conditions'>I agree to the ARC terms and conditions</a>
                </label>
                <label>
                    <input
                        type="checkbox"
                        checked={promotional}
                        onChange={handle_promotional}
                    />
                    Keep me updated with the latest news from ARC
                </label>
            </div>

            <button disabled={isLoading || !stripe || !elements || !t_and_c} id="submit">
                <span id="button-text">
                    {isLoading ? <div className="loading_spinner"><div></div><div></div><div></div><div></div></div> : "Pay now"}
                </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}