import React, { Component } from "react";
import '../../styles/components/cards/list_ad.scss';

class ListAd extends Component {
    render() {
        return (this.props.free_check ?
            <div className={`list_ad ${this.props.class_name}`}>
                <p className="list_ad_title">Included in a full check:</p>
                <div className="list_container">
                    <div className='list_col'>
                        <div className="list_item"><p>Full History</p><img src='./assets/icons/marker_pass_v2.svg' alt='Check mark' /></div>
                        <div className="list_item"><p>Valuation</p><img src='./assets/icons/marker_pass_v2.svg' alt='Check mark' /></div>
                        <div className="list_item"><p>Safety</p><img src='./assets/icons/marker_pass_v2.svg' alt='Check mark' /></div>
                    </div>
                    <div className='list_col'>
                        <div className="list_item"><p>Expenses</p><img src='./assets/icons/marker_pass_v2.svg' alt='Check mark' /></div>
                        <div className="list_item"><p>Eco-score</p><img src='./assets/icons/marker_pass_v2.svg' alt='Check mark' /></div>
                        <div className="list_item"><p>ULEZ</p><img src='./assets/icons/marker_pass_v2.svg' alt='Check mark' /></div>
                    </div>
                </div>

            </div> : <></>
        );
    }
}
export default ListAd;