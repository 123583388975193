import React, { Component } from "react";

import '../../styles/components/user_journey/loading.scss';

class ReportLoad extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div id="loading_panel">
                <div id='loading_text'>
                    <h1>Hold tight</h1>
                    <h2>Loading ARC Report</h2>
                </div>

                <div id="loading_animation">
                    <div className="bar bar1"></div>
                    <div className="bar bar2"></div>
                    <div className="bar bar3"></div>
                    <div className="bar bar4"></div>
                    <div className="bar bar5"></div>
                    <div className="bar bar6"></div>
                    <div className="bar bar7"></div>
                    <div className="bar bar8"></div>
                </div>
            </div>
        );
    }
}
export default ReportLoad;